const routes = [
  {
    path: '/container/shapes/:page?',
    props: true,
    component: () => import('@/views/container/shape/Shapes.vue')
  },
  {
    path: '/container/shape/:shapeId/:nav?/:page?',
    props: true,
    component: () => import('@/views/container/shape/Shape.vue')
  },
  {
    path: '/container/shape/:shapeId/:nav?/:from/:to',
    props: true,
    component: () => import('@/views/container/shape/Shape.vue')
  }
]

export default routes
