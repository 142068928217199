export default {
  m: {
    hello: '¡Hola Chef!',
    goToEntity: 'Ir a {entity} | Ir a {entity}',
    goBackToEntity: 'Volver a {entity} | Volver a {entity}',
    about: 'Sobre Janby',
    blog: 'Última noticia de Janby | Últimas noticias de Janby',
    logIn: 'Iniciar sesión',
    logInWithYourAccount: 'Iniciar sesión con tu cuenta',
    logOut: 'Cerrar sesión',
    newEntityVersionFound: 'Nueva versión de {entity} encontrada | Nuevas versiones de {entity} encontradas',
    giveYourFeedback: 'Danos tu opinión',
    ipAddress: 'Dirección IP | Direcciones IP',
    wifiMacAddress: 'Dirección MAC Wifi | Direcciones MAC Wifi',
    bluetoothMacAddress: 'Dirección MAC Bluetooth | Direcciones MAC Bluetooth',
    macAddress: 'Dirección MAC Ethernet | Direcciones MAC Ethernet',
    lastLogin: 'Inició sesión',
    created: 'Creado | Creados',
    updated: 'Actualizado | Actualizados',
    closed: 'Cerrado | Cerrados',
    expiration: 'Caducidad | Caducidades',
    expiration_desc: 'Tiempo/fecha a partir del cual no es seguro consumir el producto.',
    expirationWhenStarted: 'Caducidad al inicio de la acción | Caducidades al inicio de la acción',
    expirationWhenFinished: 'Nueva caducidad tras finalizar la acción | Nuevas caducidades tras finalizar la acción',
    bestBefore: 'Consumo preferente | Consumo preferente',
    bestBefore_desc: 'Tiempo/fecha a partir del cual el producto comienza a perder forma o propiedades.',
    email: 'E-mail | E-mails',
    image: 'Imagen | Imágenes',
    phone: 'Teléfono | Teléfonos',
    web: 'Sitio web | Sitios web',
    url: 'Url | Urls',
    locale: 'Idioma | Idiomas',
    timeZone: 'Zona horaria | Zonas horarias',
    password: 'Contraseña | Contraseñas',
    forgotPassword: '¿Olvidaste la contraseña?',
    recoverPassword: 'Recuperar contraseña',
    noRepeat: 'No repetir | No repetir',
    title: 'Título | Títulos',
    text: 'Texto | Textos',
    body: 'Cuerpo | Cuerpos',
    table: 'Tabla | Tablas',
    chart: 'Gráfica | Gráficas',
    id: 'Identificador | Identificadores',
    name: 'Nombre | Nombres',
    lastName: 'Apellido | Apellidos',
    publicName: 'Nombre comercial | Nombres comercial',
    publicName_desc: 'Nombre comercial para mostrar en el perfil público y etiquetas',
    publicProfile: 'Perfil público | Perfiles públicos',
    publicDescription: 'Descripción pública | Descripciones públicas',
    publicDescription_desc: 'Descripción que otros usuarios pueden ver en el perfil público de la organización',
    publicEntity: '{entity} público/a | {entity} públicos/as',
    code: 'Código | Códigos',
    icon: 'Icono | Iconos',
    geometry: 'Geometría | Geometrías',
    description: 'Descripción | Descripciones',
    entityDescription: 'Descripción de {entity} | Descripciones de {entity}',
    entityWeight: 'Peso de {entity} | Pesos de {entity}',
    tag: 'Etiqueta | Etiquetas',
    tag_desc: 'Las etiquetas son palabras que ayudan a categorizar cualquier elemento. Puedes añadir tantas como quieras separadas por comas y utilizarlas para filtrar los listados de elementos.',
    color: 'Color | Colores',
    type: 'Tipo | Tipos',
    note: 'Nota adicional | Notas adicionales',
    active: 'Activo/a | Activos/as',
    active_desc: 'Los elementos desactivados no se mostrarán en los listados.',
    available: 'Disponible | Disponibles',
    completed: 'Completedo/a | Completedo/as',
    deleted: 'Borrado/a | Borrados/as',
    tester: 'Tester',
    canceled: 'Cancelada | Canceladas',
    pending_cancel: 'Pendiente candelación',
    processing: 'Procesando | Procesando',
    failed: 'Fallido | Fallidos',
    refunded: 'Reembolsado | Reembolsados',
    onHold: 'En pausa | En pausa',
    pending: 'Pendiente | Pendientes',
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
    previous: 'Anterior',
    next: 'Siguiente',
    skip: 'Saltar | Saltar',
    remindLater: 'En otro momento',
    finish: 'Finalizar',
    price: 'Precio | Precios',
    cost: 'Coste | Costes',
    income: 'Ingreso | Ingresos',
    date: 'Fecha | Fechas',
    column: 'Columna | Columnas',
    execution: 'Ejecución | Ejecuciones',
    waitTime: 'Tiempo de espera | Tiempos de espera',
    waitTimeCurrentStart: 'Retraso en iniciar | Retrasos en iniciar',
    waitTimeCurrentEnd: 'Retraso en extraer | Retrasos en extraer',
    day: 'Día | Días',
    day_0: 'Domingo | Domingos',
    day_1: 'Lunes | Lunes',
    day_2: 'Martes | Martes',
    day_3: 'Miércoles | Miércoles',
    day_4: 'Jueves | Jueves',
    day_5: 'Viernes | Viernes',
    day_6: 'Sábado | Sábados',
    second: 'Segundo | Segundos',
    minute: 'Minuto | Minutos',
    hour: 'Hora | Horas',
    week: 'Semana | Semanas',
    month: 'Mes | Meses',
    range: 'Rango | Rangos',
    today: 'Hoy | Hoy',
    yesterday: 'Ayer | Ayer',
    currentEntity: '{entity} actual | {entity} actuales',
    lastEntity: 'Último/a {entity} | Últimos/as {entity}',
    from: 'Desde | Desde',
    to: 'Hasta | Hasta',
    file: 'Archivo | Archivos',
    rating: 'Valoración | Valoraciones',
    total: 'Total | Total',
    amount: 'Cantidad | Cantidades',
    entityAmount: 'Cantidad de {entity} | Cantidades de {entity}',
    expectedCount: 'Cantidad de producto esperada | Cantidades de producto esperadas',
    registeredCount: 'Cantidad de producto registrada | Cantidades de productos registradas',
    version: 'Versión | Versiones',
    version_desc: 'Al sincronizar dispositivos y cloud, la versión de mayor número se tomará como los datos a conservar.',
    channel: 'Canal | Canales',
    priority: 'Prioridad en lista | Prioridades en lista',
    priority_desc: 'Los elementos con mayor prioridad se mostrarán primero en los dispositivos.',
    expired: 'Caducado | Caducados',
    notExpired: 'No caducado | No caducados',
    register: 'Registrar | Registrar',
    registerEntity: 'Registrar {entity} | Registrar {entity}',
    all: 'Todos/as | Todos/as',
    allEntity: 'Todos/as {entity} | Todos/as los/as {entity}',
    never: 'Nunca | Nunca',
    save: 'Guardar | Guardar',
    saveEntity: 'Guardar {entity} | Guardar {entity}',
    create: 'Crear | Crear',
    createEntity: 'Crear {entity} | Crear {entity}',
    transferEntity: 'Transferir {entity} | Transferir {entity}',
    updateEntity: 'Actualizar {entity} | Actualizar {entity}',
    run: 'Ejecutar | Ejecutar',
    runEntity: 'Ejecutar {entity} | Ejecutar {entity}',
    savedEntity: '{entity} guardado/a | {entity} guardados/as',
    scheduledEntity: '{entity} programado/a | {entity} programados/as',
    search: 'Buscar | Buscar',
    searchEntity: 'Buscar {entity} | Buscar {entity}',
    searchInHistory: 'Buscar en histórico | Buscar en histórico',
    useExistingEntity: 'Usar {entity} existente | Usar {entity} existentes',
    countAvailableEntity: '{count} {entity} disponible | {count} {entity} disponibles',
    reloadEntity: 'Recargar {entity} | Recargar {entity}',
    entityEnabled: '{entity} activado/a | {entity} activados/as',
    entityDisabled: '{entity} desactivado/a | {entity} desactivados/as',
    enableEntity: 'Activar {entity} | Activar {entity}',
    disableEntity: 'Desactivar {entity} | Desactivar {entity}',
    delete: 'Eliminar | Eliminar',
    remove: 'Eliminar | Eliminar',
    select: 'Seleccionar | Seleccionar',
    entityEntity: '{entity2} de {entity1}',
    selectEntity: 'Selecciona {entity} | Selecciona {entity}',
    selectEntityType: 'Selecciona tipo de {entity} | Selecciona tipos de {entity}',
    add: 'Añadir | Añadir',
    addEntity: 'Añadir {entity} | Añadir {entity}',
    edit: 'Editar | Editar',
    editEntity: 'Editar {entity} | Editar {entity}',
    view: 'Ver | Ver',
    viewEntity: 'Ver {entity} | Ver {entity}',
    copy: 'Copiar | Copiar',
    copyEntity: 'Copiar {entity} | Copiar {entity}',
    sendEntity: 'Enviar {entity} | Enviar {entity}',
    removeEntity: 'Quitar {entity} | Quitar {entity}',
    deleteEntity: 'Eliminar {entity} | Eliminar {entity}',
    cloneEntity: 'Clonar {entity} | Clonar {entity}',
    moreEntity: 'Mas {entity} | Más {entity}',
    import: 'Importar | Importar',
    importEntity: 'Importar {entity} | Importar {entity}',
    export: 'Exportar | Exportar',
    exportEntity: 'Exportar {entity} | Exportar {entity}',
    detail: 'Detalle | Detalles',
    entityDetail: 'Detalle de {entity} | Detalles de {entity}',
    settings: 'Configuración | Configuraciones',
    liveEntity: '{entity} en vivo | {entity} en vivo',
    size: 'Tamaño | Tamaños',
    download: 'Descargar | Descargar',
    upload: 'Subir | Subir',
    uploadEntity: 'Subir {entity} | Subir {entity}',
    connect: 'Conectar | Conectar',
    loading: 'Cargando | Cargando',
    loadingEntity: 'Cargando {entity} | Cargando {entity}',
    setAsStatus: 'Establecer como {status} | Establecer como {status}',
    setEntityAsStatus: 'Establecer {entity} como {status} | Establecer {entity} como {status}',
    print: 'Imprimir | Imprimir',
    printEntity: 'Imprimir {entity} | Imprimir {entity}',
    groupEntity: 'Agrupar {entity} | Agrupar {entity}',
    close: 'Cerrar | Cerrar',
    cancel: 'Cancelar | Cancelar',
    clear: 'Limpiar | Limpiar selección',
    option: 'Opción | Opciones',
    result: 'Resultado | Resultados',
    preview: 'Vista previa | Vistas previas',
    barcode: 'Código de barras | Códigos de barras',
    bytecode: 'Janby QR | Janby QRs',
    meta: 'Propiedad personalizable | Propiedades personalizables',
    start: 'Inicio | Inicios',
    ready: 'Listo | Listos',
    end: 'Fin | Finales',
    filter: 'Filtro | Filtros',
    filterByEntity: 'Filtrar solo {entity} | Filtrar solo {entity}',
    noFilter: 'Sin aplicar filtro | Sin aplicar filtros',
    required: 'Requerido | Requeridos',
    notRequired: 'No obligatorio | No obligatorios',
    locked: 'Bloqueado | Bloqueados',
    public: 'Público | Públicos',
    private: 'Privado | Privados',
    private_desc: 'Solo visible para usuarios de la organización en el cloud | Solo visibles para usuarios de la organización en el cloud',
    maximum: 'Máximo | Máximos',
    minimum: 'Mínimo | Mínimos',
    noData: 'No hay datos | No hay datos',
    unknown: 'Desconocido | Desconocidos',
    default: 'Por defecto | Por defecto',
    none: 'Ninguno/a | Ningunos/as',
    noEntityFound: '{entity} no encontrado/a | {entity} no encontrados/as',
    notAllowed: 'No permitido | No permitido',
    youSure: '¿Estás seguro/a? | ¿Estás seguro/a?',
    clickToLoad: 'Click para cargar | Click para cargar',
    syncEntity: 'Sincronizar {entity} | Sincronizar {entity}',
    syncrhonizedEntity: '{entity} sincronizado/a | {entity} sincronizados/as',
    mergeEntity: 'Combinar {entity} | Combinar {entity}',
    mergeIntoEntity: 'Guardar el resultado en {entity} | Guardar los resultados en {entity}',
    publicData: 'Dato público | Datos públicos',
    publicData_desc: 'Visible en el perfil público y etiquetas de organización/producto/dispositivo etc.',
    privateData: 'Dato privado | Datos privados',
    privateData_desc: 'Solo visible para usuarios de esta organización',

    // Home
    Home: 'Página de inicio',
    Dashboard: 'Panel de mando | Paneles de mando',
    Summary: 'Resumen | Resúmenes',
    Cooking: 'Cocinado | Cocinados',
    home_tour_0: '¡Esta es la nube de Janby!<br/><br/>Deja que te guiemos por tu cocina y tu perfil.',
    home_tour_1: 'Esta es la localización en la que estás logueado ahora.<br/><br/>Los Usuarios, Dispositivos, Productos, etc. se pueden agrupar por localizaciones de forma que representen la estructura de tu organización. De este modo, usuarios de una localización solo podrán ver los datos que queden dentro de ella.',
    home_tour_2: 'Puedes moverte por tus localizaciones o ir a ver la lista completa de localizaciones.<br/><br/>Al cambiar de localización, los listados de Usuarios, Dispositivos, Productos, etc, se actualizarán mostrando solo los existentes en la nueva localización.',
    home_tour_3: 'Para editar tu perfil y permisos, ve a los ajustes de tu cuenta.',

    // Organization
    Organization: 'Organización | Organizaciones',
    organizationConnectionId: 'Organization connection ID | Organization connection IDs',
    organizationConnectionId_desc: 'Code required to connect your devices to Janby Cloud. This code is unique for your entire organization and will be requested by your devices the first time you configure them.',
    isViewFull: 'Vista todo el cloud',
    isViewFull_desc: 'Mostrar datos de toda la organización independientemente de la localización en la que se encuentre',
    isViewFullEnabled_desc: 'Mostrando datos de todo el cloud',
    isViewFullDisabled_desc: 'Datos de la localización actual ({entity}) e hijas',
    contact_organization_manager: 'Contact your organization manager',
    organization_tour_0: 'Modifica los datos generales de la organización.<br/><br/>Los datos públicos son datos visibles para todas las organizaciones mientras que los privados, solo pueden ser vistos por tus usuarios.',
    organization_tour_1: 'La estructura de localizaciones de tu organización.<br/><br/>Puedes crear nuevas y arrastrar localizaciones para modelar la jerarquía a la que luego asociar tus Usuarios, Dispositivos, Productos, etc.',
    organization_tour_2: 'Listado completo de las licencias y en qué Usuario/Dispositivo y Localización están suendo usadas.',
    locationStructure: 'Estructura de Localizaciones | Estructuras de Localizaciones',
    locationStructureClickDrag: 'Arrastra las localizciones para modificar la estructura o haz click sobre ellas para iniciar sesión',
    geom: 'Geometría | Geometrías',
    geomMap: 'Mapa de geometrías | Mapas de geometrías',
    entityMap: 'Mapa de {entity} | Mapas de {entity}',
    entityList: 'Lista de {entity} | Listas de {entity}',
    organizationRecommendeds: 'Productos de esta organización que te pueden interesar',

    // Business areas
    'janby/business/restaurant': 'Restaurante | Restaurantes',
    'janby/business/bar': 'Bar/Pub/Café | Bar/Pub/Cafés',
    'janby/business/colective': 'Colectividad | Colectivitidades',
    'janby/business/hotel': 'Hotel | Hoteles',
    'janby/business/delivery': 'Servicio de Delivery | Servicios de Delivery',
    'janby/business/online': 'Venta online | Ventas online',
    'janby/business/retail': 'Retail/Supermarket/Catering | Retail/Supermarket/Caterings',
    'janby/business/dealer': 'Foodservice dealer | Foodservice dealers',
    'janby/business/provider': 'Proveedor equipamiento cocina | Proveedores equipamiento cocina',
    'janby/business/technical': 'Servicio técnico | Servicios técnicos',
    'janby/business/consultant': 'Consultor | Consultores',
    'janby/business': 'Otro negocio | Otros negocios',

    // License
    AuthLicense: 'Licencia | Licencias',
    License: 'Licencia | Licencias',
    entityLicense: 'Licencia en {entity} | Licencias en {entity}',
    licenseForEntity: 'Licencia de {entity} | Licencias de {entity}',
    'use/total': 'Usadas/Total',
    Subscription: 'Suscripción | Suscripciones',
    lastPayment: 'Último pago | Últimos pagos',
    nextPayment: 'Próximo pago | Próximos pagos',
    permission_0_desc: 'No puede ver {entity}',
    permission_1_desc: 'Ver pero no editar/crear/borrar {entity}',
    permission_2_desc: 'Ver y editar pero no crear/borrar {entity}',
    permission_3_desc: 'Editar y crear pero no borrar {entity}',
    permission_4_desc: 'Editar, crear y borrar {entity}',
    // Licenses user
    'janby/license/user/backup': 'Backup de programas y dispositivos',
    'janby/license/user/history/90': 'Histórico de 3 meses de datos de cocina',
    'janby/license/user/history/180': 'Histórico de 6 meses de datos de cocina',
    'janby/license/user/history/unlimited': 'Histórico ilimitado de datos de cocina',
    'janby/license/user/report': 'Licencia de usuario Informes',
    'janby/license/user/chef': 'Licencia de usuario Chef',
    'janby/license/user/equipment': 'Fabricante de equipamiento de cocina',
    'janby/license/user/label/ready-to-eat': 'Productores 5ª Gama',
    'janby/license/user/label/writer': 'Productor de etiquetas autoconsumo',
    'janby/license/user/label/reader': 'Ver etiquetas consumidas',
    'janby/license/user/order/reader': 'Order connect',
    'janby/license/user/affiliate/organization': 'Affiliated organizations',
    'janby/license/user/affiliate/user': 'Affiliated organization users',
    'janby/license/user/affiliate/device': 'Affiliated organization devicess',
    'janby/license/user/affiliate/license': 'Affiliated organization licenses',
    'janby/license/user/gstock': 'Conexión con G-Stock',
    'janby/license/user/apicbase': 'Conexión con Apicbase',
    // Licenses device
    'janby/license/device/track/mini': 'Track Mini',
    'janby/license/device/track/core': 'Track Core',
    'janby/license/device/track/pro': 'Track Pro',
    'janby/license/device/track/intelligence': 'Track Intelligence',
    'janby/license/device/track/ordering': 'Track Order-Connect',
    'janby/license/device/track/unlimited': 'Track Unlimited',

    'sammic/license/device/smartvide/7': 'Sammic SmartVide 7',
    'sammic/license/device/smartvide/x': 'Sammic SmartVide X',

    'janby/license/device/test/core': 'Test Core',

    'janby/license/device/pick/core': 'Pick Core',

    'janby/license/device/checkpoint/core': 'Checkpoint Core',

    // Action
    Action: 'Acción | Acciones',
    cookTime: 'Tiempo de cocinado | Tiempos de cocinado',
    cookTime_desc: 'Duración que el dispositivo cronometrará con el temporizador',
    cookTimeIntermediateNotifications: 'Avisos intermedios durante el proceso',
    progressTime: 'Tiempo de progreso | Tiempos de progreso',
    progressTime_desc: 'Tiempo que el dispositivo cronometrará con el temporizador',
    holdTime: 'Tiempo esperando | Tiempos de cocinado',
    holdTime_desc: 'Tiempo con el producto listo esperando a que el operario lo extraiga',
    maxHoldTime: 'Tiempo máximo en espera',
    maxCookTime: 'Tiempo máximo de cocinado',
    waitingToStart: 'No empezar temporizador hasta',
    waitingToStart_desc: 'El temporizador quedará en espera hasta cumplir las condiciones de inicio',
    retainedCooking: 'Puede quedar retenido hasta',
    retainedCooking_desc: 'El proceso quedará en espera hasta cumplir las condiciones',
    readyToUse: 'Listo para retirar',
    readyToUse_desc: 'Proceso terminado y esperando a que el operario lo retire',
    untilProcessControl: 'Esperando hasta que el Control se cumpla',
    targetCookTime: 'Tiempo de cocinado esperado | Tiempos de cocinado esperados',
    cookTemperature: 'Temperatura de cocinado | Temperaturas de cocinado',
    bathTemperature: 'Temperatura de baño | Temperaturas de baño',
    targetCookTemperature: 'Temperatura de cocinado esperada | Temperaturas de cocinado esperadas',
    targetTemperature: 'Temperatura objetivo | Temperaturas objetivo',
    sensorValue: 'Valor del sensor | Valores de los sensores',
    targetValue: 'Valor objetivo | Valores objetivo',
    verifiedValue: 'Valor verificado | Valores verificados',
    appliedOffset: 'Corrección aplicada | Correcciones aplicadas',
    probeRequiredFinalTemperature: 'Temperatura de sonda objetivo | Temperaturas de sondas objetivo',
    probeRegisteredFinalTemperature: 'Control final de temperatura de sonda | Controles finales de temperatura de sonda',
    minimumStockValuesControl: 'Control de cantidad mínima de producto | Controles de cantidad mínima de producto',
    pasteurizationControl: 'Control de letalidad/pasteurización | Controles de letalidad/pasteurización',
    stabilizationControl: 'Control de estabilización | Controles de estabilización',
    pasteurized: 'Pasteurizado | Pasteurizados',
    'janby/action/label/generation': 'Alta de etiqueta en Cloud | Alta de etiquetas en Cloud',
    'janby/action/label/scan': 'Etiqueta escaneada | Etiquetas escaneadas',
    'janby/action/label/printing': 'Impresión de etiqueta | Impresión de etiquetas',
    'janby/action/label/printing/cooking': 'Impresión de etiqueta de elaboración | Impresión de etiquetas de elaboración',
    'janby/action/label/printing/identification': 'Impresión de etiqueta identificativa | Impresión de etiquetas identificativas',
    'sammic/action/smartvide/cooking': 'Cocinado SousVide con Sammic SmartVide | Cocinados SousVide con Sammic SmartVide',
    'sammic/action/smartvide/x/timing': 'Regeneración controlada con Sammic SmartVide X | Regeneración controlada con Sammic SmartVide X',
    'sammic/action/smartvide/calibration': 'Calibración de Sammic Smartvide | Calibraciones de Sammic Smartvide',
    'sammic/action/smartvide/calibration/manual': 'Calibración manual de Sammic Smartvide | Calibraciones manuales de Sammic Smartvide',
    'sammic/action/smartvide/calibration/ice': 'Calibración con hielo de Sammic Smartvide | Calibraciones con hielo de Sammic Smartvide',
    'sammic/action/vacuum/sealing': 'Envasado al vacío | Envasados al vacío',
    'sammic/action/vacuum/sealing/staged': 'Ciclo de Envasado por etapas | Ciclos de Envasado por etapas',
    'sammic/action/vacuum/sealing/vacnorm': 'Ciclo de envasado Vacnorm | Ciclos de envasado Vacnorm',
    'sammic/action/vacuum/oil/drying': 'Secado de aceite | Secados de aceite',
    'sammic/action/blastchiller/freeze/timing': 'Timer de Abatidor Sammic | Timers de Abatidor Sammic',
    'janby/action/track/timing': 'Timer de Janby Track | Timers de Janby Track',
    'janby/action/nop': 'Operación sin tiempo | Operación sin tiempo',
    'janby/action/pick/timing': 'Timer de Pick | Timers de Pick',
    'janby/action/track/probe/monitoring': 'Calentamiento controlado por Sonda y Janby Track | Calentamiento controlado por Sonda y Janby Track',
    'janby/action/track/probe/chilling': 'Enfriamiento controlado por Sonda y Janby Track | Enfriamiento controlado por Sonda y Janby Track',
    'janby/action/track/virtual/temperature/cooking': 'Programa de temperatura Janbyficada | Programas de temperatura Janbyficada',
    'janby/action/fridge/store/start': 'Almacenaje en nevera | Almacenajes en nevera',
    'janby/action/fridge/store/end': 'Fin Almacenaje en nevera | Fin Almacenajes en nevera',
    'janby/action/freezer/store/start': 'Almacenaje en congelador | Almacenajes en congelador',
    'janby/action/freezer/store/end': 'Fin Almacenaje en congelador | Fin Almacenajes en congelador',
    'janby/action/stock/receive': 'Recepción de stock | Recepción de stock',
    'janby/action/stock/send': 'Envío de stock | Envío de stock',
    'janby/action/stock/transfer': 'Traspaso de stock | Traspasos de stock',
    'janby/action/stock/variation': 'Variación de stock | Variaciones de stock',
    'janby/action/serve': 'Producto servido | Productos servidos',
    'janby/action/consume': 'Consumir/Servir producto | Consumir/Servir productos',
    'janby/action/reuse': 'Extraer para reutilizar | Extraer para reutilizar',
    'janby/action/waste': 'Mermar producto | Mermar productos',
    'janby/action/cancel': 'Cancelar producto | Cancelar productos',
    'janby/action/media': 'Multimedia de producto | Multimedias de productos',
    'janby/action/transition': 'Modificación de datos | Modificaciones de datos',
    'janby/action/container/content/register': 'Añadir a contenedor | Añadir a contenedores',
    'janby/action/container/content/delete': 'Sacar de contenedor | Sacar de contenedores',

    // Global Alerts
    GlobalAlert: 'Alerta global | Alertas globales',

    // Elaboration state
    ElaborationState: 'Estado de elaboración | Estados de elaboración',
    publicState_desc: 'Estado de elaboración genérico, no puede ser eliminado ni modificado | Estados de elaboración genéricos, no pueden ser eliminados ni modificados',
    'janby/state/init': 'Estado de inicio | Estados de inicio',
    'janby/state/any': 'Cualquier estado | Cualquier estado',
    'janby/state/prepare': 'Preparado | Preparados',
    'janby/state/label': 'Etiquetado | Etiquetados',
    'janby/state/cook': 'Cocinado | Cocinados',
    'janby/state/chill': 'Enfriado | Enfriados',
    'janby/state/freeze': 'Congelado | Congelados',
    'janby/state/defrost': 'Descongelado | Descongelados',
    'janby/state/thaw': 'Atemperado | Atemperados',
    'janby/state/ready': 'Ready | Ready',
    'janby/state/serve': 'Finalizado | Finalizado',
    'janby/state/end': 'Finalizado | Finalizado',
    'janby/state/consume': 'Consumido | Consumidos',
    'janby/state/waste': 'Merma | Mermas',
    'janby/state/reuse': 'Reutilizable | Reutilizables',
    'janby/state/vacuumseal': 'Envasado al vacío | Envasados al vacío',
    'janby/state/sear': 'Marcado | Marcados',
    'janby/state/broil': 'Asado | Asados',
    'janby/state/retherm': 'Regenerado | Regenerados',
    'janby/state/cancel': 'Cancelado | Cancelados',
    'janby/state/unknown': 'Desconocido | Desconocidos',

    // Location
    Location: 'Localización | Localizaciones',
    locationEntity: '{entity} en la localización | {entity} en las localizaciones',
    parentLocation: 'Localización padre | Localizaciones padre',
    leaveLocation: 'Salir de la localización',
    changeToLocation: 'Cambiar a esta localización',
    noLocationAllowed: 'Sin localizaciones permitidas',
    enrolledEntity: '{entity} asociado/a | {entity} asociado/as',
    enrolledEntityAt: '{entity} asociado/a en {name} | {entity} asociado/as en {name}',
    childLocation: 'Localización hija | Localizaciones hijas',
    childLocation_desc: 'Localizaciones que se encuentran dentro de "{name}"',
    addChildLocation_desc: 'Añade una localización hija a "{name}"',
    'janby/location/': 'Localización | Localizaciones',
    'janby/location/brand/': 'Marca | Marcas',
    'janby/location/division/': 'División | Divisiones',
    'janby/location/group/': 'Agrupación de localizaciones | Agrupaciones de localizaciones',
    'janby/location/production/': 'Centro de producción | Centros de producción',
    'janby/location/selling/': 'Punto de venta | Puntos de venta',
    'janby/location/kitchen/': 'Cocina | Cocinas',
    'janby/location/warehouse/': 'Almacén | Almacenes',
    'janby/ubication/': 'Ubicación | Ubicaciones',
    'janby/ubication/room/': 'Sala | Salas',
    'janby/ubication/shelf/': 'Balda | Baldas',
    'janby/ubication/table/': 'Mesa | Mesas',
    'janby/ubication/seat/': 'Silla | Sillas',
    'janby/ubication/position/': 'Posición | Posiciones',
    country: 'País | Paises',
    region: 'Región | Regiones',
    city: 'Ciudad | Ciudades',
    address: 'Dirección | Direcciones',
    postcode: 'Código postal | Códigos postales',
    businessArea: 'Área de negocio | Áreas de negocio',
    vatId: 'CIF | CIFs',
    rgseaa: 'Número RGSEAA | Números RGSEAA',
    rgseaa_desc: 'Número de Registro Sanitario de Empresas y Alimentos. <a href="https://www.aesan.gob.es/AECOSAN/web/seguridad_alimentaria/seccion/registro.htm" target="blank">Mas información</a>',

    // User
    User: 'Usuario | Usuarios',
    Me: 'Yo | Yo',
    userProfile: 'Cuenta y Preferencias',
    roleLevel: 'Rol en el Cloud | Roles en el Cloud',
    roleLevel_desc: 'Rol del usuario en JANBY Cloud. El rol es un atributo global del usuario que se aplica en todas las localizaciones. Posteriormente el usuario puede tener distintas licencias activadas por cada localización. La combinación del rol junto con las licencias activadas determina los permisos que tiene el usuario en cada localización.',
    'janby/role/owner': 'Propietario | Propietarios',
    'janby/role/admin': 'Administrador | Administradores',
    'janby/role/corporate': 'Corporativo | Corporativos',
    'janby/role/executive': 'Ejecutivo | Ejecutivos',
    'janby/role/manager': 'Responsable de local | Responsables de locales',
    'janby/role/user': 'Empleado | Empleados',
    userLogins: 'Historial de sesión | Historial de sesiones',
    changeEmailAndPassword: 'Cambiar correo electrónico y contraseña',
    needHelp: '¿Necesitas ayuda?',
    contactUs: 'Contacta con nosotros',
    aroundTheWorld: 'JANBY en el mundo',
    findOurProducts: 'Dónde contratar nuestros productos',
    profile_tour_0: 'Edita tus datos de usuario y preferencias.',
    profile_tour_1: 'Modifica la contraseña y accceso a tu cuenta de Janby Cloud.',
    profile_tour_2: 'Activa/Desactiva licencias para la localización actual.<br/><br/>Estas permiten ver o modificar elementos en esta localización y localizaciones hijas.',
    profile_tour_3: 'Asocia tu usuario con las localizaciones en las que deba tener acceso.<br/><br/>Dar acceso a una localización de la que dependan otras en su jerarquía, da acceso a todas las localizaciones hijas.',
    'janby/user/company/owner': 'Propietario | Propietarios',
    'janby/user/company/admin': 'Administrador | Administradores',
    'janby/user/company/manager': 'Responsable | Responsables',
    'janby/user/company/operations': 'Operaciones | Operaciones',
    'janby/user/company/operations/manager': 'Responsable de Operaciones | Responsable de Operaciones',
    'janby/user/company/sales': 'Ventas | Ventas',
    'janby/user/company/sales/manager': 'Responsable de Ventas | Responsable de Ventas',
    'janby/user/company/purchases': 'Compras | Compras',
    'janby/user/company/purchases/manager': 'Responsable de Compras | Responsable de Compras',
    'janby/user/company/marketing': 'Marketing | Marketing',
    'janby/user/company/marketing/manager': 'Responsable de Marketing |  Responsable de Marketing',
    'janby/user/company/finances': 'Finanzas | Finanzas',
    'janby/user/company/finances/manager': 'Responsable de Finanzas | Responsable de Finanzas',
    'janby/user/company/advice': 'Asesoramiento | Asesoramientos',
    'janby/user/company/advice/manager': 'Responsable de Asesoramiento | Responsable de Asesoramiento',
    'janby/user/company/technology': 'Tecnología | Tecnología',
    'janby/user/company/technology/manager': 'Responsable de Tecnología | Responsable de Tecnología',
    'janby/user/company/chef': 'Chef | Chefs',
    'janby/user/company/chef/corporate': 'Chef corporativo | Chefs corporativo',
    'janby/user/company/operator': 'Operario | Operarios',
    'janby/user/external': 'Externo | Externos',
    'janby/user/external/colaborator': 'Colaborador externo | Colaboradores externos',
    'janby/user/external/contact': 'Contact externo | Contactos externos',

    // Permission description
    permission: 'Permiso | Permisos',
    permission_desc: 'Los permisos permiten a los usuarios ver elementos y realizar acciones en una localización. Se calculan en base al rol del usuario (Empleado, Responsable, Ejecutivo, Corporativo o Administrador) y las licencias que este tiene activas en la localización actual.',
    'janby/role/owner_desc': 'Puede ver toda la organización y modificar cualquier elemento',
    'janby/role/admin_desc': 'Puede ver toda la organización y modificar cualquier elemento',
    'janby/role/corporate_desc': 'Puede crear, ver y editar elementos pero solo en sus localizaciones habilitadas',
    'janby/role/executive_desc': 'Puede crear, ver y editar elementos pero solo en sus localizaciones habilitadas',
    'janby/role/manager_desc': 'Puede ver y editar elementos pero solo en sus localizaciones habilitadas',
    'janby/role/user_desc': 'Solamente puede ver elementos en sus localizaciones habilitadas',
    permissionFromParentLocation: 'Licencia asignada en una localización superior | Licencias asignadas en una localización superior',
    allLicensesInUse: 'Todas las licencias están en uso',

    // Device
    Device: 'Dispositivo | Dispositivos',
    isBought: 'Comprado',
    isRented: 'Rentado',
    deviceLogins: 'Historial de sesión | Historial de sesiones',
    serialNumber: 'Número de serie | Números de serie',
    activationKey: 'Código de activación | Códigos de activación',
    softwareVersion: 'Versión Software | Versiones Software',
    hardwareVersion: 'Versión Hardware | Versiones Hardware',
    install: 'Instalar',
    uninstall: 'Desinstalar',

    // Menu
    Menu: 'Menú | Menús',
    MenuCourse: 'Pase de menú | Pases de menú',

    // Product
    Product: 'Producto | Productos',
    'janby/product/': 'Producto | Productos',
    'janby/product/selling/': 'Plato/Producto terminado | Platos/Productos terminados',
    'janby/product/selling': 'Plato/Producto terminado | Platos/Productos terminados', // TO BE DELETED
    'janby/product/semi/': 'Semielaborado | Semielaborados',
    'janby/product/semi': 'Semielaborado | Semielaborados', // TO BE DELETED
    'janby/product/ingredient/': 'Ingrediente | Ingredientes',
    'janby/product/ingredient': 'Ingrediente | Ingredientes', // TO BE DELETED
    product_tour_0: 'Edita los datos generales del producto..',
    product_tour_1: 'Indica los alérgenos contenidos.<br/><br/>Estos se pueden clasificar en 4 grupos en función de la seguridad de que su presencia.',
    product_tour_2: 'Diseña la ruta de elaboración del producto.<br/><br/>Para ello indica qué programas y los estados por los que el producto transcurre en cada paso.',
    product_tour_3: 'Activa la impresión de etiquetas.<br/><br/>Adicionalmente define diferentes formatos del producto con sus respectivos datos de etiquetado.',
    product_tour_4: 'Asocia a las localizaciones donde se use el producto.<br/><br/>Para que un dispositivo pueda imprimir etiquetas de este producto, deberá estar asociado a alguna de las mismas localizaciones.',
    sku: 'SKU | SKUs',
    gtin: 'GTIN | GTINs',
    gtin_desc: 'Código GTIN-14 del producto',
    production: 'Producción | Producciones',
    produceBatchOf: 'Producir un lote de | Producir lotes de',
    productionSheet: 'Ficha de producción | Fichas de producción',
    consumption: 'Consumo | Consumos',
    consumptionUnit: 'Unidad de consumo | Unidades de consumo',
    consumptionUnit_desc: 'Indica la unidad de porcionado/consumo del producto: unidades, peso, rebanadas...',
    productOrigin: 'Origen de producto | Origenes de productos',
    Producer: 'Productor | Productores',
    produced: 'Fecha de producción | Fechas de producción',
    Elaboration: 'Elaboración | Elaboraciones',
    ElaborationRoute: 'Ruta de elaboración | Rutas de elaboración',
    ElaborationStep: 'Paso de elaboración | Pasos de elaboración',
    noElaborationRoute: 'Producto sin pasos de elaboración | Productos sin pasos de elaboración',
    isPrintableInLabelQr: 'Imprimir este paso de elaboratión en Etiquetas QR para que otras organizationes puedan cocinar | Imprimir estos pasos de elaboratión en Etiquetas QR para que otras organizationes puedan cocinar',
    isPrintableInLabelQr_desc: 'Si usas QRs dentro de tu organización, tus dispositivos ya conocerán los pasos de elaboración y no es necesario imprimirlos en los QRs. Sin embargo, si planeas enviar estos QRs a otras organizaciones, selecciona qué pasos de elaboración deben ser impresos dentro del QR para que puedan ser ejecutados por otros dispositivos.',
    isPrintableInProductionSheet: 'Imprimir este paso de elaboratión en Fichas de Producción | Imprimir estos pasos de elaboratión en Fichas de Producción',
    newPagePerStep: 'Nueva página por paso de elaboración',
    ProductComposition: 'Árbol de subproducto | Árbol de subproductos',
    ProductModifier: 'Modificador de producto | Modificadores de producto',
    ProductBatching: 'Formato de etiquetado/envasado | Formatos de etiquetado/envasado',
    batchingName: 'Nombre de formato | Nombres de formato',
    batchingName_desc: 'Nombre del formato de etiquetado/envasado para ser diferenciado de otros formatos',
    batchingWeight: 'Peso del formato/envase | Peso de formato/envase',
    nextBatchSerial: 'Siguiente serial/número de lote | Siguientes seriales/números de lote',
    nextBatchSerial_desc: 'Serial/Número de lote que se asignará al siguiente lote impreso',
    printByDevice: 'Enviar etiquetas a este dispositivo | Enviar etiquetas a estos dispositivos',
    printByDevice_desc: 'Enviar etiquetas para que sean impresas por este dispositivo. Tiene que tener conexión activa al cloud y una impresora configurada',
    nextProductSerial: 'Siguiente serial/número de serie de producto | Siguientes seriales/números de serie de producto',
    nextProductSerial_desc: 'Serial/Número de serie de producto que se asignará al siguiente producto impreso',
    nextBatchId: 'Siguiente ID de lote para este formato de etiquetado | Siguientes IDs de lote para este formato de etiquetado',
    productStocking: 'Control de stock | Control de stocks',
    Allergen: 'Alérgeno | Alérgenos',
    containsAllergen: 'Alérgeno presente | Alérgenos presentes',
    tracesAllergen: 'Puede contener trazas de | Puede contener trazas de',
    cantEnsureAllergen: 'No se puede garantizar que no contenga | No se puede garantizar que no contenga',
    notContainsAllergen: 'No contiene | No contiene',
    'janby/allergen/celery/': 'Apio | Apios',
    'janby/allergen/cereal/': 'Cereal | Cereales',
    'janby/allergen/crustaceans/': 'Crustáceo | Crustáceos',
    'janby/allergen/eggs/': 'Huevo | Huevos',
    'janby/allergen/fish/': 'Pescado | Pescados',
    'janby/allergen/gluten/': 'Gluten | Gluten',
    'janby/allergen/lupin/': 'Altramuz | Altramuces',
    'janby/allergen/milk/': 'Lácteo | Lácteos',
    'janby/allergen/molluscs/': 'Molusco | Moluscos',
    'janby/allergen/mustard/': 'Mostaza | Mostaza',
    'janby/allergen/nuts/': 'Fruto con cáscara | Frutos con cáscara',
    'janby/allergen/peanuts/': 'Cacahuete | Cacahuetes',
    'janby/allergen/sesame/': 'Sésamo | Sésamo',
    'janby/allergen/so2/': 'Dioxido de azufre y sulfitos | Dioxidos de azufre y sulfitos',
    'janby/allergen/soya/': 'Soja | Soja',
    allergensNotDefined: 'Sin alérgenos definidos para este producto',
    product_image_not_defined: 'Parece que no hay imágenes de este producto',
    Recommended: 'Sugerencia | Sugerencias',
    Diets: 'Apto para dieta | Apto para dietas',
    diet:{
      vegetarian: 'Vegetariana',
      vegan: 'Vegana',
      halal: 'Halal',
      kisher: 'Kosher',
      coelian: 'Celíaca',
      lactose: 'Intolerante a la lactosa',
      blackened: 'Blackened'
    },

    // Program
    Program: 'Programa de elaboración | Programas de elaboración',
    ProductProgram: 'Producto/Programa | Productos/Programas',
    cookingConfiguration: 'Parámetro de cocinado | Parámetros de cocinado',
    ActiveControl: 'Control activo | Controles activos',
    lockEntity: 'Bloquear {entity} - Evitar que sea modificado por ningún dispositivo',
    programType_desc: 'El tipo de un programa no puede ser modifcado tras su creación. En ese caso será necesario crear uno nuevo y eliminar el anterior.',
    programProduct_desc: 'Productos con una ruta de elaboración en la que usa este programa',
    fromState: 'Estado del producto antes del programa | Estados de los productos antes del programa',
    fromState_desc: 'Estado de elaboración (de la ruta del producto) en la que se encuentra el producto antes iniciar el programa.',
    nextState: 'Estado del producto al finalizar correctamente | Estados de los productos al finalizar correctamente',
    nextState_desc: 'Estado de elaboración (de la ruta del producto) en el que se encontrará el producto tras la finalización correcta del programa.',
    reuseState: 'Estado si se extrae para reutilizar | Estados si se extraen para reutilizar',
    reuseState_desc: 'Estado de elaboración (de la ruta del producto) a la que retornará el producto si se extrae para ser reutilizado.',
    nextBestBeforeTime: 'Consumo preferente al finalizar correctamente',
    nextBestBeforeTime_desc: 'Opcionalmente establecer un consumo preferente para el producto una vez el programa haya terminado correctamente',
    nextExpirationTime: 'Caducidad al finalizar correctamente',
    nextExpirationTime_desc: 'Opcionalmente establecer una caducidad para el producto una vez el programa haya terminado correctamente',
    reuseBestBeforeTime: 'Consumo preferente si se extrae para reutilizar',
    reuseBestBeforeTime_desc: 'Opcionalmente establecer un consumo preferente para el producto si este se extrae para ser reutilizado',
    reuseExpirationTime: 'Caducidad si se extrae para reutilizar',
    reuseExpirationTime_desc: 'Opcionalmente establecer una caducidad para el producto si este se extrae para ser reutilizado',
    isConsumable: 'Al finalizar el programa, el producto puede ser consumido | Al finalizar el programa los productos pueden ser consumidos',
    isConsumable_desc: 'Si se activa, el producto podrá adicionalmente ser servido/consumido tras finalizar este paso de la ruta de elaboración. En caso contrario, el producto quedará en el estado indicado en "Estado del producto al finalizar correctamente".',

    // Container
    Container: 'Contenedor | Contenedores',
    ContainerShape: 'Formato de contenedor | Formatos de contenedores',
    ContainerContent: 'Contenido | Contenidos',
    containerMacAddress: 'Dirección MAC o ID | Direcciones MAC o ID',
    containerMacAddress_desc: 'Dirección MAC interna del RFID o Identificador del contenedor',
    sscc: 'SSCC | SSCCs',

    // Period
    period: 'Periodo | Periodos',
    'janby/period/opening': 'Apertura | Horas de apertura',
    'janby/period/cleaning': 'Limpieza | Horas de limpieza',

    // Monitor
    // Events
    Event: 'Evento | Eventos',
    Stream: 'Dato de sensor | Datos de sensores',
    History: 'Histórico | Históricos',
    entity_event: 'Evento de {entity} | Eventos de {entity}',
    entityTrace: 'Traza por {entity} | Trazas por {entity}',
    status: 'Estado | Estados',
    aggregation: 'Agregación | Agregaciones',
    registered: 'Registrado | Registrados',
    expected: 'Esperado | Esperado',
    lastRegistered: 'Última actividad | Últimas actividades',
    NextActivity: 'Próxima actividad | Próximas actividades',
    firstRegistered: 'Primera actividad | Primeras actividades',
    'janby/event': 'Finalizado | Finalizados',
    'janby/event/schedule': 'Programado | Programados',
    'janby/event/pending': 'Pendiente | Pendientes',
    'janby/event/start': 'Iniciado | Iniciados',
    'janby/event/info': 'Información | Informaciones',
    'janby/event/ready': 'Listo | Listos',
    'janby/event/warning': 'Aviso | Avisos',
    'janby/event/error': 'Error | Errores',
    'janby/event/modify': 'Modificado manualmente por usuario',
    'janby/event/recalculate': 'Configuración recalculada automáticamente',
    'janby/event/end': 'Finalizado | Finalizados',

    'janby/error': 'Error | Errores',
    'janby/error/unfinish': 'Finalizado antes de estar listo',
    'janby/error/expire': 'Producto caducado',
    'janby/error/waste': 'Finalizado como merma',
    'janby/error/reuse': 'Finalizado para reutilizar',
    'janby/error/control/fail': 'Control de proceso fallido',
    'janby/error/device/disconnect': 'Dispositivo desconectado',
    'janby/error/time/modify': 'Tiempo modificado durante el proceso',
    'janby/error/temperature/modify': 'Temperatura modificada durante el proceso',
    'janby/error/time/recalculate': 'Tiempo recalculado durante el proceso',
    'janby/error/temperature/recalculate': 'Temperatura recalculada durante el proceso',
    'janby/error/time/reset': 'Tiempo reiniciado durante el proceso',
    'janby/error/temperature/reset': 'Temperatura reiniciada durante el proceso',
    'janby/error/time/exceed': 'Excedido tiempo máximo',
    'janby/error/time/hold/exceed': 'Excedido tiempo de espera máximo',
    'janby/error/temperature/exceed': 'Excedida temperatura máxima',
    'janby/error/time/unreach': 'Tiempo mínimo no alcanzado',
    'janby/error/temperature/unreach': 'Temperatura mínima no alcanzada',

    'janby/warning': 'Aviso | Avisos',
    'janby/warning/waste': 'Finalizado como merma',
    'janby/warning/reuse': 'Finalizado para reutilizar',
    'janby/warning/control/fail': 'Control de proceso fallido',
    'janby/warning/device/disconnect': 'Dispositivo desconectado',
    'janby/warning/time/modify': 'Tiempo modificado durante el proceso',
    'janby/warning/temperature/modify': 'Temperatura modificada durante el proceso',
    'janby/warning/time/recalculate': 'Tiempo recalculado',
    'janby/warning/temperature/recalculate': 'Temperatura recalculada',
    'janby/warning/time/reset': 'Tiempo reiniciado',
    'janby/warning/temperature/reset': 'Temperatura reiniciada',
    'janby/warning/time/exceed': 'Excedido tiempo máximo',
    'janby/warning/time/hold/exceed': 'Excedido tiempo de espera máximo',
    'janby/warning/temperature/exceed': 'Excedida temperatura máxima',
    'janby/warning/time/unreach': 'Tiempo mínimo no alcanzado',
    'janby/warning/temperature/unreach': 'Temperatura mínima no alcanzada',

    'janby/info': 'Mensaje de información',
    'janby/info/serve': 'Producto servido',
    'janby/info/control/fulfil': 'Control de proceso cumplido',

    // Incidence
    Incidence: 'Incidencia | Incidencias',
    Message: 'Mensaje | Mensajes',

    // Notification
    Notification: 'Notificación | Notificaciones',
    'janby/notification/report/registered/': 'Nuevo informe | Nuevos informes',
    'janby/notification/device/registered/': 'Nuevo dispositivo | Nuevos dispositivos',
    'janby/notification/trace/warning/': 'Aviso de proceso | Avisos de proceso',
    'janby/notification/trace/error/': 'Error de proceso | Errores de proceso',
    'janby/notification/test/': 'Notificación de prueba | Notificaciones de prueba',
    'janby/notification/': 'Notificación | Notificaciones',
    resolved: 'Resuelto | Resueltos',
    resolveFirst1000: 'Resolver las 1000 mas antiguas',
    NotificationForwarder: 'Reenviar | Reenviar',
    matcher: 'Criterio | Criterios',
    forwardTo: 'Reenviar a',
    'janby/notification/forwarder/': 'Desconocido | Desconocidos',
    'janby/notification/forwarder/email/': 'Email',
    'janby/notification/forwarder/sms/': 'SMS',
    'janby/notification/forwarder/telegram/': 'Telegram',
    'janby/notification/forwarder/whatsapp/': 'WhatsApp',
    'janby/notification/forwarder/pubsub/': 'Dispositivo Janby',

    // Attachment
    Attachment: 'Adjunto | Adjuntos',

    // Labeling
    // Batches
    LabelBatch: 'Lote | Lotes',
    LabelBatchItem: 'Elemento del lote | Elementos del lote',
    labelingData: 'Datos de etiquetado',
    labelingData_desc: 'Las etiquetas identifican los productos y configuran los dispositivos para su elaboración',
    allowPrintLabelsForEntity: 'Permitir imprimir etiquetas para este/a {entity} | Permitir imprimir etiquetas para estos/as {entity}',
    Item: 'Elemento | Elementos',
    Label: 'Etiqueta | Etiquetas',
    labelIsPending: 'Pendiente de ser consumido | Pendientes de ser consumidos',
    serial: 'Serial | Seriales',
    mask: 'Máscara | Máscaras',
    noLabelBatchId: 'Sin lotificar | Sin lotificar',
    labelCount: 'Cantidad de etiquetas en el lote | Cantidad de etiquetas en los lotes',
    servingCount: 'Cantidad de raciones | Cantidad de raciones',
    isSerial: 'Número de serie en etiqueta | Números de serie en etiquetas',
    'janby/label/cooking/individual': 'Etiquetas individuales',
    'janby/label/cooking/range': 'Etiqueta de rango',
    'janby/label/cooking/step': 'Etiqueta por cada paso de elaboración',
    scannedLabel: 'Etiqueta escaneada | Etiquetas escaneadas',
    serve_pending: 'Pendiente | Pendientes',
    batchPattern: 'Patrón para generar lote | Patrones para generar lotes',
    batchPattern_desc: 'Patrón para generar los identificadores de lote. Estos pueden estar compuestos por letras y números y utilizar atajos para inyectar información como la fecha, día o número de serie. Datos inyectables:<br/>(S): Serial de producto<br/>(BS): Serial de lote<br/>(Y): Año de envasado<br/>(M): Mes de envasado<br/>(D): Día de envasado<br/>(R): Código aleatorio',
    batchItem: 'Etiqueta en lote | Etiquetas en cada lote',
    batchItem_desc: 'Cantidad de etiquetas por defecto para imprimir en cada lote. Durante la impresión se puede modificar el número de etiquetas si es necesario.',
    countPerBatchItem: '{unit} en cada bolsa | {unit} en en cada bolsa',
    countPerBatchItem_desc: 'Cantidad de producto contenido en cada bolsa/formato | Cantidad de producto contenido en cada bolsa/formato',
    servingCount: 'Cantidad de raciones | Cantidad de raciones',
    countPerServing: '{unit} por ración | {unit} por raciones',
    countPerServing_desc: 'Cantidad de producto en cada ración | Cantidad de producto en cada ración',
    bestBeforeTime: 'Tiempo recomendado de consumo | Tiempos recomendados de consumo',
    expirationTime: 'Tiempo de caducidad | Tiempos de caducidad',
    expiration_nearer_than_period: 'La caducidad es inferior a {period} | Las caducidades son inferiores a {period}',
    expiration_with_pending: 'Caducado con productos sin utilizar | Caducados con productos sin utilizar',

    // Templates
    LabelTemplate: 'Plantilla de impresión para etiquetas | Plantillas de impresión para etiquetas',
    LabelTemplateBlock: 'Campos de plantilla | Campos de las plantillas',
    "janby/labeling/template/label/name/": "Nombre completo (Producto + Formato + Lote) | Nombres completos (Productos + Formatos + Lotes)",
    'janby/labeling/template/organization/name/': 'Nombre de la organización | Nombres de la organización',
    'janby/labeling/template/organization/vatId/': 'CIF de la organización | CIFs de la organización',
    'janby/labeling/template/organization/health/': 'Número RGSEAA de la organización | Números RGSEAA de la organización',
    'janby/labeling/template/organization/web/': 'Web de la organización | Webs de la organización',
    'janby/labeling/template/organization/address/': 'Dirección de la organización | Direcciones de la organización',
    'janby/labeling/template/organization/metas/': 'Propiedades personalizables públicas de la organización | Propiedades personalizables públicas de la organización',
    'janby/labeling/template/product/name/': 'Nombre del producto | Nombres de los productos',
    'janby/labeling/template/product/metas/': 'Propiedades personalizables públicas del producto | Propiedades personalizables públicas de los productos',
    'janby/labeling/template/batching/name/': 'Nombre del formato/envase | Nombres de los formato/envase',
    'janby/labeling/template/batching/weight/': 'Peso del formato/envase | Pesos de formato/envase',
    'janby/labeling/template/batching/expectedCount/': 'Cantidad de producto | Cantidades de producto',
    'janby/labeling/template/batching/servingCount/': 'Cantidad de raciones | Cantidades de raciones',
    'janby/labeling/template/batching/metas/': 'Propiedades personalizables públicas del formato de etiquetado/envasado | Propiedades personalizables públicas de los formatos de etiquetado/envasado',
    'janby/labeling/template/task/name/': 'Nombre de la tarea | Nombres de las tareas',
    'janby/labeling/template/task/description/': 'Descripción de la tarea | Descripciones de las tareas',
    'janby/labeling/template/label/batchId/': 'ID de lote y porción | IDs de lote y porción',
    'janby/labeling/template/label/registered/': 'Fecha de creación | Fechas de creación',
    'janby/labeling/template/label/bestBefore/': 'Consumo preferente | Consumos preferentes',
    'janby/labeling/template/label/expiration/': 'Caducidad | Caducidades',
    'janby/labeling/template/label/barcode/': 'Código de barras | Códigos de barras',
    'janby/labeling/template/label/bytecode/': 'Código QR | Códigos QR',
    'janby/labeling/template/hspace/': 'Espacio horizontal | Espacios horizontales',
    'janby/labeling/template/hline/': 'Línea horizontal | Líneas horizontales',

    // Trace
    Trace: 'Traza | Trazas',
    timeline: 'Traza completa | Trazas completas',

    // Order
    Order: 'Comanda | Comandas',
    OrderBatch: 'Comanda | Comandas',
    OrderBatchItem: 'Elemento de comanda | Elementos de comanda',
    OrderForwarder: 'Recibir comanda en dispositivo | Recibir comandas en dispositivos',
    orderForwarderLocation: 'Comandas recibidas en esta localización e hijas',
    'janby/order/pending': 'Pendiente | Pendientes',
    'janby/order/cancel': 'Cancelada | Canceladas',
    'janby/order/start': 'Iniciada | Iniciadas',
    'janby/order/end': 'Finalizada | Finalizadas',

    // Innovation
    Innovation: 'Cocina de pruebas | Cocinas de pruebas',
    Experiment: 'Experimento | Experimentos',
    ExperimentQualityParameter: 'Parámetro de calidad | Parámetros de calidad',

    // Stock
    Stock: 'Stock | Stocks',
    Evolution: 'Evolución | Evoluciones',
    StockVariation: 'Variación | Variaciones',
    stockIncrease: 'Incremento | Incrementos',
    stockDecrease: 'Decremento | Decrementos',
    Waste: 'Merma | Mermas',
    StockCheckpoint: 'Inventario | Inventarios',
    CheckpointBatch: 'Grupo de Inventario | Grupos de Inventario',
    StockTransfer: 'Traspaso | Traspasos',
    transferSource: 'Origen del traspaso | Orígenes del traspaso',
    transferTarget: 'Destino del traspaso | Destinos del traspaso',
    outOfStock: 'Sin stock | Sin stocks',
    availableStock: 'Stock disponible | Stocks disponibles',
    entityStock: 'Stock de {entity} | Stock de {entity}',
    stockEstimatedAvailability: 'Disponibilidad estimada | Disponibilidades estimadas',
    isPositiveCount: 'Variación positiva | Variaciones positivas',
    isNegativeCount: 'Variación negativa | Variaciones negativas',
    stockVariation: 'Variación de stock | Variaciones de stock',
    stockVariation_desc: 'Cantidad variada el/la pasado/a {period}',
    stockVariationPerPeriod: 'Variación en {period} | Variaciones en {period}',
    stockCovered: 'Consumo cubierto para {period} | Consumo cubierto para {period}',
    stockCovered_desc: 'Disponibilidad en base a la variación en {period}.',
    historyCanBeLimited: 'Los datos pueden estar reducidos a los {days} de histórico que tu licencia permite consultar.',

    // UserControl
    UserControl: 'Control de usuario | Control de usuarios',
    UserToken: 'Token de identidad | Tokens de identidad',
    tokenPIN: 'MAC o PIN | MACs o PINs',
    tokenPIN_desc: 'Dirección MAC del token/pulsera o el código PIN que se utiliza para identificar al usuario',
    tokenLocations_desc: 'Ubicaciones donde se utiliza el token/pulsera',

    // Store
    Store: 'Tienda | Tiendas',
    StorePurchase: 'Compra/Suscripción | Compras/Suscripciones',
    nonSubscriptionPurchase: 'Accesorio o producto no suscripción | Accesorios o productos no suscripción',
    priceAmount: 'Importe | Importes',
    tax: 'Impuesto | Impuestos',
    shipping: 'Envío | Envíos',
    shippingTax: 'Impuesto de envío | Impuestos de envío',
    discount: 'Descuento | Descuentos',
    discountTax: 'Impuesto de descuento | Impuestos de descuento',

    // Haccp
    Haccp: 'Appcc | Appccs',
    HaccpPoint: 'Punto de control | Puntos de control',
    templateMessage: 'Mensaje de plantilla | Mensajes de plantilla',
    'janby/haccp/point/': 'Punto de control | Puntos de control',
    'janby/haccp/point/confirm/': 'Punto ACCPP de tipo Sí/No | Puntos ACCPP de tipo Sí/No',
    'janby/haccp/point/datetime/date/': 'Punto ACCPP de selección de Fecha | Puntos ACCPP de selección de Fecha',
    'janby/haccp/point/datetime/time/': 'Punto ACCPP de selección de Hora | Puntos ACCPP de selección de Hora',
    'janby/haccp/point/user/': 'Punto ACCPP de selección de Usuario | Puntos ACCPP de selección de Usuario',
    
    // Alert
    'janby/alert/labeling/': 'Alerta de producto etiquetado | Alertas de producto etiquetado',
    'janby/alert/haccp/': 'Alerta APPCC | Alertas APPCC',
    'janby/alert/product/foodsafety/': 'Alerta de Seguridad alimentaria | Alertas de Seguridad alimentaria',
    'janby/alert/product/': 'Alerta de producto',

    // Planning
    Planning: 'Planificación | Planificaciones',
    Task: 'Tarea | Tareas',
    SubTask: 'Subtarea | Subtareas',
    Trigger: 'Disparador | Disparadores',
    parent: 'Padre | Padres',
    hierarchy: 'Jerarquía | Jerarquías',
    taskAction: 'Acción a realizar en la tarea | Acciones a realizar en las tareas',
    'janby/task/pending/': 'Pendiente | Pendientes',
    'janby/task/cancel/': 'Cancelada | Canceladas',
    'janby/task/start/': 'Iniciada | Iniciadas',
    'janby/task/end/': 'Finalizada | Finalizadas',
    'janby/task/pause/': 'Pausada | Pausadas',
    recurrence: 'Repetición | Repeticiones',
    recurrence_desc: 'Cada cuánto se repite la tarea',
    'janby/recurrence/none': 'Ninguna | Ningunas',
    'janby/recurrence/day': 'Repetición diaria | Repeticiones diarias',
    'janby/recurrence/day_desc': 'Generado cada día. Utilizará los datos desde el instante de generación hasta 24h antes.',
    'janby/recurrence/week': 'Repetición semanal | Repeticiones semanales',
    'janby/recurrence/week_desc': 'Generado cada 7 días. Utilizará los datos desde el instante de generación hasta los 7 días anteriores',
    'janby/recurrence/month': 'Repetición mensual | Repeticiones mensuales',
    'janby/recurrence/month_desc': 'Generado cada mes. Utilizará los datos desde el instante de generación hasta los 30/31 anteriores',
    instructions: 'Instrucciones | Instrucciones',
    duration: 'Duración | Duraciones',
    expectedDuration: 'Duración esperada | Duraciones esperadas',
    Forecaster: 'Forecaster | Forecasters',
    forecastingCondition: 'Condición de forecast | Condiciones de forecasts',
    Pattern: 'Patrón | Patrones',
    'janby/pattern/dayhours/': 'Patrón 24 horas',
    'janby/pattern/weekdays/': 'Patrón días de la semana',
    'janby/pattern/weekhours/': 'Patrón horas de la semana',
    'janby/pattern/monthdays/': 'Patrón días del mes',
    'janby/pattern/monthhours/': 'Patrón horas del mes',

    // Provider
    EquipmentProvider: 'Fabricante | Fabricantes',
    path: 'Ruta | Rutas',
    Equipment: 'Equipamiento | Equipamientos',
    DevicePosition: 'Posición dentro de dispositivo | Posiciones dentro de dispositivo',
    ExecutableAction: 'Acción ejecutable | Acciones ejecutables',
    InstallableAction: 'Acción instalable | Acciones instalables',
    SchedulableAction: 'Acción programable | Acciones programables',
    Sensor: 'Sensor | Sensores',
    Software: 'Software/Firmware | Softwares/Firmwares',
    'janby/sensor/temperature': 'Temperatura | Temperatura',
    'janby/sensor/humidity': 'Humedad | Humedad',
    'janby/sensor/temperature/water': 'Temperatura de agua | Temperatura de agua',
    'janby/sensor/temperature/probe': 'Sonda de temperatura | Sonda de temperatura',
    'janby/sensor/battery/level': 'Nivel de batería | Niveles de batería',
    'sammic/sensor/smartvide/water/temperature': 'Sensor de temperatura de agua de Sammic SmartVide',
    'sammic/sensor/smartvide/probe/temperature': 'Sonda de temperatura de Sammic SmartVide',
    freezer: 'Congelador | Congeladores',
    fridge: 'Frigorífico | Frigoríficos',
    oven: 'Horno | Hornos',
    blastChiller: 'Abatidor | Abatidores',

    // Affiliate
    Affiliate: 'Afiliado | Afiliados',

    // Reporting
    Report: 'Informe | Informes',
    ScheduledReport: 'Plantilla de informe | Plantillas de informes',
    ReportValidation: 'Validación de informe | Validaciones de informes',
    Insight: 'Análisis | Análisis',
    thematicReport: 'Informe temático | Informes temáticos',
    nextReportGeneration: 'Próxima generación de informe | Próximas generaciones de informe',
    nextReportGeneration_desc: 'Fecha de la próxima generación del informe recurrente',
    'janby/report/entity/events': 'Informe de {entity} | Informes de {entity}',
    'janby/report/action/events': 'Informe de acciones | Informe de acciones',
    'janby/report/location/events': 'Informe de Actividad en localización | Informes de Actividad en localización',
    'janby/report/labeling/expirations': 'Informe de próximas caducidades | Informes de próximas caducidades',
    'janby/report/stock/variations': 'Informe de Variaciones de stock | Informes de Variaciones de stock',

    // Food
    Food: 'Alimento | Alimentos',
    FoodPasteurization: 'Pasteurización | Pasteurizaciones',
    food_sw: 'Pollo | Pollo',
    food_sx: 'Ternera | Ternera',
    food_1yxt6: 'Ternera (Cocinada) | Ternera (Cocinada)',
    food_1ytst: 'Ternera (Enlatada) | Ternera (Enlatada)',
    food_23y: 'Ternera (Asada) | Ternera (Asada)',
    food_su: 'Cerdo | Cerdo',
    food_1on: 'Conejo | Conejo',
    food_zq: 'Pavo | Pavo',
    food_1jc: 'Cordero | Cordero',
    food_29z: 'Hamburguesa | Hamburguesas',

    // Integration
    ThirdPartyConnector: 'Integración | Integraciones',
    Connector: 'Conector | Conectores',
    Webhook: 'Webhook | Webhooks',
    lastRun: 'Última vez invocado | Últimas veces invocados',
    secret: 'Secreto | Secretos',
    secret_desc: 'Si el software no es público, será necesario indicar este secreto en la llamada a la API',
    isPublicForAPI_desc: 'Si se activa, será visible en las llamadas a la API',
    costCenter: 'Centro de costes | Centros de costes',
    plate: 'Plato | Platos',
    recipe: 'Receta | Recetas',
    subRecipe: 'Subreceta | Subrecetas',
    outlet: 'Punto de venta | Puntos de venta',
    nextSync: 'Fecha de próxima sincronización automática | Fechas de próxima sincronización automática',
    processMayTakeLong: 'Este proceso puede tardar varios minutos | Estos procesos pueden tardar varios minutos',
    integrationDataPolicy: 'AVISO: JANBY solo almacena la información de localizaciones, productos, pedidos y elaboraciones que sea mínima y necesaria para identificar correctamente los procesos de cocinado de tu organización. En ningún caso se almacenan ni se utilizan datos relativos a transacciones económicas, precios de productos o clientes de restaurante.',

    // Showcase
    Gallery: 'Galería | Galerías',
    Media: 'Media | Medias',
    publicHtml: 'Texto adicional | Textos adicionales',
    caption: 'Leyenda | Leyendas',
    recommendedEntity: '{entity} recomendado/a | {entity} recomendados/as',

    // Crm
    Crm: 'Soporte de cliente | Soportes de clientes',
    CrmCompany: 'Empresa | Empresas',
    CrmContact: 'Contacto | Contactos',
    CrmEvent: 'Evento/Reunión | Eventos/Reuniones',
    CrmTicket: 'Ticket de soporte | Tickets de soporte',
    Attendee: 'Asistente | Asistentes',
    Calendar: 'Calendario | Calendarios',
    upcoming_entity: 'Próximo/a {entity} | Próximos/as {entity}',
    expectedStart: 'Inicio esperado | Inicios esperados',
    maximumStart: 'Inicio máximo | Inicios máximos',
    expectedReady: 'Listo esperado | Listos esperados',
    maximumReady: 'Listo máximo | Listos máximos',
    expectedEnd: 'Fin esperado | Fines esperados',
    maximumEnd: 'Final máximo | Finales máximos',
    activeFrom: 'Activo desde | Activos desde',
    activeTo: 'Activo hasta | Activos hasta',
    repeatEvery: 'Repetir cada | Repetir cada',
    companyPosition: 'Puesto en la empresa | Puestos en la empresa',
    priority: 'Prioridad | Prioridades',
    category: 'Categoría | Categorías',
    employee: 'Empleado/a | Empleados/as',
    thankMessage: 'Gracias por contactar con Janby. Nos pondremos en contacto contigo lo antes posible.',
    'janby/crm/event/email': 'E-mail | E-mail',
    'janby/crm/event/email/out': 'Email enviado | Emails enviados',
    'janby/crm/event/email/in': 'Email recibido | Emails recibidos',
    'janby/crm/event/call': 'Llamada | Llamadas',
    'janby/crm/event/call/out': 'Llamada a cliente | Llamadas a cliente',
    'janby/crm/event/call/out|missed': 'Llamada sin éxito a cliente | Llamadas sin éxito a cliente',
    'janby/crm/event/call/in': 'Llamada recibida | Llamadas recibidas',
    'janby/crm/event/call/in/missed': 'Llamada perdida | Llamadas perdida',
    'janby/crm/event/visit': 'Visita | Visitas',
    'janby/crm/event/visit/out': 'Visita a cliente | Visitas a cliente',
    'janby/crm/event/visit/in': 'Visita en Janby | Visitas en Janby',
    'janby/crm/event/meeting': 'Reunión | Reuniones',
    'janby/crm/event/meeting/video': 'Videollamada | Videollamada',
    'janby/crm/event/meeting/in': 'Reunión en Janby | Reuniones en Janby',
    'janby/crm/event/meeting/out': 'Renión en cliente | Reuniones en clientes',

    // Others
    plural_ex: 'You have {n, plural, =0{no messages} one{1 message} other{# messages}}.',
    select_ex: '{gender, select, male{He} female{She} other{They}} liked this.',
    number_ex: 'Current Percent: {current, number, percent}',
    time_ex: 'Current Time: {current, time, short}',

    // Units
    'janby/unit/time': 'Tiempo | Tiempos',
    'janby/unit/temperature': 'Temperatura | Temperaturas',
    'janby/unit/temperature/C': 'Celsius (ºC) | Celsius (ºC)',
    'janby/unit/temperature/F': 'Fahrenheit (ºF) | Fahrenheit (ºF)',
    'janby/unit/percentage': 'Porcentaje | Porcentajes',

    'janby/unit/weight': 'Peso | Pesos',
    'janby/unit/weight/g': 'Gramo (g) | Gramos (g)',
    'janby/unit/weight/kg': 'Kilogramo (kg) | Kilogramos (kg)',
    'janby/unit/weight/mg': 'Miligramo (mg) | Miligramos (mg)',
    'janby/unit/weight/oz': 'Onza (oz) | Onzas (oz)',
    'janby/unit/weight/lb': 'Libra (lb) | Libras (lb)',

    'janby/unit/length': 'Dimensión | Dimensiones',
    'janby/unit/length/m': 'Metro (m) | Metros (m)',
    'janby/unit/length/cm': 'Centímetro (cm) | Centímetros (cm)',
    'janby/unit/battery/level': 'Nivel de Bateria | Niveles de Batería',

    'janby/unit': 'Unidad | Unidades',
    'janby/unit/serving': 'Ración | Raciones',
    'janby/unit/leaf': 'Hoja | Hojas',
    'janby/unit/slice': 'Rebanada | Rebanadas',
    'janby/unit/calory': 'Caloría | Calorías',
    'janby/unit/rating/stars/': 'Star | Stars',

  }
}
