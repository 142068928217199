const routes = [
  {
    path: '/provider/sensors/:page?',
    props: true,
    component: () => import('@/views/provider/sensor/Sensors.vue')
  },
  {
    path: '/provider/sensor/:sensorId/:nav?/:page?',
    props: true,
    component: () => import('@/views/provider/sensor/Sensor.vue')
  }
]

export default routes
