const routes = [
  {
    path: '/stock/products/:page?',
    props: true,
    component: () => import('@/views/stock/product/ProductsStock.vue')
  },
  {
    path: '/stock/locations/:page?',
    props: true,
    component: () => import('@/views/stock/location/LocationsStock.vue')
  },
  {
    path: '/stock/states/:page?',
    props: true,
    component: () => import('@/views/stock/state/StatesStock.vue')
  },

  {
    path: '/stock/expirations/:searchType?/:page?',
    props: true,
    component: () => import('@/views/stock/expiration/Expirations.vue')
  },

  {
    path: '/stock/product/:productId/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/stock/product/ProductStock.vue')
  },
  {
    path: '/stock/register/transfer',
    props: true,
    component: () => import('@/views/stock/transfer/Register.vue')
  },
  {
    path: '/stock/transfers/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/stock/transfer/Transfers.vue')
  },
  {
    path: '/stock/register/checkpoints',
    props: true,
    component: () => import('@/views/stock/batch/Register.vue')
  },
  {
    path: '/stock/checkpoints/batches/:page?',
    props: true,
    component: () => import('@/views/stock/batch/Batches.vue')
  },
  {
    path: '/stock/register/variations/positive',
    props: true,
    component: () => import('@/views/stock/variation/PositiveVariationRegister.vue')
  },
  {
    path: '/stock/register/variations/negative',
    props: true,
    component: () => import('@/views/stock/variation/NegativeVariationRegister.vue')
  },
  {
    path: '/stock/variations/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/stock/variation/Variations.vue')
  },
]

export default routes
