const routes = [
  {
    path: '/organization/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/organization/Organization.vue')
  },
  {
    path: '/license/:licenseId?/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/license/License.vue')
  },
  {
    path: '/ticket/:ticketId?/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/ticket/Ticket.vue')
  }
]

export default routes
