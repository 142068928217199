const routes = [
  {
    path: '/reporting/reports/:page?',
    props: true,
    component: () => import('@/views/reporting/report/ReportsList.vue')
  },
  {
    path: '/reporting/report/:reportId/:nav?/:page?',
    props: true,
    component: () => import('@/views/reporting/report/ReportDetails.vue')
  },
  {
    path: '/reporting/scheduled-reports/:page?',
    props: true,
    component: () => import('@/views/reporting/scheduled/ScheduledReportsList.vue')
  },
  {
    path: '/reporting/scheduled-report/:scheduledId/:nav?/:page?',
    props: true,
    component: () => import('@/views/reporting/scheduled/ScheduledReportDetails.vue')
  },
  {
    path: '/reporting/location/events',
    props: true,
    component: () => import('@/views/reporting/location/LocationEventsReport.vue')
  },
  {
    path: '/reporting/location/events/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/reporting/location/LocationEventsReport.vue')
  },
  {
    path: '/reporting/labeling/expirations',
    props: true,
    component: () => import('@/views/reporting/labeling/LabelExpirationsReport.vue')
  },
  {
    path: '/reporting/labeling/expirations/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/reporting/labeling/LabelExpirationsReport.vue')
  },
  {
    path: '/reporting/stock/variations',
    props: true,
    component: () => import('@/views/reporting/stock/VariationEventsReport.vue')
  },
  {
    path: '/reporting/stock/variations/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/reporting/stock/VariationEventsReport.vue')
  },
  {
    path: '/reporting/action/:actionId/events',
    props: true,
    component: () => import('@/views/reporting/action/ActionEventsReport.vue')
  },
  {
    path: '/reporting/action/:actionId/events/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/reporting/action/ActionEventsReport.vue')
  }
]

export default routes
