const products = [
  {
    path: '/product/:productId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/product/Product.vue')
  },
  {
    path: '/products/:page?',
    props: true,
    component: () => import('@/views/base/product/Products.vue')
  },
  {
    path: '/ingredients/:page?',
    props: true,
    component: () => import('@/views/base/product/types/IngredientProducts.vue')
  },
  {
    path: '/ingredient/:productId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/product/Product.vue')
  },
  {
    path: '/semi-products/:page?',
    props: true,
    component: () => import('@/views/base/product/types/SemiProducts.vue')
  },
  {
    path: '/semi-product/:productId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/product/Product.vue')
  },
  {
    path: '/selling-products/:page?',
    props: true,
    component: () => import('@/views/base/product/types/SellingProducts.vue')
  },
  {
    path: '/selling-product/:productId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/product/Product.vue')
  }
]

export default products
