const routes = [
    {
      path: '/innovation/parameters/:page?',
      props: true,
      component: () => import('@/views/innovation/qualityparameter/Parameters.vue')
    },
    {
      path: '/innovation/parameter/:parameterId/:nav?/:page?',
      props: true,
      component: () => import('@/views/innovation/qualityparameter/Parameter.vue')
    },
  ]
  
  export default routes
  