<template>

    <b-alert :show="show" :variant="variant" :class="`${textClass} ${borderClass} text-center w-100`" :dismissible="dismissible" @dismissed="$emit('dismissed')">
        
        <template v-slot:default>

          <b-skeleton v-if="busy" height="32px" class="w-100"/>

          <div v-if="!busy" class="d-flex align-items-center w-100">
              <slot name="Content">
                <i v-if="icon" :class="`material-icons mr-2 ${textClass}`">{{icon}}</i>
                <div class="w-100"><slot name="Text">{{text}}</slot></div>
                <slot name="Append"/>
              </slot>
          </div>

          <div v-if="!busy" class="w-100"><slot name="Extra"/></div>

        </template>

    </b-alert>

</template>

<script>

export default {
  props: {
    show: {
      type: Boolean
    },
    busy: {
      type: Number
    },
    icon: {
      type: String
    },
    text: {
      type: String
    },
    variant: {
      type: String,
      default () { return 'secondary' }
    },
    textClass: {
      type: String,
      default () { return 'text-dark' }
    },
    borderClass: {
      type: String,
      default () { return 'border-dark' }
    },
    dismissible: {
      type: Boolean
    }
  }
}

</script>
