<template>

        <!-- Label/Batch generation -->
        <SidenavSection v-if="permissions && permissions.LA"
        :name="$tc('m.production')"
        icon="receipt_long"
        :navs="[
          // { name: $tc('m.Summary'), href: `/#/labeling/dashboard`, active: '/labeling/dashboard', minPermissions: { RO: 99 } },
          // { name: $tc('m.mask', 2), href: `/#/labeling/register/mask`, active: '/labeling/register/mask', minPermissions: { RO: 99 } },
          { name: $tc('m.LabelBatch', 2), href: `/#/labeling/batches`, active: ['/labeling/product/', '/labeling/batch/', '/labeling/batches'] },
          { name: $tc('m.Label', 2), href: `/#/labeling/labels`, active: '/labeling/label' },
          { name: $tc('m.LabelTemplate', 2), href: `/#/labeling/templates`, active: '/labeling/template' },
          { name: $tc('m.janby/alert/labeling/', 2), href: `/#/labeling/alerts`, active: '/labeling/alert', minPermissions: { GA: 1 } },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="production"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
