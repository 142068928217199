<template>

        <!-- User control generation -->
        <SidenavSection
        :name="$tc('m.UserControl')"
        icon="watch"
        :navs="[
          // { name: $tc('m.Summary'), href: `/#/user-control/dashboard`, active: '/user-control/dashboard' },
          { name: $tc('m.UserToken', 2), href: `/#/user-control/tokens`, active: '/user-control/token', minPermissions: { UT: 1 }, count: counts.UT },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="usercontrol"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import i18n from '@/i18n/i18n'

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
