<template>

      <!-- Affiliate options -->
      <SidenavSection v-if="permissions && permissions.RO >= 99"
        name="Cloud admin"
        icon="security"
        :navs="[]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="cloud-admin"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
