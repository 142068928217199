const routes = [
  {
    path: '/integration/webhooks/:page?',
    props: true,
    component: () => import('@/views/integration/webhook/Webhooks.vue')
  },
  {
    path: '/integration/webhook/:webhookId/:nav?/:page?',
    props: true,
    component: () => import('@/views/integration/webhook/Webhook.vue')
  }
]

export default routes
