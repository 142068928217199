<template>
  <div>

      <div v-if="location" split split-variant="link" variant="link" split-to="/location" flush>

        <b-dropdown-item :title="`${location.name} - ${$tc('m.' + (location.type || 'janby/location/' ))}`" @click="$emit('update', 'location', location)" class="w-100">

            <i v-if="loggedLocation && loggedLocation.id == location.id" class="material-icons position-absolute" style="margin-left:-24px">arrow_right</i>
            
            <div class="w-100 text-truncate">
              <span :style="`padding-left:calc(${location.depth} * 24px)`">
                {{location.name}}
              </span>
            </div>

        </b-dropdown-item>

      </div>

  </div>
</template>

<script>

export default {
  name: 'LocationPickerList',
  props: {
    loggedLocation: {
      type: Object
    },
    location: {
      type: Object
    },
    depth: {
      type: Number,
      default () { return 0 }
    }
  }
}
</script>
