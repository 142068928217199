<template>

    <b-row :class="`m-0 d-inline-flex w-100 data-info-card position-relative ${classes}`" :style="`background: ${background}; border: 1px solid ${border}`">

        <b-col class="m-0 p-0" :style="`display:contents;width:${imageWidth}`">
          <slot name="BeforeImage"/>

          <!-- IMAGE-->
          <slot name="DataImage">
          
            <!-- LINK -->
            <b-link v-if="href" :href="href">
              <DataImage :src="image" :src2="subimage" :color="color" :href="href" :width="imageWidth" class="rounded"/>
            </b-link>

            <!-- NO LINK -->
            <div v-else>
              <DataImage :src="image" :src2="subimage" :color="color" :width="imageWidth" class="rounded"/>
            </div>

          </slot>

          <slot name="AfterImage"/>
        </b-col>

        <b-col :class="`d-flex justify-content-start align-items-center pl-2 ${rightIcon ? 'pr-5' : 'pr-2'}`" :style="`width:calc(100% - ${imageWidth})`">

          <slot name="BeforeContent"/>
          <slot name="Content">

              <div class="w-100">

                <!-- LINK -->
                <b-link v-if="href" :href="href" class="text-dark">

                    <slot name="Name">
                      <Marquee :startOnMount="true" class="text-left w-100">
                        <h6 class="my-0">{{name}}</h6>
                      </Marquee>
                    </slot>

                    <slot name="Description">
                      <Marquee :startOnMount="true" class="text-left w-100" style="max-height:64px">
                        <small v-if="description" class="text-muted"><div v-html="description" class="p-0 m-0"/></small>
                      </Marquee>
                    </slot>
                  
                </b-link>

                <!-- NO LINK -->
                <div v-else class="text-dark w-100">
                  <slot name="Name">
                    <Marquee :startOnMount="true" class="text-left w-100">
                      <h6 class="my-0">{{name}}</h6>
                    </Marquee>
                  </slot>

                  <slot name="Description">
                    <Marquee :startOnMount="true" class="text-left w-100">
                      <small v-if="description" class="text-muted"><div v-html="description" class="p-0 m-0"/></small>
                    </Marquee>
                  </slot>
                </div>
  
              </div>
              
          </slot>
          <slot name="AfterContent"/>

      </b-col>

      <b-col cols="12"><slot name="AfterContentAndImage"/></b-col>

      <i v-if="rightIcon" class="material-icons text-dark position-absolute" style="right:24px;top:calc(50% - 12px)">{{rightIcon}}</i>
    </b-row>

</template>

<script>

import DataImage from '@/views/components/_datacard/DataImage'
import Marquee from '@/views/components/_utils/Marquee.vue'

export default {
  props: {
    name: {
      type: String
    },
    description: {
      type: String
    },
    image: {
      type: String
    },
    subimage: {
      type: String
    },
    imageWidth: {
      type: String,
      default () { return '64px' }
    },
    rightIcon: {
      type: String
    },
    href: {
      type: String
    },
    color: {
      type: String
    },
    background: {
      type: String,
      default () { return 'transparent' }
    },
    border: {
      type: String,
      default () { return 'transparent' }
    },
    classes: {
      type: String
    }
  },
  components: {
    DataImage,
    Marquee,
  }
}

</script>
