<template>

        <!-- Location resources -->
        <SidenavSection v-if="location && counts"
        :name="$tc('m.Location')"
        icon="storefront"
        :navs="[
          { name: $tc('m.Location'), href: `/#/location/${location.id}`, active: `/location/${location.id}` },
          { name: $tc('m.User', counts.U), href: `/#/users`, active: ['/users', '/user/'], minPermissions: { U: 1 }, count: counts.U },
          { name: $tc('m.Device', counts.D), href: `/#/devices`, active: ['/devices', '/device/'], minPermissions: { D: 1 }, count: counts.D },
          { name: $tc('m.Menu', counts.M), href: `/#/menus`, active: ['/menus', '/menu/'], minPermissions: { M: 1 }, count: counts.M },
          { name: $tc('m.janby/product/selling/', counts['P.janby/product/selling/']), href: `/#/selling-products`, active: ['/selling-products', '/selling-product/', '/products/', '/product/'], minPermissions: { P: 1 }, count: counts['P.janby/product/selling/'] || 0 },
          { name: $tc('m.janby/product/semi/', counts['P.janby/product/semi/']), href: `/#/semi-products`, active: ['/semi-products', '/semi-product/'], minPermissions: { P: 1 }, count: counts['P.janby/product/semi/'] || 0 },
          { name: $tc('m.janby/product/ingredient/', counts['P.janby/product/ingredient/']), href: `/#/ingredients`, active: ['/ingredients', '/ingredient/'], minPermissions: { P: 1 }, count: counts['P.janby/product/ingredient/'] || 0 },
          { name: $tc('m.Program', counts.R), href: `/#/programs`, active: ['/programs', '/program/'], minPermissions: { R: 1 }, count: counts.R },
          { name: $tc('m.ElaborationState', 2), href: `/#/states`, active: ['/states', '/state/'], minPermissions: { R: 1, P: 1 },  count: counts.RS },
          { name: $tc('m.Attachment', 2), href: `/#/attachments`, active: ['/attachments', '/attachment/'], minPermissions: { A: 1 }, count: counts.A },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="location"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    location: {
      type: Object,
      default () { return {} }
    },
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
