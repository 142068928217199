const routes = [
  {
    path: '/me/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/user/Me.vue')
  },
  {
    path: '/user/:userId?/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/user/User.vue')
  },
  {
    path: '/users/:page?',
    props: true,
    component: () => import('@/views/base/user/Users.vue')
  }
]

export default routes
