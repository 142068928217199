<template>
    
  <b-row class="mx-0">

        <!-- NO PERMISSIONS -->
        <GenericAlert :show="!permissions" :text="$tc('m.noData', 2)"/>

        <b-col v-for="k in foundPermissions" :key="k" :class="`col px-0 ${colClasses}`">

            <div class="d-flex mb-2">
              <i :class="`material-icons text-white px-2 mr-2 d-flex border rounded align-items-center ${getColor(k)}`" v-b-popover.hover.right="$tc(`m.permission_${getPermission(k)}_desc`, 1, { entity: $tc('m.' + k, 2) })">{{getIcon(k)}}</i>
              <Marquee>{{$tc('m.' + k , 2)}}</Marquee>
            </div>

        </b-col>

  </b-row>

</template>

<script>

import UserController from '@/controllers/base/user.controller.js'

import GenericAlert from '@/views/components/_alert/GenericAlert.vue'
import Marquee from '@/views/components/_utils/Marquee.vue'

export default {
  props: {
    permissions: {
      type: Object
    },
    colClasses: {
      type: String,
      default: 'col-12 col-md-6 col-lg-4 col-xl-3'
    }
  },
  data () {
    return {
      showPermissions: [
      'Organization',
      'Location',
      'GlobalAlert',
      'Notification',
      'Product',
      'ProductBatching',
      'Program',
      'Menu',
      'User',
      'Attachment',
      'AuthLicense',
      'StorePurchase',
      'Container',
      'ContainerShape',
      'Label',
      'Event',
      'Stream',
      'Stock',
      'Report',
      'GlobalAlert',
      'HaccpPoint',
      'Equipment',
      'Action',
      'License',
      'Sensor',
      'Software',
      'Task',
      'Pattern',
      'Forecaster',
      'ThirdPartyConnector',
      'Webhook',
      'UserToken',
      ]
    }
  },
  computed: {
    foundPermissions () {
      return this.showPermissions.filter(k => this.permissions[this.getPermissionKey(k)] )
    }
  },
  methods: {
    getPermissionKey (modelName) {
      return UserController.getPermissionJwtKey(modelName)
    },
    getPermission (modelName) {
      const k = this.getPermissionKey(modelName)
      return this.permissions[k]
    },
    getIcon (modelName) {
      const v = this.getPermission(modelName)
      switch (v) {
        case 0: return 'lock'
        case 1: return 'visibility'
        case 2: return 'edit'
        case 3: 
        case 4: return 'add'
      }
    },
    getColor (modelName) {
      const v = this.getPermission(modelName)
      switch (v) {
        case 0: return 'bg-secondary'
        case 1: return 'bg-secondary'
        case 2: return 'bg-dark'
        case 3: 
        case 4: return 'bg-dark'
      }
    }
  },
  components: {
    GenericAlert,
    Marquee,
  }
}

</script>
