const routes = [
  {
    path: '/notifications/:page?',
    props: true,
    component: () => import('@/views/notification/notification/Notifications.vue')
  },
  {
    path: '/notification/notifications/:page?',
    props: true,
    component: () => import('@/views/notification/notification/Notifications.vue')
  },
  {
    path: '/notification/notification/:notificationId/:nav?/:page?',
    props: true,
    component: () => import('@/views/notification/notification/Notification.vue')
  }
]

export default routes
