import { createRouter, createWebHashHistory } from 'vue-router'

import HomeRoutes from './home.routes'
import LoginRoutes from './login.routes'

import OrganizationRoutes from './base/organization.routes'
import UserRoutes from './base/user.routes'
import LocationRoutes from './base/location.routes'
import DeviceRoutes from './base/device.routes'
import MenuRoutes from './base/menu.routes'
import ModifierRoutes from './base/modifier.routes'
import ProductRoutes from './base/product.routes'
import ProgramRoutes from './base/program.routes'
import AttachmentRoutes from './base/attachment.routes'
import ActionRoutes from './base/action.routes'
import GlobalAlertRoutes from './base/global.alert.routes'

import ElaborationStateRoutes from './elaboration/elaboration.state.routes'

import NotificationRoutes from './notification/notification.routes'
import NotificationForwarderRoutes from './notification/forwarder.routes'

import EventRoutes from './monitor/event.routes'
import EventLiveRoutes from './monitor/live.routes'
import EventLabelRoutes from './monitor/label.routes'
import EventIncidenceRoutes from './monitor/incidence.routes'

import LabelRoutes from './labeling/label.routes'

import StockRoutes from './stock/stock.routes'

import UserControlRoutes from './usercontrol/user.control.routes'

import TaskRoutes from './planning/task.routes'
import TriggerRoutes from './planning/trigger.routes'
import PatternRoutes from './planning/pattern.routes'
import ForecasterRoutes from './planning/forecaster.routes'

import ContainerRoutes from './container/container.routes'
import ContainerShapeRoutes from './container/container.shape.routes'

import HaccpIncidenceRoutes from './haccp/incidence.routes'
import HaccpPointRoutes from './haccp/point.routes'

import ReportingRoutes from './reporting/report.routes'
import ScreenshotRoutes from './screenshot/screenshot.routes'

import OrderRoutes from './ordering/order.routes'
import OrderForwarderRoutes from './ordering/forwarder.routes'

import QrRoutes from './qr/qr.routes'

import IntegrationRoutes from './integration/integration.routes'
import WebhookRoutes from './integration/webhook.routes'

import AffiliateRoutes from './affiliate/affiliate.routes'

import EquipmentRoutes from './provider/equipment.routes'
import EquipmentActionRoutes from './provider/action.routes'
import EquipmentLicenseRoutes from './provider/license.routes'
import EquipmentSoftwareRoutes from './provider/software.routes'
import EquipmentSensorRoutes from './provider/sensor.routes'

import ExperimentRoutes from './innovation/experiment.routes'
import ExperimentQualityParameterRoutes from './innovation/quality.parameter.routes'

import CrmRoutes from './crm/crm.routes'

/*import AchievementRoutes from './gamification/achievement.routes'

import MultiSiteRoutes from './multisite/multisite.routes'

import AdminRoutes from './admin/admin.routes'

*/

let routes = [
  {
    path: '/',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/soon',
    component: () => import('@/views/ComingSoon.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/util/NotFound.vue')
  },
  {
    path: '/internet',
    component: () => import('@/views/util/InternetConnectionCheck.vue')
  },
  {
    path: '/qr',
    component: () => import('@/views/qr/QrBase.vue')
  }
]

routes = routes.concat(
  HomeRoutes, LoginRoutes,
  
  // Workspace
  OrganizationRoutes,
  UserRoutes,
  LocationRoutes,
  DeviceRoutes,
  MenuRoutes,
  ModifierRoutes,
  ProductRoutes,
  ProgramRoutes,
  AttachmentRoutes,
  ActionRoutes,
  GlobalAlertRoutes,

  // Elaboration
  ElaborationStateRoutes,

  // Notification
  NotificationRoutes, NotificationForwarderRoutes,

  // Monitor
  EventRoutes, EventLiveRoutes, EventLabelRoutes, EventIncidenceRoutes,
  // StreamRoutes,

  // Labeling
  LabelRoutes,

  // Stock
  StockRoutes,

  // User Control
  UserControlRoutes,

  // Planning
  TaskRoutes, TriggerRoutes, ForecasterRoutes, PatternRoutes,

  // Container
  ContainerRoutes, ContainerShapeRoutes,

  // Haccp
  HaccpIncidenceRoutes, HaccpPointRoutes,

  // Reporting
  ReportingRoutes,
  
  // Screenshot
  ScreenshotRoutes,

  // QR Product
  QrRoutes,
  
  // Integration
  IntegrationRoutes, WebhookRoutes,

  // Affiliate-Partner
  AffiliateRoutes,

  // Order connect
  OrderRoutes, OrderForwarderRoutes,

  // Equipment provider
  EquipmentRoutes, EquipmentActionRoutes, EquipmentLicenseRoutes, EquipmentSensorRoutes, EquipmentSoftwareRoutes,

  // Innovation
  ExperimentRoutes, ExperimentQualityParameterRoutes,

  // CRM
  CrmRoutes,

  // Gamification
  /*AchievementRoutes,

  // Multi site
  MultiSiteRoutes,

  // Admin
  AdminRoutes,

  */

  // Fallback
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/util/NotFound.vue')
  })

// Set app routes
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.compatConfig = { MODE: 3 }

// Set router guard
router.onError(error => {
  console.log(error)
  if (error.message && /loading chunk \d* failed./i.test(error.message)) {
    window.location.reload(true)
  }
})

export default router
