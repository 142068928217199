/* eslint-disable no-console */

import { register } from 'register-service-worker'

const reload = () => {
  const noRedirect = window.location.href.indexOf('uiredirect=0') > 0
  if (noRedirect) { return }

  // Get when was the latest reload
  const lastReload = localStorage.getItem('app-updated') || ((new Date(0)).toISOString())

  // If last reload was less than one hour ago, skip
  if (new Date() - new Date(lastReload) < 3600000) { return }

  localStorage.setItem('app-updated', (new Date()).toISOString())

  // Reload avoiding cache
  // window.location.reload(true)

  // Show button to Force a POST here to request refreshing content
  let elm = document.getElementById("reload-app-button")
  elm.classList.remove("d-none")
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content found.')
    },
    updated () {
      console.log('New content downloaded and ready please refresh.')
      reload()
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
