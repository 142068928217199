const routes = [
  {
    path: '/dashboard/:dashboard?/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/home/Dashboards.vue')
  },
  {
    path: '/world',
    props: true,
    component: () => import('@/views/public/World.vue')
  },
  {
    path: '/contact',
    props: true,
    component: () => import('@/views/public/Contact.vue')
  },
  /*{
    path: '/admin/cloud/tests',
    props: true,
    component: () => import('@/views/admin/cloud/Tests.vue')
  },*/
]

export default routes
