const routes = [
  {
    path: '/planning/forecasters',
    props: true,
    component: () => import('@/views/planning/forecaster/Forecasters.vue')
  },
  {
    path: '/planning/forecaster/:forecasterId/:nav?/:page?',
    props: true,
    component: () => import('@/views/planning/forecaster/Forecaster.vue')
  }
]

export default routes
