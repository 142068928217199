<template>

      <!-- Schedules and patterns -->
      <SidenavSection v-if="permissions && permissions.RE"
        :name="$tc('m.Report', 2)"
        icon="fact_check"
        :navs="navsD"
        :permissions="permissions"
        :layout="layout"
        visibleKey="reporting"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import ActionController from '@/controllers/base/action.controller.js'

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    location: {
      type: Object
    },
    permissions: {
      type: Object
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      navsD: [],
      fixedNavsD: [],
      extraNavs: []
    }
  },
  mounted () {
    this.reloadFixedNavs()
    // this.reloadActions()
  },
  methods: {
    reloadFixedNavs () {
      this.fixedNavsD = [
        { name: this.$tc('m.Report', 2), href: `/#/reporting/reports`, active: '/reporting/report', minPermissions: { RE: 1 }, count: this.counts.RE },
        { name: this.$tc('m.ScheduledReport', 2), href: `/#/reporting/scheduled-reports`, active: '/reporting/scheduled-report', minPermissions: { RE: 1 } }
      ]
      this.navsD = this.navsD.concat(this.fixedNavsD)
    },
    async reloadActions () {
      this.extraNavsD = []
      if (!this.location || !this.permissions) { return }

      this.$emit('update', 'busy', 1)

      setTimeout(async () => {
        const data = await ActionController.getPaginatedExecutableActions({ attributes: ['path'], hasReport: true })
          
        this.extraNavsD.push({ name: this.$tc('m.thematicReport', 2), href: null, minPermissions: { RE: 1 } })
        this.extraNavsD.push({ name: this.$tc('m.janby/report/location/events'), href: `/#/reporting/location/events`, active: '/reporting/location/events', minPermissions: { EV: 1 } })
        this.extraNavsD.push({ name: this.$tc('m.janby/report/stock/variations'), href: `/#/reporting/stock/variations`, active: '/reporting/stock/variations', minPermissions: { CS: 1 } })
        
        if (data.executables && data.executables.length) {
          data.executables.map(a => {
            this.extraNavsD.push({ name: this.$tc('m.' + a.path , 2), href: `/#/reporting/action/${encodeURIComponent(a.id)}/events`, active: `/reporting/action/${encodeURIComponent(a.id)}/events`, minPermissions: { RE: 1, EV: 1 } })
          })
        }

        this.navsD = this.navsD.concat(this.extraNavsD)
      }, Math.random() * 2000)

      this.$emit('update', 'busy', -1)
    }
  },
  watch: {
    permissions () {
      this.navsD = []
      this.reloadFixedNavs()
      // this.reloadActions()
    },
    'counts.RE' () {
      this.navsD = []
      this.reloadFixedNavs()
      this.navsD = this.navsD.concat(this.extraNavsD)
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
