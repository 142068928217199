import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

// Router
import router from './routes/routes'
router.compatConfig = { MODE: 3 }
app.use(router)

// I18n
import i18n from './i18n/i18n'
i18n.compatConfig = { MODE: 3 }
app.use(i18n)

// Fallback emitter
// import emitter from './CustomEmitter.js'
// app.mixins(emitter)

// Bootstrap vue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
app.use(BootstrapVue)

import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
app.use(Vue3Tour)

// Print html parts
import print from 'vue3-print-nb'
app.use(print)

app.mount('#app')

// IndexedDB
import IDBController from './controllers/idb.controller.js'
IDBController.startIDB()

// Service Worker
import './registerServiceWorker'