const routes = [
  {
    path: '/planning/patterns',
    props: true,
    component: () => import('@/views/planning/pattern/Patterns.vue')
  },
  {
    path: '/planning/pattern/:patternId/:nav?/:page?',
    props: true,
    component: () => import('@/views/planning/pattern/Pattern.vue')
  }
]

export default routes
