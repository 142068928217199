<template>

    <div v-show="busy > 0" id="janby-spinner" class="janby-spinner" :style="`height:${height};width:${width}`" :title="$tc('m.loading') + ': ' + busy">
      <b-spinner class="w-100 h-100" :label="$tc('m.loading')"/>
      <Imago height="80%"/>
      <!-- <div class="position-absolute" style="top:85%;right:0%">{{busy}}</div> -->
    </div>

</template>

<script>

import Imago from '@/views/components/_janby/Imago.vue'

export default {
  props: {
    busy: {
      type: Number
    },
    width: {
      type: String,
      default () { return '100%' }
    },
    height: {
      type: String,
      default () { return '100%' }
    }
  },
  components: {
    Imago,
  }
}

</script>

<style scoped>

#janby-spinner {
  position: relative;
}

#janby-spinner ::v-deep(svg) {
  position: absolute;
  left: 10%;
  top: 10%;
}

#janby-spinner ::v-deep(path#imago2) {
  animation: janby-spinner 1.5s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes janby-spinner {
    0% , 20% { transform: rotate(0deg) }
    80% , 100% { transform: rotate(360deg) }
}

</style>
