<template>

        <!-- Info sections -->
        <SidenavSection
        name="Explore"
        icon="tips_and_updates"
        :navs="[
          { name: 'JANBY Blog', href: `https://eu.janby.kitchen/en/blog`, target: 'blank', active: '/blog' },
          { name: $tc('m.findOurProducts'), href: `/#/world`, active: '/world' },
          { name: 'Test', href: `/#/admin/cloud/tests`, active: '/admin/cloud/tests', active: '/admin/cloud/tests', minPermissions: { RO: 99 } },
          { name: 'Healthcheck', href: 'https://stats.uptimerobot.com/DZ5xgsoKAm', target: 'blank', active: '/healthcheck', minPermissions: { RO: 99 } },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="explore"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    },
    locale: {
      type: String,
      default () { return localStorage.getItem('locale') || 'en' }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
