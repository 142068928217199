<template>

  <div id="app">

    <Background :loaded="loaded" :me="me" :location="location" :image="layout.background" class="fade-animation"/>

    <PageContent v-if="loaded" :layout="layout" @update="onUpdate">

      <router-view :layout="layout" :organization="organization" :me="me" :location="location" :permissions="permissions" :jwt="jwt" :counts="counts" :filters="filters" :selection="selection" @update="onUpdate"/>

      <StatusMessage :me="me" :layout="layout"/>

    </PageContent>

    <Navbar v-if="loaded" :layout="layout" :organization="organization" :me="me" :location="location" :permissions="permissions" :jwt="jwt" :counts="counts" @update="onUpdate"/>
    
    <HomeTour :me="me" :organization="organization" :location="location"/>

    <div class="main-spinner user-events-none">
      <Spinner :busy="layout.busy"/>
    </div>

    <form id="reload-app-button" class="reload-app-button stamp-animation m-0 d-none" action="/" method="POST">
        <button type="submit" class="popover b-popover bs-popover-top position-absolute" role="tooltip" tabindex="-1" style="left:-8em;top:-6em">
          <div class="popover-body">New App version found. Reload App</div>
        </button>
        <button type="submit" class="btn w-100 h-100 janby-color-background text-white pulse-animation"><i class="material-icons text-center w-100">refresh</i></button>
    </form>

    <WidthMonitor :layout="layout" @update="onUpdate"/>

  </div>

</template>

<script>

import { isProxy, toRaw } from 'vue'

import LoginController from '@/controllers/base/login.controller.js'
import FilterController from '@/controllers/filter.controller.js'
import MultiSelectController from '@/controllers/multiselect.controller.js'

import OrganizationController from '@/controllers/base/organization.controller.js'
import UserController from '@/controllers/base/user.controller.js'
import LocationController from '@/controllers/base/location.controller.js'

import Navbar from '@/views/components/_menu/Navbar.vue'
import WidthMonitor from '@/views/components/_menu/WidthMonitor.vue'
import StatusMessage from '@/views/components/_menu/StatusMessage.vue'
import PageContent from '@/views/components/PageContent.vue'
import HomeTour from '@/views/components/tour/HomeTour.vue'
import Spinner from '@/views/components/_janby/Spinner.vue'
import Background from '@/views/components/_janby/Background.vue'

export default {
  name: 'JanbyCloud',
  data () {
    return {
      layout: { sidenav: 0, navbar: 1, mobile: false, busy: 0, view: 'list' },
      me: null,
      organization: null,
      location: null, // Viewing/Logged in location
      permissions: null, // Location permissions
      jwt: null,
      loaded: false,
      filters: [],
      selection: null,
      counts: {} // Entity counts (Users, Devices, Products...) for the current location
    }
  },
  methods: {
    async onUpdate (type, data) {
      // console.log('App.vue onUpdate', type, data)
      if (!type || !this) { return }
      switch (type) {
        case 'busy':
          if (isProxy(this.layout)) { this.layout = toRaw(this.layout) }
          this.layout.busy = Math.max(0, data === 0 ? 0 : this.layout.busy + (parseInt(data) || 0))
          break

        // Me
        case 'me': {
          this.me = data

          // Store in localStorage for units, locale, listSizes, etc
          const toLocalStorage = ['locale', 'timeZone', 'listSizes', 'temperatureUnit', 'weightUnit', 'lengthUnit']
          for (const k in toLocalStorage) { if (this.me && this.me[toLocalStorage[k]]) { localStorage.setItem(toLocalStorage[k], this.me[toLocalStorage[k]]) } }

          if (this.me) {
            this.layout.navbar = 1
            const d = await OrganizationController.getOrganization()
            this.organization = d.organization
          } else {
            this.organization = null
            this.layout.sidenav = 0
          }
          break
        }

        // Organization
        case 'organization':
          this.organization = data
          break

        // Location and permissions
        case 'location': {
          this.location = data
          if (this.location) {
            this.counts = this.location.counts || this.counts || {}
            this.layout.navbar = 1
            this.layout.sidenav = this.layout.mobile ? 0 : 2
          } else {
            this.permissions = null
            this.counts = {}
            this.layout.sidenav = 0
          }
          break
        }

        case 'permissions':
          this.permissions = data
          break

        case 'jwt':
          this.jwt = data
          break

        // Layout and UI elements
        case 'layout':
          for(const k in data) {
            if (Object.prototype.hasOwnProperty.call(data, k)){
              this.layout[k] = data[k]
            }
          }
          localStorage.setItem('layout', JSON.stringify(this.layout))
          break

        case 'counts':
          for(const k in data) {
            if (Object.prototype.hasOwnProperty.call(data, k)){
              this.counts[k] = data[k]
            }
          }
          break

        // Display Message toast
        case 'message':
          if (this.layout.toasts === 0 || this.layout.toasts === '0') { return }
          this.$bvToast.toast(data.body || ' ', { title: data.title || 'An error ocurred', variant: data.variant, to: data.to, solid: true, toaster: 'b-toaster-bottom-right' })
          break

        default:
          // Filters
          this.filters = FilterController.onUpdate(type, data, this.filters)
          // Multiselect
          this.selection = MultiSelectController.onUpdate(type, data, this.selection)
      }
    },
    async chekUser () {
      if (!this.me) {
        this.onUpdate('busy', 1)
        try {
          const data = await UserController.getMe({ counts: true }, { uiredirect: 0 })
          if (!data) { throw new Error('Login') }

          this.onUpdate('me', data.me)
          this.onUpdate('permissions', data.permissions)
          this.onUpdate('busy', -1)

          return this.checkLocation()
        } catch (err) {
          this.loaded = true
          this.onUpdate('busy', -1)
        }
      }
    },
    async checkLocation () {
      if (!this.location) {
        this.onUpdate('busy', 1)
        try {
          const data = await LocationController.getLocation({ counts: true }, { uiredirect: 0 })

          this.onUpdate('location', data.location)
          this.onUpdate('permissions', data.permissions)
        } catch (err) {
          setTimeout(() => { if (!this.location) this.onUpdate('message', { body: 'Please select a location from your organization', to: '/', title: `Welcome ${this.me.email}` }) }, 5000)
        }

        this.loaded = true
        this.onUpdate('busy', -1)
      }
    }
  },
  async mounted () {
    setTimeout(async () => {

      // Load jwt
      try {
        this.jwt = JSON.parse(localStorage.getItem('jwt-session') || 'null') || null
      } catch (err) { localStorage.removeItem('jwt-session') }

      // Check if jwt comes in query string
      if(this.$route.query.jwt){
        if (this.jwt) {
          const r = await LoginController.mergetToCurrentJWT(this.$route.query.jwt)
          if(r && r.jwt) { this.jwt = r.jwt }
        } else {
          this.jwt = this.$route.query.jwt
        }
      }
      if (this.jwt) { localStorage.setItem('jwt-session', JSON.stringify(this.jwt)) }

      // Load saved layout
      let layout = null
      try {
        const l = (JSON.parse(localStorage.getItem('layout') || '{}') || {})
        if (l) { layout = l }
      } catch (err) { console.error(err) }
      
      // Load query strings to layout
      for(const k in this.$route.query){
        if (!layout) { layout = {} }
        layout[k] = this.$route.query[k]
      }
      if (layout) { this.onUpdate('layout', layout) }

      // Check user session
      this.chekUser()
    }, 500)
  },
  watch: {
    '$route'() {
      this.layout.busy = 0
    }
  },
  components: {
    Navbar,
    WidthMonitor,
    StatusMessage,
    PageContent,
    HomeTour,
    Spinner,
    Background
  }
}

</script>


