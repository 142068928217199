<template>

    <!-- Elaboration resources -->
    <SidenavSection v-if="location && permissions && permissions.RO >= 99"
      :name="$tc('m.Innovation')"
      icon="science"
      :navs="[
        { name: $tc('m.Experiment', 2), href: `/#/innovation/experiments`, active: ['/innovation/experiments', '/innovation/experiment/'], minPermissions: { RO: 99 } },
        { name: $tc('m.ExperimentQualityParameter', 2), href: `/#/innovation/parameters`, active: ['/innovation/parameters', '/innovation/parameter/'], minPermissions: { RO: 99 } },
      ]"
      :permissions="permissions"
      :layout="layout"
      visibleKey="elaboration"
      :visibles="visibles"
      :toggleVisible="toggleVisible"
      :isActive="isActive"
      />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
props: {
location: {
  type: Object,
  default () { return {} }
},
permissions: {
  type: Object,
  default () { return {} }
},
layout: {
  type: Object,
  default () { return { sidenav: 0 } }
},
counts: {
  type: Object,
  default () { return {} }
},
isActive: {
  type: Function
},
toggleVisible: {
  type: Function
},
visibles: {
  type: Array,
  default () { return [] }
}
},
components: {
    SidenavSection,
}
}
</script>
