const routes = [
  {
    path: '/affiliate/dashboard/:dashboard?/:date?',
    props: true,
    component: () => import('@/views/affiliate/dashboard/Dashboard.vue')
  },
  {
    path: '/affiliate/organizations/:page?',
    props: true,
    component: () => import('@/views/affiliate/organization/Organizations.vue')
  },
  {
    path: '/affiliate/purchases/:page?',
    props: true,
    component: () => import('@/views/affiliate/purchase/Purchases.vue')
  },
  {
    path: '/affiliate/licenses/:page?',
    props: true,
    component: () => import('@/views/affiliate/license/Licenses.vue')
  },
  {
    path: '/affiliate/users/:page?',
    props: true,
    component: () => import('@/views/affiliate/user/Users.vue')
  },
  {
    path: '/affiliate/as/user/:userId/:nav?/:page?',
    props: true,
    component: () => import('@/views/affiliate/user/AsUser.vue')
  },
  {
    path: '/affiliate/devices/:page?',
    props: true,
    component: () => import('@/views/affiliate/device/Devices.vue')
  },
  {
    path: '/affiliate/reports/:page?',
    props: true,
    component: () => import('@/views/affiliate/report/Reports.vue')
  }
]

export default routes
