<template>

      <!-- Schedules and patterns -->
      <SidenavSection v-if="permissions && permissions.CRM"
        name="CRM"
        icon="perm_contact_calendar"
        :navs="[
          // { name: $tc('m.Calendar'), href: `/#/crm/calendar`, active: ['/crm/calendar', '/crm/event'] },
          { name: $tc('m.CrmCompany', 2), href: `/#/crm/companies`, active: ['/crm/company', '/crm/companies'] },
          { name: $tc('m.CrmContact', 2), href: `/#/crm/contacts`, active: ['/crm/contact', '/crm/contacts'] },
          { name: $tc('m.CrmTicket', 2), href: `/#/crm/tickets`, active: ['/crm/ticket', '/crm/tickets'] },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="crm"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
