const routes = [
  {
    path: '/login',
    component: () => import('@/views/home/Login.vue')
  },
  {
    path: '/logout',
    component: () => import('@/views/home/Logout.vue')
  },
  {
    path: '/password/recover',
    component: () => import('@/views/home/LoginForgotPassword.vue')
  },
  {
    path: '/request/:requestType/:requestId',
    component: () => import('@/views/home/Request.vue'),
    props: true
  }
]

export default routes
