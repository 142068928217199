const routes = [
    {
      path: '/innovation/experiments/:page?',
      props: true,
      component: () => import('@/views/innovation/experiment/Experiments.vue')
    },
    {
      path: '/innovation/experiment/:experimentId/:nav?/:page?',
      props: true,
      component: () => import('@/views/innovation/experiment/Experiment.vue')
    },
  ]
  
  export default routes
  