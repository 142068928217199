const routes = [
  {
    path: '/programs/:page?',
    props: true,
    component: () => import('@/views/base/program/Programs.vue')
  },
  {
    path: '/action/:actionId/programs',
    props: true,
    component: () => import('@/views/base/program/Programs.vue')
  },
  {
    path: '/program/:programId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/program/Program.vue')
  },
]

export default routes
