const routes = [
  {
    path: '/menu/:menuId?/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/menu/Menu.vue')
  },
  {
    path: '/menus/:page?',
    props: true,
    component: () => import('@/views/base/menu/Menus.vue')
  }
]

export default routes
