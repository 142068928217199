const routes = [
  {
    path: '/devices/:page?',
    props: true,
    component: () => import('@/views/base/device/Devices.vue')
  },
  {
    path: '/provider/:providerId/device/activationKey/:activationKey',
    props: true,
    component: () => import('@/views/base/device/Anonymous.vue')
  },
  {
    path: '/device/:deviceId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/device/Device.vue')
  },
  {
    path: '/equipments/:page?',
    props: true,
    component: () => import('@/views/base/device/DevicesEquipments.vue')
  },
  {
    path: '/equipments/providers/:page?',
    props: true,
    component: () => import('@/views/base/device/DevicesEquipmentProviders.vue')
  },
  {
    path: '/devices/positions/:page?',
    props: true,
    component: () => import('@/views/base/device/DevicesPositions.vue')
  }
]

export default routes
