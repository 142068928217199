const routes = [
    {
      path: '/states/:page?',
      props: true,
      component: () => import('@/views/elaboration/state/States.vue')
    },
    {
      path: '/state/:stateId/:nav?/:page?',
      props: true,
      component: () => import('@/views/elaboration/state/State.vue')
    },
    {
      path: '/elaboration/states/:page?',
      props: true,
      component: () => import('@/views/elaboration/state/States.vue')
    },
    {
      path: '/elaboration/state/:stateId/:nav?/:page?',
      props: true,
      component: () => import('@/views/elaboration/state/State.vue')
    },
    {
      path: '/elaborations/:page?',
      props: true,
      component: () => import('@/views/elaboration/elaboration/Elaborations.vue')
    },
    {
      path: '/elaboration/elaborations/:page?',
      props: true,
      component: () => import('@/views/elaboration/elaboration/Elaborations.vue')
    },
    {
      path: '/elaboration/elaboration/:elaborationId/:nav?/:page?',
      props: true,
      component: () => import('@/views/elaboration/elaboration/Elaboration.vue')
    },
    {
      path: '/elaboration/routes/:page?',
      props: true,
      component: () => import('@/views/elaboration/route/Routes.vue')
    },
  ]
  
  export default routes
  