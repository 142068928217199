<template>

    <div class="data-square" :style="{ width : width, 'border-width' : '1px 1px 1px 8px' , 'border-style' : 'solid' , 'border-color' : (color || 'transparent') }">

        <!-- MAIN IMAGE -->
        <div v-if="!loading" class="border data-square-content w-100 h-100" :style="{ 'min-height' : '16px', 'background-position': '50% 50%', 'background-size' : fit, 'background-image' : `url(${(src || 'https://janby.blob.core.windows.net/logos/janby_imago_1024.png') + suffix})` }">
            <slot name="InsideImage"></slot>
        </div>

        <!-- SECONDARY IMAGE -->
        <div v-if="!loading && src2" class="data-square-content position-absolute w-50 h-50" :style="{ bottom: 0, right: 0, 'background-size' : 'contain', 'background-image' : `url(${src2 + suffix})` }"/>

        <!-- LOADING SKELETON -->
        <div v-if="loading" class="position-absolute w-100 h-100">
          <b-skeleton class="h-100 w-100"/>
        </div>
    </div>

</template>

<script>

export default {
  props: {
    src: {
      type: String
    },
    src2: {
      type: String
    },
    fit: {
      type: String,
      default () { return 'cover' }
    },
    width: {
      type: String,
      default () { return '100%' }
    },
    color: {
      type: String
    },
    loading: {
      type: Boolean,
      default () { return false }
    },
    suffix: {
      type: String,
      default () { return `?t=${Math.floor( new Date().getTime() / 10000 )}` }
    }
  }
}
</script>

<style>

.data-square {
  max-width: 150px;
  margin: 0 auto;
  position: relative;
}

.data-square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.data-square-content {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
}

</style>
