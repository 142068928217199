const routes = [
  {
    path: '/container/containers/:page?',
    props: true,
    component: () => import('@/views/container/container/Containers.vue')
  },
  {
    path: '/container/container/:containerId/:nav?/:page?',
    props: true,
    component: () => import('@/views/container/container/Container.vue')
  },
  {
    path: '/container/container/:containerId/:nav?/:from/:to',
    props: true,
    component: () => import('@/views/container/container/Container.vue')
  }
]

export default routes
