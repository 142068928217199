<template>

  <Tour v-if="organization && location" name="home" :steps="steps" :onStepFunction="onStep"/>

</template>

<script>

import Tour from './Tour'

export default {
props: {
  me: {
    type: Object
  },
  organization: {
    type: Object
  },
  location: {
    type: Object
  },
  permissions: {
    type: Object
  },
  layout: {
    type: Object,
    default () { return {} }
  }
},
data () {
  return {
    steps: [
      { target: '#app-brand', content: this.$tc('m.home_tour_0') },
      { target: '#app-location-picker .btn-link', content: this.$tc('m.home_tour_1') },
      { target: '#app-location-picker .dropdown-toggle-split', content: this.$tc('m.home_tour_2') },
      { target: '#app-user-button', content: this.$tc('m.home_tour_3') }
    ]
  }
},
methods: {
  onStep (s) {
    switch (s) {
      case 1: this.$router.push({ path: '/location' }); break
      case 2: this.$router.push({ path: '/locations' }); break
      case 3: this.$router.push({ path: '/me' }); break
    }
  }
},
components: {
  Tour
}
}
</script>
