const routes = [
  {
    path: '/location/:locationId?/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/location/Location.vue')
  },
  {
    path: '/locations/:page?',
    props: true,
    component: () => import('@/views/base/location/Locations.vue')
  }
]

export default routes
