const routes = [
  {
    path: '/provider/actions/:page?',
    props: true,
    component: () => import('@/views/provider/action/Actions.vue')
  },
  {
    path: '/provider/action/:actionId/:nav?/:page?',
    props: true,
    component: () => import('@/views/provider/action/Action.vue')
  }
]

export default routes
