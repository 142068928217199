<template>

        <!-- Location history -->
        <SidenavSection v-if="permissions && permissions.EV"
        :name="$tc('m.History', 2)"
        icon="history"
        :navs="[
          // { name: 'Live', href: `/#/monitor/live`, active: '/monitor/live' },
          { name: $tc('m.Event', 2), href: `/#/monitor/events`, active: '/monitor/event' },
          { name: $tc('m.Incidence', 2), href: `/#/monitor/incidences`, active: '/monitor/incidence' },
          { name: $tc('m.scannedLabel', 2), href: `/#/monitor/labels`, active: '/monitor/label', minPermissions: { LA: 1 } },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="history"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
