const routes = [
  {
    path: '/screenshot/devices/:requestWithJwt',
    props: true,
    component: () => import('@/views/screenshot/device/Devices.vue')
  },
  {
    path: '/screenshot/products/:requestWithJwt',
    props: true,
    component: () => import('@/views/screenshot/product/Products.vue')
  },
  {
    path: '/screenshot/a/ev/:requestWithJwt',
    props: true,
    component: () => import('@/views/screenshot/action/ActionEvents.vue')
  },
  {
    path: '/screenshot/l/ev/:requestWithJwt',
    props: true,
    component: () => import('@/views/screenshot/location/LocationEvents.vue')
  },
  {
    path: '/screenshot/la/e/:requestWithJwt',
    props: true,
    component: () => import('@/views/screenshot/labeling/LabelExpirations.vue')
  },
  {
    path: '/screenshot/s/va/:requestWithJwt',
    props: true,
    component: () => import('@/views/screenshot/stock/VariationEvents.vue')
  }
]

export default routes
