import ApiController from '../api.controller'
import IDBController from '../idb.controller.js'

export default {
  async getOrganization (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/organization?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.organization){ IDBController.storeInIDB('organizations', d.organization) }
    return d
  },

  async getOrganizationMini (organizationId, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/organization/mini/get?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ organizationId }) })
    if(d && d.organization){ IDBController.storeInIDB('organizations', d.organization) }
    return d
  },

  async setViewFullOrganizationOn (filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/organization/full/on?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
    if(data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    return data
  },

  async setViewFullOrganizationOff (filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/organization/full/off?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
    if(data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    return data
  },

  async loginInPublicOrganizationById (organizationId, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/auth/user/organization/public?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ organizationId }) })
    if(d && d.organization){ IDBController.storeInIDB('organizations', d.organization) }
    return d
  },

  async loginInPublicOrganizationByBytecode (bytecode, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/auth/user/organization/public?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ bytecode }) })
    if(d && d.organization){ IDBController.storeInIDB('organizations', d.organization) }
    return d
  },

  async getPaginatedOrganizationLicenses (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/licenses?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getOrganizationLicensesLimits (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/licenses/limits?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getOrganizationLocationsStructure (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/locations/structure/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getOrganizationContextsStructure (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/contexts/structure/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getOrganizationMetas (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async updateOrganization (organization, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ organization }) })
  },

  async setOrganizationLocationsStructure (structure, organization, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/locations/structure?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ structure , organization }) })
  },

  async setOrganizationMetas (metas, organization, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ metas , organization }) })
  },

  async getPaginatedOrganizationTickets (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/organization/tickets?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  getBusinessAreas () {
    return [
      'janby/business/restaurant',
      'janby/business/bar',
      'janby/business/colective',
      'janby/business/hotel',
      'janby/business/delivery',
      'janby/business/online',
      'janby/business/retail',
      'janby/business/provider',
      'janby/business/technical',
      'janby/business/consultant',
      'janby/business'
    ]
  },
}
