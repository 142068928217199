const routes = [
  {
    path: '/ordering/batches/:page?',
    props: true,
    component: () => import('@/views/ordering/batch/Batches.vue')
  },
  {
    path: '/ordering/batch/:batchId/:nav?/:page?',
    props: true,
    component: () => import('@/views/ordering/batch/Batch.vue')
  },
  {
    path: '/ordering/orders/:page?',
    props: true,
    component: () => import('@/views/ordering/order/Orders.vue')
  },
  {
    path: '/ordering/order/:orderId/:nav?/:page?',
    props: true,
    component: () => import('@/views/ordering/order/Order.vue')
  },
]

export default routes
