<template>

<div class="navbar-and-sidebar">

    <b-sidebar
      id="sidebar"
      class="bg-janby"
      bg-variant="none"
      :visible="layout.sidenav > 0"
      :width="layout.sidenav > 1 ? width : widthCollapsed"
      :no-close-on-route-change="!layout.mobile"
      :backdrop="layout.mobile"
      @hidden="$emit('update', 'layout', { sidenav: 0 })"
      no-header
      shadow>

      <Sidenav :layout="layout" :location="location" :permissions="permissions" :counts="counts" @update="(t,d) => $emit('update',t,d)"/>

      <template v-slot:footer="{ hide }">
        <b-link @click="toggleSidenav( hide )" class="list-group-item bg-janby m-0 text-white">
            <i v-show="layout.sidenav > 1" class="material-icons">arrow_back_ios</i>
            <i v-show="layout.sidenav <= 1" class="material-icons">arrow_forward_ios</i>
        </b-link>
      </template>

    </b-sidebar>

    <b-navbar :visible="layout.navbar > 0" fixed="top" class="navbar bg-janby" :style="{ top : navbarTop, opacity: navbarOpacity }">

      <!-- Logo -->
      <b-navbar-brand id="app-brand" @click="brandClick" class="p-0">
        <!--<img src="https://www.sammic.com/images/logo-sammic-220px.png"/>-->
        <Logo height="38px" janbyColor="#ffffff" digitalColor="#ffffff" :imagoOnly="layout.mobile" :imagoColor="layout.imagoColor"/>
      </b-navbar-brand>

      <!-- Location Picker -->
      <LocationPicker v-if="me" :organization="organization" :location="location" :permissions="permissions" :layout="layout" @update="(t,d) => $emit('update',t,d)"/>

      <!-- User Button -->
      <b-navbar-nav class="ml-auto">
        <NotificationsButton :me="me" :location="location" :permissions="permissions" :counts="counts" :layout="layout" @update="(t,d) => $emit('update',t,d)"/>

        <UserButton :me="me"/>
      </b-navbar-nav>

    </b-navbar>

</div>
</template>

<script>

import Logo from '@/views/components/_janby/Logo.vue'
import LocationPicker from './LocationPicker.vue'
import UserButton from './UserButton.vue'
import NotificationsButton from './NotificationsButton.vue'
import Sidenav from './Sidenav.vue'

export default {
  props: {
    me: {
      type: Object
    },
    organization: {
      type: Object
    },
    location: {
      type: Object
    },
    permissions: {
      type: Object
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return {} }
    },
    width: {
      type: String,
      default: '300px'
    },
    widthCollapsed: {
      type: String,
      default: '80px'
    }
  },
  data () {
    return {
      mobile: false
    }
  },
  methods: {
    brandClick () {
      if (this.me && this.location && this.layout.sidenav === 0) {
        return this.$emit('update', 'layout', { sidenav: 2 })
      }

      if (this.me && this.location && this.layout.sidenav === 2 && this.layout.mobile) {
        return this.$emit('update', 'layout', { sidenav: 0 })
      }

      window.location.href = '/'
    },
    toggleSidenav (hideFunction) {
      if (this.layout.sidenav === 2 && !this.layout.mobile) {
        return this.$emit('update', 'layout', { sidenav: 1 })
      }

      if (this.layout.sidenav === 2 && this.layout.mobile) {
        hideFunction()
      }

      if (this.layout.sidenav === 1) {
        return this.$emit('update', 'layout', { sidenav: 2 })
      }

      if (this.layout.sidenav === 0) {
        return this.$emit('update', 'layout', { sidenav: 2 })
      }
    }
  },
  computed: {
    navbarTop () {
      if (this.layout && this.layout.navbar > 0) {
        return '0px'
      } else {
        return '-128px'
      }
    },
    navbarOpacity () {
      if (this.layout && this.layout.navbar > 0) {
        return 1
      } else {
        return 0
      }
    }
  },
  components: {
    Logo,
    LocationPicker,
    UserButton,
    NotificationsButton,
    Sidenav
  }
}
</script>

<style scoped>

    .navbar {
        transition: top 0.3s, opacity 0.3s;
        z-index: 1020
    }

    .navbar-brand {
      cursor: pointer;
      font-size: 1rem;
    }

    .navbar-and-sidebar ::v-deep(.b-sidebar) , .b-sidebar-outer {
      z-index: 1010;
      top: 54px
    }

    .navbar-and-sidebar ::v-deep(.b-sidebar) {
      height: calc(100% - 54px) !important;
      z-index: 10
    }

    .navbar-and-sidebar ::v-deep(.b-sidebar-footer), .navbar-and-sidebar ::v-deep(.b-sidebar-footer a) {
        height: 45px
    }

    .navbar-and-sidebar ::v-deep(.bg-janby) , .navbar-and-sidebar ::v-deep(.b-sidebar-body) {
      background-color: #1D252D;
    }

</style>
