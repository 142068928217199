import ApiController from '../api.controller'

export default {

  async loginInNotification (notificationId, filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/notification/notification?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ notificationId }) })
    if(data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    return data
  },

  async getPaginatedNotifications (filter = { offset: 0, limit: 100, by: 'updated', sort: 'desc' }, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notifications?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async deleteNotifications (notifications, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notifications?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ notifications }) })
  },

  async createNotification (notification, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notification?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ notification }) })
  },

  async updateNotification (notification, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notification?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ notification }) })
  },

  async patchNotification (notificationId, notification, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notification/${notificationId}?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PATCH', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ notification }) })
  },

  async restoreNotification (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notification/restore?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
  },

  async deleteNotification (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notification?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE' })
  },

  async deleteResolvedNotifications (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notifications/resolved?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE' })
  },

  async resolveAllNotifications (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/notification/notifications/resolve?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT' })
  },
}
