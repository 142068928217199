<template>

    <b-nav-item-dropdown id="app-user-button" right>

      <template v-slot:button-content>

          <div class="text-white d-inline-flex btn btn-link py-0 pr-0 mb-0" href="">
            <span class="d-none d-md-block mr-4">{{me ? me.fullName || me.email : $tc('m.hello')}}</span>&#x2063;
            <DataImage :src="`https://janby.blob.core.windows.net/users/${me && me.image ? me.id + '/thumb' : 'undefined-thumb'}.jpg`" width="32px"/>
          </div>

      </template>

      <div v-if="!me">
        <b-dropdown-item v-if="!me" to="/">{{ $tc('m.logIn') }}</b-dropdown-item>
        <b-dropdown-item to="/contact" target="blank">{{ $tc('m.contactUs') }}</b-dropdown-item>
        <b-dropdown-item to="/world" target="blank">{{ $tc('m.aroundTheWorld') }}</b-dropdown-item>
      </div>

      <div v-if="me">
        <b-dropdown-item to="/me">{{ $tc('m.userProfile') }}</b-dropdown-item>
        <b-dropdown-item to="/contact" target="blank">{{ $tc('m.contactUs') }}</b-dropdown-item>
        <b-dropdown-item to="/world" target="blank">{{ $tc('m.aroundTheWorld') }}</b-dropdown-item>
        <b-dropdown-item to="/logout">{{ $tc('m.logOut') }}</b-dropdown-item>
      </div>

    </b-nav-item-dropdown>

</template>

<script>

import DataImage from '@/views/components/_datacard/DataImage.vue'

export default {
  props: {
    me: {
      type: Object
    },
    layout: {
      type: Object
    }
  },
  methods: {
    deviceRegistered (device) {
      this.$bvModal.hide('device-register-modal')
      this.$router.push({ path: `/device/${encodeURIComponent(device.id)}` })
    }
  },
  components: {
    DataImage
  }
}
</script>

<style scoped>
  .dropdown.nav-item {
    margin-bottom: 0;
  }

  .dropdown ::v-deep(.btn) {
    padding-bottom: 0;
    padding-top: 0;
    line-height: 36px;
  }

  .dropdown ::v-deep(.nav-link) {
    color: #fff !important;
    padding-bottom: 0;
    padding-top: 0;
  }

  .dropdown ::v-deep(.nav-link:focus), .dropdown ::v-deep(.nav-link:hover) {
    text-decoration: underline;
    color: #fff;
  }

  .dropdown ::v-deep(.dropdown-toggle::after) {
    display: none
  }
</style>
