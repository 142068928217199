const routes = [
  {
    path: '/planning/tasks/calendar/:date?',
    props: true,
    component: () => import('@/views/planning/task/Calendar.vue')
  },
  {
    path: '/planning/tasks/list/:page?',
    props: true,
    component: () => import('@/views/planning/task/Tasks.vue')
  },
  {
    path: '/planning/tasks/today',
    props: true,
    component: () => import('@/views/planning/task/Today.vue')
  },
  {
    path: '/planning/task/:taskId/:nav?/:page?',
    props: true,
    component: () => import('@/views/planning/task/Task.vue')
  }
]

export default routes
