const routes = [
  /*{
    path: '/user-control/dashboard/:dashboard?/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/stock/dashboard/Dashboards.vue')
  },*/
  {
    path: '/user-control/tokens/:page?',
    props: true,
    component: () => import('@/views/usercontrol/token/Tokens.vue')
  },
  {
    path: '/user-control/token/:tokenId/:nav?/:page?',
    props: true,
    component: () => import('@/views/usercontrol/token/Token.vue')
  }
]

export default routes
