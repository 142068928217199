const routes = [
    {
      path: '/notification/forwarders/:page?',
      props: true,
      component: () => import('@/views/notification/forwarder/Forwarders.vue')
    },
    {
      path: '/notification/forwarder/:forwarderId/:nav?/:page?',
      props: true,
      component: () => import('@/views/notification/notification/Notification.vue')
    }
  ]
  
  export default routes
  