const routes = [
  {
    path: '/alerts/:page?',
    props: true,
    component: () => import('@/views/base/alert/Alerts.vue')
  },
  {
    path: '/alert/:alertId/:nav?/:page?',
    props: true,
    component: () => import('@/views/base/alert/Alert.vue')
  }
]

export default routes