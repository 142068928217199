<template>

        <!-- Orders -->
        <SidenavSection v-if="permissions && permissions.OR >= 1"
        :name="$tc('m.OrderBatch', 2)"
        icon="room_service"
        :navs="[
          { name: $tc('m.OrderBatch', 2), href: `/#/ordering/batches`, active: `/ordering/batch` },
          { name: $tc('m.OrderBatchItem', 2), href: `/#/ordering/orders`, active: `/ordering/order` },
          { name: $tc('m.OrderForwarder', 2), href: `/#/ordering/forwarders`, active: `/ordering/forwarder`, minPermissions: { OF: 1 } },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="orderconnect"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    location: {
      type: Object,
      default () { return {} }
    },
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
