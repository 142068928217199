<template>

      <!-- Equipment provider options -->
      <SidenavSection v-if="permissions && permissions['janby/license/user/equipment']"
        name="Equipment provider"
        icon="hardware"
        :navs="[
          { name: $tc('m.Equipment', 2), href: `/#/provider/equipments`, active: '/provider/equipment' },
          { name: $tc('m.Action', 2), href: `/#/provider/actions`, active: '/provider/action' },
          { name: $tc('m.Sensor', 2), href: `/#/provider/sensors`, active: '/provider/sensor' },
          { name: $tc('m.License', 2), href: `/#/provider/licenses`, active: '/provider/license'},
          { name: $tc('m.Software', 2), href: `/#/provider/softwares`, active: ['/provider/software', '/provider/find/software']},
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="equipment-provider"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
