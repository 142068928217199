import ApiController from '../api.controller'
import IDBController from '../idb.controller.js'

export default {
  async loginInLocation (locationId, filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ locationId }) })
    if(data && data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    if(data && data.location){ IDBController.storeInIDB('locations', data.location) }
    return data
  },

  async getPaginatedLocations (filter = { offset: 0, limit: 100 }, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/locations/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...filter }) })
    if(d && d.locations){ IDBController.storeInIDB('locations', d.locations) }
    return d
  },

  async getLocationsStructure (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/locations/structure/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getLocationsGeometries (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/locations/geometries/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async deleteLocations (locations, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ locations }) })
  },

  async getLocation (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.location){ IDBController.storeInIDB('locations', d.location) }
    return d
  },

  async getLocationMetas (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationBytecode (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/bytecode?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationMini (locationId, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/location/mini/get?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ locationId }) })
    if(d && d.location){ IDBController.storeInIDB('locations', d.location) }
    return d
  },

  async getLocationLocations (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/location/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.locations){ IDBController.storeInIDB('locations', d.locations) }
    return d
  },

  async getLocationStructure (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/structure/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getPaginatedLocationGeometries (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/geometries/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getLocationPeriods (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/periods?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationActions (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/actions?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationSensors (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/sensors?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationUsers (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/users?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationDevices (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/devices?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationStatistics (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/statistics?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async createLocation (location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ location }) })
  },

  async updateLocation (location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ location }) })
  },

  async patchLocation (locationId, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/${locationId}?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ location }) })
  },

  async restoreLocation (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/restore?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
  },

  async cloneLocation (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/clone?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
  },

  async deleteLocation (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE' })
  },

  async setLocationMetas (metas, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ metas , location }) })
  },

  async setLocationUsers (users, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/users?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ users , location }) })
  },

  async setLocationDevices (devices, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/devices?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ devices , location }) })
  },

  async setLocationPeriods (periods, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/periods?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ periods , location }) })
  },

  // TYPES
  // GENERAL
  get JANBY_ALLERGEN_CELERY () { return 'janby/allergen/celery/' },
  get JANBY_ALLERGEN_CEREAL () { return 'janby/allergen/cereal/' },
  get JANBY_ALLERGEN_CRUSTACEANS () { return 'janby/allergen/crustaceans/' },
  get JANBY_ALLERGEN_EGGS () { return 'janby/allergen/eggs/' },
  get JANBY_ALLERGEN_FISH () { return 'janby/allergen/fish/' },
  get JANBY_ALLERGEN_GLUTEN () { return 'janby/allergen/gluten/' },
  get JANBY_ALLERGEN_LUPIN () { return 'janby/allergen/lupin/' },
  get JANBY_ALLERGEN_MILK () { return 'janby/allergen/milk/' },
  get JANBY_ALLERGEN_MOLLUSCS () { return 'janby/allergen/molluscs/' },
  get JANBY_ALLERGEN_MUSTARD () { return 'janby/allergen/mustard/' },
  get JANBY_ALLERGEN_NUTS () { return 'janby/allergen/nuts/' },
  get JANBY_ALLERGEN_PEANUTS () { return 'janby/allergen/peanuts/' },
  get JANBY_ALLERGEN_SESAME () { return 'janby/allergen/sesame/' },
  get JANBY_ALLERGEN_SO2 () { return 'janby/allergen/so2/' },
  get JANBY_ALLERGEN_SOYA () { return 'janby/allergen/soya/' },

  get showDefaultLocationTypes() { 
    return [
    'janby/location/brand/',
    'janby/location/division/',
    'janby/location/group/',
    'janby/location/kitchen/',
    'janby/location/production/',
    'janby/location/selling/',
    'janby/location/warehouse/',
    ]
  },
}
