const routes = [
  {
    path: '/provider/licenses/:page?',
    props: true,
    component: () => import('@/views/provider/license/Licenses.vue')
  },
  {
    path: '/provider/license/:licenseId/:nav?/:page?',
    props: true,
    component: () => import('@/views/provider/license/License.vue')
  }
]

export default routes
