
export default {
  
  onUpdate (type, data, selection) {
    if (data === undefined) { return selection }
    switch (type) {
      case 'selection': return data
      case 'addSelection': return MultiSelectController.addSelection(data, selection)
      case 'toggleSelection': return MultiSelectController.toggleSelection(data, selection)
      case 'removeSelection': return MultiSelectController.removeSelection(data, selection)
    }
    return selection
  },

  addSelection (entity, selection = []) {
    if (!entity || !entity.id) { return selection }

    selection = (selection || []).filter(s => s.id !== entity.id) // Remove the same
    selection.push(entity) // Add

    return selection
  },

  toggleSelection (entity, selection = []) {
    if (!entity || !entity.id) { return selection }

    return MultiSelectController.contains(entity, selection) ? MultiSelectController.removeSelection(entity, selection) : MultiSelectController.addSelection(entity, selection)
  },

  removeSelection (entity, selection = []) {
    if (!entity || !entity.id) { return selection }

    return (selection || []).filter(s => s.id !== entity.id)
  },

  contains (entity, selection = []) {
    if (!entity || !entity.id) { return false }

    return MultiSelectController.containsId(entity.id, selection)
  },

  containsId (id, selection = []) {
    if (!id || !selection) { return false }

    return selection.filter(f => f.id === id).length > 0
  },
  
}
