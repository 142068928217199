
<template>
    <div/>
</template>

<script>

export default {
  data () {
    return {
      lastWidth: 0,
      lastMobile: null
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },
  async mounted () {
    // Check window size
    this.handleResize()
  },
  methods: {
    handleResize () {
      const width = window.innerWidth
      if (width === this.lastWidth) {
        return
      }

      this.lastWidth = width
      let newMobile = this.lastMobile

      if (this.lastWidth <= 992) {
        newMobile = true
      }
      if (this.lastWidth > 992) {
        newMobile = false
      }

      if (newMobile !== this.lastMobile) {
        this.$emit('update', 'layout', { mobile: newMobile })
      }

      this.lastMobile = newMobile
    }
  }
}
</script>
