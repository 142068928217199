const routes = [
    {
      path: '/action/:actionId?/:nav?/:page?',
      props: true,
      component: () => import('@/views/base/action/Action.vue')
    },
    {
      path: '/actions/:page?',
      props: true,
      component: () => import('@/views/base/action/Actions.vue')
    }
  ]
  
  export default routes
  