import ApiController from '../api.controller'
import IDBController from '../idb.controller.js'

export default {
  async loginInAction (actionId, filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/action?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ actionId }) })
    if(data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    if(data && data.action){ IDBController.storeInIDB('actions', data.action) }
    return data
  },

  async getAction (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/action?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.action){ IDBController.storeInIDB('actions', d.action) }
    return d
  },

  async getActionBytecode (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/action/bytecode?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getActionMini (actionId, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/action/mini/get?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ actionId: actionId }) })
    if(d && d.action){ IDBController.storeInIDB('actions', d.action) }
    return d
  },

  async getActionMiniByPath (actionPath, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/action/mini/get?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ actionPath: actionPath }) })
    if(d && d.action){ IDBController.storeInIDB('actions', d.action) }
    return d
  },

  async getPaginatedExecutableActions (filter = { offset: 0, limit: 100, by: 'updated', sort: 'desc' }, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/actions/executables/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...filter }) })
    if(d && d.executables){ IDBController.storeInIDB('actions', d.executables) }
    return d
  },

  async getPaginatedInstallableActions (filter = { offset: 0, limit: 100, by: 'updated', sort: 'desc' }, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/actions/installables/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...filter }) })
    if(d && d.installables){ IDBController.storeInIDB('actions', d.installables) }
    return d
  },

  async getPaginatedSchedulableActions (filter = { offset: 0, limit: 100, by: 'updated', sort: 'desc' }, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/actions/schedulables/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...filter }) })
    if(d && d.schedulables){ IDBController.storeInIDB('actions', d.schedulables) }
    return d
  },

  // Action paths
  // GENERAL
  JANBY_ACTION_SERVE: 'janby/action/serve', // TO BE CHANGED FOR CONSUMED
  JANBY_ACTION_CONSUME: 'janby/action/consume',
  JANBY_ACTION_WASTE: 'janby/action/waste',
  JANBY_ACTION_REUSE: 'janby/action/reuse',
  JANBY_ACTION_CANCEL: 'janby/action/cancel',
  JANBY_ACTION_TRANSITION: 'janby/action/transition',
  JANBY_ACTION_LABEL_GENERATION: 'janby/action/label/generation',
  JANBY_ACTION_LABEL_PRINTING: 'janby/action/label/printing',
  JANBY_ACTION_LABEL_COOKING_GENERATION: 'janby/action/label/generation/cooking',
  JANBY_ACTION_LABEL_IDENTIFICATION_GENERATION: 'janby/action/label/generation/identification',
  JANBY_ACTION_LABEL_COOKING_PRINTING: 'janby/action/label/printing/cooking',
  JANBY_ACTION_LABEL_IDENTIFICATION_PRINTING: 'janby/action/label/printing/identification',
  // STOCK
  JANBY_ACTION_STOCK_RECEIVE: 'janby/action/stock/receive',
  JANBY_ACTION_STOCK_SEND: 'janby/action/stock/send',
  JANBY_ACTION_STOCK_TRANSFER: 'janby/action/stock/transfer',
  JANBY_ACTION_STOCK_VARIATION: 'janby/action/stock/variation',
  // CONTAINER
  JANBY_ACTION_CONTAINER_CONTENT_REGISTER: 'janby/action/container/content/register',
  JANBY_ACTION_CONTAINER_CONTENT_DELETE: 'janby/action/container/content/delete',
  // JANBY TRACK
  JANBY_ACTION_TRACK_TIMING: 'janby/action/track/timing',
  JANBY_ACTION_NOP: 'janby/action/nop',
  JANBY_ACTION_TRACK_PROBE_MONITORING: 'janby/action/track/probe/monitoring',
  JANBY_ACTION_TRACK_PROBE_CHILLING: 'janby/action/track/probe/chilling',
  JANBY_ACTION_VIRTUAL_TEMPERATURE_COOKING: 'janby/action/track/virtual/temperature/cooking',
  // JANBY PICK
  JANBY_ACTION_PICK_TIMING: 'janby/action/pick/timing',
  // SAMMIC SMARTVIDE
  SAMMIC_ACTION_SMARTVIDE_COOKING: 'sammic/action/smartvide/cooking',
  SAMMIC_ACTION_SMARTVIDEX_TIMING: 'sammic/action/smartvide/x/timing',
  SAMMIC_ACTION_SMARTVIDE_CALIBRATION: 'sammic/action/smartvide/calibration',
  // SAMMIC FRYER
  SAMMIC_ACTION_FRYER_COOKING: 'sammic/action/fryer/cooking',
  // SAMMIC BLAST CHILLER
  SAMMIC_ACTION_BLASTCHILLER_FREEZE_TIMING: 'sammic/action/blastchiller/freeze/timing',
  // SAMMIC VACUUM PACKER/SEALER
  SAMMIC_ACTION_VACUUM_SEALING: 'sammic/action/vacuum/sealing',
  SAMMIC_ACTION_VACUUM_SEALING_STAGED: 'sammic/action/vacuum/sealing/staged',
  SAMMIC_ACTION_VACUUM_OIL_DRYING: 'sammic/action/vacuum/oil/drying',
  SAMMIC_ACTION_VACUUM_VACNORM_SEALING: 'sammic/action/vacuum/sealing/vacnorm',
  // FREEZER/FRIDGE
  JANBY_ACTION_FREEZER_STORE_START: 'janby/action/freezer/store/start',
  JANBY_ACTION_FREEZER_STORE_END: 'janby/action/freezer/store/end',
  JANBY_ACTION_FRIDGE_STORE_START: 'janby/action/fridge/store/start',
  JANBY_ACTION_FRIDGE_STORE_END: 'janby/action/fridge/store/end',

}
