const routes = [
    {
      path: '/modifier/:modifierId?/:nav?/:page?',
      props: true,
      component: () => import('@/views/base/modifier/Modifier.vue')
    },
    {
      path: '/modifiers/:page?',
      props: true,
      component: () => import('@/views/base/modifier/Modifiers.vue')
    }
  ]
  
  export default routes
  