<template>

        <!-- Label/Batch generation -->
        <SidenavSection v-if="permissions && permissions.CS >= 1"
        :name="$tc('m.Stock')"
        icon="pallet"
        :navs="[
          { name: $tc('m.total'), href: `/#/stock/products`, active: '/stock/product' },
          { name: $tc('m.Location', 2), href: `/#/stock/locations`, active: '/stock/locations' },
          { name: $tc('m.ElaborationState', 2), href: `/#/stock/states`, active: '/stock/states' },
          { name: $tc('m.searchEntity', 2, { entity: $tc('m.expiration', 2) }), href: `/#/stock/expirations/batches`, active: '/stock/expirations' },
          { name: $tc('m.StockCheckpoint', 2), href: `/#/stock/checkpoints/batches`, active: ['/stock/checkpoints/batches','/stock/checkpoints/batch/','/stock/register/checkpoints'] },
          { name: $tc('m.StockVariation', 2), href: `/#/stock/variations`, active: '/stock/variations', minPermissions: { EV: 1 } },
          // { name: $tc('m.Waste', 2), href: `/#/stock/wastes`, active: ['/stock/wastes', '/stock/waste/'] },
          // { name: $tc('m.StockTransfer', 2), href: `/#/stock/transfers`, active: ['/stock/transfers', '/stock/transfer/', '/stock/register/transfer'] },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="stock"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
