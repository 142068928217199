const routes = [
  {
    path: '/haccp/alerts/:page?',
    props: true,
    component: () => import('@/views/haccp/alert/Alerts.vue')
  },
  {
    path: '/haccp/alert/:alertId/:nav?/:page?',
    props: true,
    component: () => import('@/views/haccp/alert/Alert.vue')
  },
  {
    path: '/haccp/points/:page?',
    props: true,
    component: () => import('@/views/haccp/point/Points.vue')
  },
  {
    path: '/haccp/point/:pointId/:nav?/:page?',
    props: true,
    component: () => import('@/views/haccp/point/Point.vue')
  }
]

export default routes
