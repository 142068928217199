<template>

<svg
   class="janby-logo"
   :style="`height:${height}`"
   :viewBox="`0 0 ${imagoOnly ? '150' : '646'} 192`"
   version="1.1"
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xml:space="preserve">

    <g v-show="!imagoOnly">

    <path
     d="m 154.1671,112.40257 c 2.81067,-1.71067 5.224,-3.504 7.24133,-5.376 2.016,-1.87467 3.676,-3.94133 4.98,-6.204 1.304,-2.26 2.26,-4.785333 2.872,-7.576 0.61067,-2.789333 0.916,-5.957333 0.916,-9.501333 V 1.0705728 H 189.5471 V 80.262571 c 0,4.237333 -0.184,8.096 -0.55067,11.578666 -0.36666,3.482667 -0.968,6.681333 -1.80266,9.593333 -0.83467,2.912 -1.95467,5.592 -3.36,8.036 -1.40534,2.444 -3.13734,4.76533 -5.19467,6.96533 -2.056,2.2 -4.48,4.31734 -7.27067,6.35467 -2.79066,2.036 -6.01866,4.09333 -9.68533,6.172 z"
     :style="`fill:${janbyColor || '#1c242d'}`"/>
     <path
     d="M 275.2979,64.558173 254.64457,21.174174 234.5419,64.558173 Z M 244.19523,1.0715079 h 20.53067 l 46.196,95.5053311 h -20.348 l -7.63733,-16.070666 h -55.78934 l -7.39333,16.070666 h -20.40933 z"
     :style="`fill:${janbyColor || '#1c242d'}`"/>
     <path
     d="m 324.31417,1.0712548 h 19.36933 l 59.516,31.1639992 V 1.0712548 h 19.36933 V 96.576586 H 403.1995 V 52.76592 l -59.516,-30.979999 v 74.790665 h -19.36933 z"
     :style="`fill:${janbyColor || '#1c242d'}`"/>
     <path
     d="m 502.31163,79.712439 c 5.21467,0 9.176,-1.12 11.88533,-3.361333 2.708,-2.238667 4.06267,-5.397333 4.06267,-9.470667 0,-4.073333 -1.35467,-7.250666 -4.06267,-9.531999 -2.70933,-2.281334 -6.67066,-3.422667 -11.88533,-3.422667 h -39.472 V 79.712439 Z M 501.9463,37.733773 c 1.66933,0 3.29866,0.06133 4.888,0.182667 2.728,-2.605333 4.09333,-5.58 4.09333,-8.921333 0,-3.461333 -1.35467,-6.141333 -4.064,-8.034667 -2.708,-1.894666 -6.67067,-2.841333 -11.884,-2.841333 h -32.14 V 37.733773 Z M 443.46763,1.0711076 h 51.02267 c 6.23333,0 11.66133,0.6626667 16.28533,1.9853333 4.62133,1.324 8.46133,3.1479999 11.51733,5.4693332 3.05467,2.3226669 5.32534,5.0813329 6.81334,8.2799999 1.48666,3.198666 2.23066,6.650666 2.23066,10.356 0,6.071999 -1.91466,11.386666 -5.744,15.949333 4.39867,2.606666 7.67867,5.987999 9.83734,10.142666 2.15866,4.154667 3.23866,8.881333 3.23866,14.177333 0,4.44 -0.744,8.452 -2.23066,12.036 -1.488,3.585333 -3.748,6.652 -6.78267,9.197333 -3.03467,2.545333 -6.864,4.501333 -11.48667,5.865333 -4.624,1.365333 -10.072,2.046667 -16.34533,2.046667 h -58.356 z"
     :style="`fill:${janbyColor || '#1c242d'}`"/>
     <path
     d="M 584.32843,72.929906 542.59376,1.0712411 h 21.936 l 29.392,54.3213319 29.024,-54.3213319 H 644.8831 L 603.69776,72.807239 v 23.769333 h -19.36933 z"
     :style="`fill:${janbyColor || '#1c242d'}`"/>

     <path
     d="m 221.52843,176.13177 c 1.82267,-1.076 3.264,-2.57467 4.32133,-4.496 1.05467,-1.92 1.584,-4.10667 1.584,-6.56667 0,-2.45866 -0.52933,-4.64666 -1.584,-6.568 -1.05733,-1.92133 -2.49866,-3.42 -4.32133,-4.496 -1.82667,-1.07466 -3.89067,-1.61333 -6.19467,-1.61333 -2.30533,0 -4.36933,0.53867 -6.19466,1.61333 -1.824,1.076 -3.26534,2.57467 -4.32,4.496 -1.05734,1.92134 -1.58667,4.10934 -1.58667,6.568 0,2.46 0.52933,4.64667 1.58667,6.56667 1.05466,1.92133 2.496,3.42 4.32,4.496 1.82533,1.076 3.88933,1.61333 6.19466,1.61333 2.304,0 4.368,-0.53733 6.19467,-1.61333 m 8.78667,-38.77733 v 42.75333 h -2.824 v -7.08667 c -1.19067,2.34267 -2.86134,4.14667 -5.012,5.416 -2.152,1.268 -4.592,1.9 -7.31867,1.9 -2.80267,0 -5.34,-0.65066 -7.604,-1.956 -2.268,-1.30666 -4.044,-3.11333 -5.332,-5.41866 -1.28667,-2.30267 -1.92933,-4.936 -1.92933,-7.89334 0,-2.95866 0.64266,-5.59866 1.92933,-7.92266 1.288,-2.32267 3.064,-4.12934 5.332,-5.41867 2.264,-1.28533 4.80133,-1.928 7.604,-1.928 2.68933,0 5.1,0.636 7.232,1.90133 2.13333,1.268 3.81067,3.03334 5.04133,5.3 v -19.64666 z"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 245.35297,150.02857 h 2.88133 v 30.08 h -2.88133 z m -0.22934,-7.97867 c -0.46133,-0.44133 -0.69333,-0.988 -0.69333,-1.644 0,-0.65066 0.232,-1.20933 0.69333,-1.66933 0.46,-0.46 1.01734,-0.692 1.67067,-0.692 0.65333,0 1.20933,0.22133 1.672,0.66133 0.46,0.44267 0.69067,0.99067 0.69067,1.644 0,0.65334 -0.23067,1.21067 -0.69067,1.672 -0.46267,0.45867 -1.01867,0.692 -1.672,0.692 -0.65333,0 -1.21067,-0.22133 -1.67067,-0.664"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 281.53617,174.89217 c 1.884,-1.01733 3.352,-2.43733 4.40933,-4.26267 1.05467,-1.82266 1.584,-3.90933 1.584,-6.252 0,-2.344 -0.52933,-4.41733 -1.584,-6.22266 -1.05733,-1.80534 -2.52533,-3.21734 -4.40933,-4.23467 -1.88267,-1.01733 -3.99467,-1.528 -6.33733,-1.528 -2.344,0 -4.44534,0.51067 -6.30934,1.528 -1.864,1.01733 -3.324,2.42933 -4.37866,4.23467 -1.05734,1.80533 -1.58534,3.87866 -1.58534,6.22266 0,2.34267 0.528,4.42934 1.58534,6.252 1.05466,1.82534 2.51466,3.24534 4.37866,4.26267 1.864,1.02133 3.96534,1.528 6.30934,1.528 2.34266,0 4.45466,-0.50667 6.33733,-1.528 m 8.816,-24.86267 v 26.852 c 0,4.99067 -1.20933,8.68134 -3.62933,11.06267 -2.41867,2.38133 -6.07067,3.572 -10.948,3.572 -2.76534,0 -5.37734,-0.42267 -7.836,-1.26667 -2.45867,-0.84533 -4.436,-2.01733 -5.936,-3.51466 l 1.55733,-2.19067 c 1.49733,1.384 3.30133,2.45733 5.416,3.22667 2.112,0.768 4.35867,1.152 6.74,1.152 3.996,0 6.95333,-0.94934 8.87467,-2.85067 1.91866,-1.90133 2.88,-4.85067 2.88,-8.84533 v -5.07067 c -1.228,2.18933 -2.93734,3.88 -5.12667,5.07067 -2.19067,1.19066 -4.648,1.78533 -7.37467,1.78533 -2.80533,0 -5.35066,-0.624 -7.63466,-1.87067 -2.288,-1.24933 -4.084,-2.988 -5.388,-5.216 -1.30667,-2.22666 -1.96,-4.74266 -1.96,-7.548 0,-2.80266 0.65333,-5.32 1.96,-7.548 1.304,-2.228 3.1,-3.956 5.388,-5.18533 2.284,-1.22933 4.82933,-1.844 7.63466,-1.844 2.764,0 5.26134,0.61467 7.48934,1.844 2.22666,1.22933 3.93733,2.95733 5.12933,5.18533 v -6.8 z"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 305.44897,150.02857 h 2.88266 v 30.08 h -2.88266 z m -0.22934,-7.97867 c -0.46266,-0.44133 -0.69333,-0.988 -0.69333,-1.644 0,-0.65066 0.23067,-1.20933 0.69333,-1.66933 0.46,-0.46 1.01734,-0.692 1.67067,-0.692 0.65333,0 1.20933,0.22133 1.672,0.66133 0.46,0.44267 0.69067,0.99067 0.69067,1.644 0,0.65334 -0.23067,1.21067 -0.69067,1.672 -0.46267,0.45867 -1.01867,0.692 -1.672,0.692 -0.65333,0 -1.21067,-0.22133 -1.67067,-0.664"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 337.37016,178.20724 c -0.732,0.692 -1.64267,1.21867 -2.73733,1.58267 -1.09467,0.36533 -2.23734,0.548 -3.428,0.548 -2.61334,0 -4.63067,-0.71867 -6.05067,-2.16 -1.42133,-1.44267 -2.13333,-3.44667 -2.13333,-6.02134 v -28.696 h 2.88133 v 6.568 h 9.564 v 2.48 h -9.564 v 19.36 c 0,1.95867 0.47067,3.44667 1.412,4.46667 0.94,1.016 2.33333,1.52667 4.17733,1.52667 1.88134,0 3.43734,-0.55867 4.66667,-1.672 z"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 365.11363,176.13177 c 1.82133,-1.076 3.26267,-2.57467 4.32,-4.496 1.05467,-1.92 1.584,-4.10667 1.584,-6.56667 0,-2.45866 -0.52933,-4.64666 -1.584,-6.568 -1.05733,-1.92133 -2.49867,-3.42 -4.32,-4.496 -1.82667,-1.07466 -3.89067,-1.61333 -6.196,-1.61333 -2.30533,0 -4.36933,0.53867 -6.19333,1.61333 -1.82534,1.076 -3.26534,2.57467 -4.32134,4.496 -1.05733,1.92134 -1.58533,4.10934 -1.58533,6.568 0,2.46 0.528,4.64667 1.58533,6.56667 1.056,1.92133 2.496,3.42 4.32134,4.496 1.824,1.076 3.888,1.61333 6.19333,1.61333 2.30533,0 4.36933,-0.53733 6.196,-1.61333 m 8.78533,-26.10267 v 30.07867 h -2.82266 v -7.08667 c -1.192,2.34267 -2.86267,4.14667 -5.012,5.416 -2.152,1.268 -4.592,1.9 -7.31867,1.9 -2.804,0 -5.34,-0.65066 -7.60533,-1.956 -2.268,-1.30666 -4.044,-3.11333 -5.332,-5.41866 -1.28534,-2.30267 -1.92934,-4.936 -1.92934,-7.89334 0,-2.95866 0.644,-5.59866 1.92934,-7.92266 1.288,-2.32267 3.064,-4.12934 5.332,-5.41867 2.26533,-1.28533 4.80133,-1.928 7.60533,-1.928 2.688,0 5.1,0.636 7.23067,1.90133 2.13333,1.268 3.812,3.03334 5.04133,5.3 v -6.972 z"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 390.95483,178.17737 c -1.34667,-1.44133 -2.01733,-3.428 -2.01733,-5.96267 v -34.86 h 2.88133 v 34.51467 c 0,1.95733 0.44133,3.44667 1.32533,4.46533 0.884,1.01734 2.208,1.528 3.976,1.528 0.99734,0 1.96,-0.19333 2.88,-0.58 l 0.23067,2.42267 c -1.07467,0.42133 -2.228,0.632 -3.456,0.632 -2.536,0 -4.47467,-0.71867 -5.82,-2.16"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 437.39363,164.0315 -7.836,7.14533 v 8.932 h -2.88133 v -42.75466 h 2.88133 v 30.25066 l 19.24533,-17.576 h 3.68667 l -12.96267,12.15734 14.23067,17.92266 h -3.572 z"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 462.91963,150.02857 h 2.88267 v 30.08 h -2.88267 z m -0.22933,-7.97867 c -0.46267,-0.44133 -0.692,-0.988 -0.692,-1.644 0,-0.65066 0.22933,-1.20933 0.692,-1.66933 0.46133,-0.46 1.01733,-0.692 1.67066,-0.692 0.65334,0 1.20934,0.22133 1.672,0.66133 0.46134,0.44267 0.692,0.99067 0.692,1.644 0,0.65334 -0.23066,1.21067 -0.692,1.672 -0.46266,0.45867 -1.01866,0.692 -1.672,0.692 -0.65333,0 -1.20933,-0.22133 -1.67066,-0.664"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 494.84283,178.20724 c -0.732,0.692 -1.64267,1.21867 -2.73733,1.58267 -1.09467,0.36533 -2.23734,0.548 -3.428,0.548 -2.612,0 -4.63067,-0.71867 -6.05067,-2.16 -1.42133,-1.44267 -2.13333,-3.44667 -2.13333,-6.02134 v -28.696 h 2.88266 v 6.568 h 9.564 v 2.48 h -9.564 v 19.36 c 0,1.95867 0.47067,3.44667 1.41067,4.46667 0.94,1.016 2.33333,1.52667 4.17867,1.52667 1.88,0 3.436,-0.55867 4.66666,-1.672 z"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 508.72829,178.38044 c -2.30533,-1.30533 -4.112,-3.12267 -5.416,-5.44667 -1.30667,-2.32266 -1.96,-4.94533 -1.96,-7.864 0,-2.95866 0.65333,-5.6 1.96,-7.924 1.304,-2.32266 3.11067,-4.128 5.416,-5.41733 2.30533,-1.28533 4.916,-1.92933 7.83467,-1.92933 2.42133,0 4.62133,0.47066 6.59733,1.41066 1.98133,0.94267 3.58267,2.31467 4.812,4.12 l -2.13067,1.556 c -1.07733,-1.49733 -2.412,-2.62133 -4.00533,-3.368 -1.59467,-0.752 -3.352,-1.12533 -5.27333,-1.12533 -2.34267,0 -4.44534,0.528 -6.30667,1.58267 -1.86667,1.05733 -3.32533,2.54533 -4.38133,4.468 -1.056,1.92 -1.584,4.13066 -1.584,6.62666 0,2.49734 0.528,4.70534 1.584,6.62667 1.056,1.92133 2.51466,3.408 4.38133,4.46533 1.86133,1.05734 3.964,1.58267 6.30667,1.58267 1.92133,0 3.67866,-0.37333 5.27333,-1.12133 1.59333,-0.75067 2.928,-1.87467 4.00533,-3.372 l 2.13067,1.556 c -1.22933,1.80533 -2.83067,3.17866 -4.812,4.12 -1.976,0.94 -4.176,1.41066 -6.59733,1.41066 -2.91867,0 -5.52934,-0.652 -7.83467,-1.95733"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 562.60189,153.08364 c 2.18933,2.19067 3.284,5.34133 3.284,9.44933 v 17.576 h -2.88133 v -17.34533 c 0,-3.37733 -0.85467,-5.95467 -2.56267,-7.72 -1.71067,-1.76667 -4.10133,-2.652 -7.17467,-2.652 -3.53466,0 -6.32,1.05733 -8.356,3.16933 -2.036,2.112 -3.05333,4.956 -3.05333,8.52667 v 16.02133 h -2.88133 v -42.75466 h 2.88133 v 18.956 c 1.03733,-2.07334 2.57333,-3.67734 4.61067,-4.80934 2.036,-1.13466 4.436,-1.70133 7.20266,-1.70133 3.764,0 6.74,1.09467 8.93067,3.284"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 585.93789,153.9195 c -1.80533,1.05733 -3.20667,2.536 -4.20533,4.43733 -0.99867,1.90134 -1.49734,4.06134 -1.49734,6.48267 0,0.42267 0.0373,1.01733 0.11467,1.784 l 22.93333,-4.49333 c -0.26933,-1.804 -0.904,-3.45734 -1.904,-4.956 -0.99866,-1.49867 -2.30266,-2.67734 -3.916,-3.54534 -1.61333,-0.86266 -3.42133,-1.292 -5.416,-1.292 -2.26666,0 -4.30266,0.52534 -6.10933,1.58267 m 12.53333,22.76 c 1.67067,-0.71067 3.06267,-1.75733 4.176,-3.14 l 1.672,1.90133 c -1.30533,1.61334 -2.94,2.83334 -4.89733,3.66 -1.95867,0.82534 -4.112,1.236 -6.45467,1.236 -2.996,0 -5.67466,-0.65066 -8.036,-1.956 -2.36266,-1.30666 -4.20666,-3.11333 -5.532,-5.41866 -1.324,-2.30267 -1.98933,-4.936 -1.98933,-7.89334 0,-2.95733 0.624,-5.58933 1.87467,-7.89466 1.24666,-2.304 2.98533,-4.10934 5.21466,-5.41467 2.22667,-1.30533 4.74267,-1.96 7.548,-1.96 2.61067,0 4.964,0.604 7.05734,1.81467 2.09466,1.212 3.764,2.88266 5.01333,5.01333 1.24667,2.13067 1.93067,4.52267 2.04533,7.17467 l -25.41066,4.95466 c 0.72933,2.76667 2.18933,4.956 4.38,6.56934 2.18933,1.612 4.82133,2.41866 7.89333,2.41866 1.95867,0 3.77333,-0.35333 5.44533,-1.06533"
     :style="`fill:${digitalColor || '#64676b'}`"/>
     <path
     d="m 641.59936,153.08364 c 2.188,2.19067 3.284,5.34133 3.284,9.44933 v 17.576 h -2.88267 v -17.34533 c 0,-3.37733 -0.85466,-5.95467 -2.56266,-7.72 -1.70934,-1.76667 -4.10134,-2.652 -7.17467,-2.652 -3.53333,0 -6.31867,1.05733 -8.356,3.16933 -2.03467,2.112 -3.052,4.956 -3.052,8.52667 v 16.02133 h -2.88267 v -30.08 h 2.76534 v 6.51067 c 1.04,-2.10933 2.58533,-3.76133 4.64133,-4.95333 2.05333,-1.192 4.48267,-1.78667 7.28933,-1.78667 3.76267,0 6.73867,1.09467 8.93067,3.284"
     :style="`fill:${digitalColor || '#64676b'}`"/>

    </g>

     <path
     d="M 76.972969,1.0712548 V 77.207253 H 0.8369709 V 96.576586 H 76.972969 96.343635 V 77.207253 1.0712548 Z"
     :style="`fill:${imagoColor || '#ec8c03'}`"/>
     <path
     d="M 76.972969,191.51524 H 96.343635 V 115.37791 H 76.972969 Z"
     :style="`fill:${imagoColor || '#ec8c03'}`"/>
</svg>

</template>

<script>

export default {
  props: {
    janbyColor: {
      type: String,
      default () { return '#1c242d' }
    },
    digitalColor: {
      type: String,
      default () { return '#64676b' }
    },
    imagoColor: {
      type: String,
      default () { return '#ec8c03' }
    },
    height: {
      type: String,
      default () { return '24px' }
    },
    imagoOnly: {
      type: Boolean,
      default () { return false }
    }
  }
}
</script>
