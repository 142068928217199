import SWorker from 'simple-web-worker'
import { isProxy, toRaw } from 'vue'

export default {

  onUpdate (type, data, filters) {
    if (isProxy(type)) { type = toRaw(type) }
    if (isProxy(data)) { data = toRaw(data) }
    if (isProxy(filters)) { filters = toRaw(filters) }
    if (!data) { return filters }
    switch (type) {
      case 'filters': return data
      case 'addFilter': return this.addFilter(data, filters)
      case 'updateFilter': return this.updateFilter(data, filters)
      case 'toggleFilter': return this.toggleFilter(data, filters)
      case 'toggleFilterByKey': return this.toggleFilterByKey(data, filters)
      case 'removeFilter': return this.removeFilter(data, filters)
      case 'removeFilterByKey': return this.removeFilterByKey(data, filters)
    }
    return filters
  },

  addFilter (filter, filters = []) {
    if (!filter || !filter.key) { return filters }
    if (filter.value === undefined) { return this.removeFilterByKey(filter.key, filters) }

    filters = filters.filter(f => f.key !== filter.key || f.value !== filter.value) // Remove the same KEY AND VALUE
    filters.push(filter) // Add

    return filters
  },

  updateFilter (filter, filters = []) {
    if (!filter || !filter.key) { return filters }
    if (filter.value === undefined) { return this.removeFilterByKey(filter.key, filters) }

    filters = filters.filter(f => f.key !== filter.key) // Remove the same KEY
    filters.push(filter) // Add

    return filters
  },

  toggleFilter (filter, filters = []) {
    if (!filter || !filter.key) { return filters }

    return this.containsExact(filter.key, filter.value, filters) ? this.removeFilter(filter, filters) : this.addFilter(filter, filters)
  },

  toggleFilterByKey (filter, filters = []) {
    if (!filter || !filter.key) { return filters }

    if (this.containsExact(filter.key, filter.value, filters)) {
      return this.removeFilter(filter, filters)
    }

    // Contains key but not same value
    if (this.containsKey(filter.key, filters)) {
      filters = this.removeFilterByKey(filter.key, filters) // Remove other key
      return this.addFilter(filter, filters) // Add same key different value
    }

    // No same key found, add new
    return this.addFilter(filter, filters)
  },

  removeFilter (filter, filters = []) {
    if (!filter || !filter.key) { return filters }

    return filters.filter(f => f.key !== filter.key || f.value !== filter.value)
  },

  removeFilterByKey (key, filters = []) {
    if (!key) { return filters }

    return filters.filter(f => f.key !== key)
  },

  containsExact (key, value, filters = []) {
    if (!key || value === undefined) { return false }

    return filters.filter(f => f.key === key && f.value === value).length > 0
  },

  containsKey (key, filters = []) {
    if (!key) { return false }

    return filters.filter(f => f.key === key).length > 0
  },

  toQueryObject (filters = [], add = {}, init = {}) {
    if (isProxy(filters)) { filters = toRaw(filters) }
    if (isProxy(add)) { add = toRaw(add) }
    if (isProxy(init)) { init = toRaw(init) }
    let obj = init
    filters.map(f => {
      obj[f.key] = f.value
      return f
    })
    obj = { ...obj, ...add }
    for (const i in obj) {
      if (obj[i] === undefined) { delete obj[i] }
    }
    return obj
  },

  queryParamsToFilters (query, restricToKeys = null, filters = []) {
    if(isProxy(query)) { query = toRaw(query) }
    if(isProxy(restricToKeys)) { restricToKeys = toRaw(restricToKeys) }
    if(isProxy(filters)) { filters = toRaw(filters) }
    if (!query) { return filters }

    const keysMap = {
      organizationId: { key: 'organizationId', name: 'organizationName', icon: 'business' },
      companyId: { key: 'companyId', name: 'companyName', icon: 'business' },
      userId: { key: 'userId', name: 'userName', icon: 'people_alt' },
      contactId: { key: 'contactId', name: 'contactName', icon: 'people_alt' },
      locationId: { key: 'locationId', name: 'locationName', icon: 'storefront' },
      locationType: { key: 'locationType', name: 'locationTypeName', icon: 'storefront' },
      parentLocationId: { key: 'parentLocationId', name: 'locationName', icon: 'storefront' },
      deviceId: { key: 'deviceId', name: 'deviceName', icon: 'router' },
      deviceType: { key: 'deviceType', name: 'deviceName', icon: 'router' },
      equipmentId: { key: 'equipmentId', name: 'equipmentName', icon: 'hardware' },
      equipmentPath: { key: 'equipmentPath', name: 'equipmentName', icon: 'hardware' },
      providerId: { key: 'providerId', name: 'providerName', icon: 'hardware' },
      productId: { key: 'productId', name: 'productName', icon: 'restaurant' },
      productType: { key: 'productType', name: 'productName', icon: 'restaurant' },
      programId: { key: 'programId', name: 'programName', icon: 'timer' },
      actionId: { key: 'actionId', name: 'actionName', icon: 'pending_actions' },
      actionPath: { key: 'actionPath', name: 'actionName', icon: 'pending_actions' },
      batchingId: { key: 'batchingId', name: 'batchingName', icon: 'receipt_long' },
      templateId: { key: 'templateId', name: 'templateName', icon: 'print' },
      labelId: { key: 'labelId', name: 'labelName', icon: 'receipt_long' },
      labelBatchId: { key: 'labelBatchId', name: 'labelBatchName', icon: 'receipt_long' },
      containerId: { key: 'containerId', name: 'containerName', icon: 'pin_drop' },
      stateId: { key: 'stateId', name: 'stateName', icon: 'linear_scale' },
      reportId: { key: 'reportId', name: 'reportName', icon: 'fact_check' },
      scheduledReportId: { key: 'scheduledReportId', name: 'scheduledReportName', icon: 'fact_check' },
      alertId: { key: 'alertId', name: 'alertName', icon: 'health_and_safety' },
      alertType: { key: 'alertType', name: 'alertName', icon: 'health_and_safety' },
      haccpPointId: { key: 'haccpPointId', name: 'haccpPointName', icon: 'health_and_safety' },
      haccpPointType: { key: 'haccpPointType', name: 'haccpPointName', icon: 'health_and_safety' },
      haccpAlertId: { key: 'haccpAlertId', name: 'haccpAlertName', icon: 'health_and_safety' },
      haccpAlertType: { key: 'haccpAlertType', name: 'haccpAlertName', icon: 'health_and_safety' },
      traceabilityId: { key: 'traceabilityId', name: 'traceabilityName', icon: 'receipt_long' },
      traceabilityPath: { key: 'traceabilityPath', name: 'traceabilityName', icon: 'receipt_long' },
      eventType: { key: 'eventType', name: 'eventName', icon: 'warning' },
      messageType: { key: 'messageType', name: 'messageName', icon: 'warning' },
      notificationId: { key: 'notificationId', name: 'notificationName', icon: 'notifications' },
      notificationType: { key: 'notificationType', name: 'notificationName', icon: 'notifications' },
      forwarderId: { key: 'forwarderId', name: 'forwarderName', icon: 'forward_to_inbox' },
      forwarderType: { key: 'forwarderType', name: 'forwarderName', icon: 'forward_to_inbox' },
      from: { key: 'from', name: 'fromName', icon: 'calendar' },
      to: { key: 'to', name: 'toName', icon: 'calendar' },
      checkpointId: { key: 'checkpointId', name: 'checkpointName', icon: 'pallet' },
      checkpointBatchId: { key: 'checkpointBatchId', name: 'checkpointBatchName', icon: 'pallet' },
      orderId: { key: 'orderId', name: 'orderName', icon: 'room_service' },
      orderBatchId: { key: 'orderBatchId', name: 'orderBatchName', icon: 'room_service' },
      licenseId: { key: 'licenseId', name: 'licenseName', icon: 'extension' },
      licenseStatus: { key: 'licenseStatus', name: 'licenseName', icon: 'extension' },
      licenseType: { key: 'licenseType', name: 'licenseName', icon: 'extension' },
      subscriptionId: { key: 'subscriptionId', name: 'subscriptionName', icon: 'extension' },
      purchaseId: { key: 'purchaseId', name: 'purchaseName', icon: 'shopping_cart' },
      purchaseStatus: { key: 'purchaseStatus', name: 'purchaseName', icon: 'shopping_cart' },
      storeProductId: { key: 'storeProductId', name: 'storeProductName', icon: 'shopping_cart' },
      hierarchy: { key: 'hierarchy', name: 'hierarchyName', icon: 'lan' },
      taskId: { key: 'taskId', name: 'taskName', icon: 'edit_calendar' },
      parentTaskId: { key: 'parentTaskId', name: 'taskName', icon: 'edit_calendar' },
      tag: { key: 'tag', name: 'tagName', icon: 'label' },
      search: { key: 'search', name: 'search', icon: 'search' },
      ticketId: { key: 'ticketId', name: 'ticketName', icon: 'ticket' },
      tokenId: { key: 'tokenId', name: 'tokenName', icon: 'watch' },
      experimentId: { key: 'experimentId', name: 'experimentName', icon: 'science' },
      minExpiration: { key: 'minExpiration', name: 'minExpiration', icon: 'screen_lock_landscape' },
      maxExpiration: { key: 'maxExpiration', name: 'maxExpiration', icon: 'screen_lock_landscape' },
      isPending: { key: 'isPending', name: 'isPending', icon: 'screen_lock_landscape' },
      isExpired: { key: 'isExpired', name: 'isExpired', icon: 'screen_lock_landscape' },
    }

    for(const k in query) {
      if (keysMap[k] !== undefined && (restricToKeys === null || restricToKeys.indexOf(k) > -1)) {
        const d = keysMap[k]
        filters.push({ key: d.key, value: query[d.key], name: query[d.name] || query.name || '', icon: d.icon })
      }
    }

    return filters.filter((v, idx, arr) => arr.findIndex(t => t.key === v.key) === idx)
  },

  async filterElements (elements = [], filters = [], fromArray = false) {
    let fs = filters
    if (fs.map) { fs = this.toQueryObject(fs) }

    return new Promise((resolve) => {
      if (!elements || !fs) { return resolve(elements) }
      if (isProxy(fs)) { fs = toRaw(fs) }
      if (isProxy(elements)) { elements = toRaw(elements) }
      elements = elements.map(e => {
        const obj = {}
        for (const i in e) {
          if (isProxy(e[i])) { obj[i] = toRaw(e[i]) } else { obj[i] = e[i] }
        }
        return obj
      })
      
        SWorker.run((elements, fs = {}, fromArray = false) => {
          
          if (fs.traceabilityId) {
            elements = elements.filter(e => e.traceabilityId ? e.traceabilityId === fs.traceabilityId : (e.traceabilityIds || []).indexOf(fs.traceabilityId) > -1)
          }
          if (fs.traceabilityPath) {
            elements = elements.filter(e => e.traceabilityPath ? e.traceabilityPath === fs.traceabilityPath : (e.traceabilityPaths || []).indexOf(fs.traceabilityPath) > -1)
          }
          if (fs.deviceId) {
            elements = elements.filter(e => e.deviceId ? e.deviceId === fs.deviceId : (e.deviceIds || []).indexOf(fs.deviceId) > -1)
          }
          if (fs.equipmentId) {
            elements = elements.filter(e => e.equipmentId ? e.equipmentId === fs.equipmentId : (e.equipmentIds || []).indexOf(fs.equipmentId) > -1)
          }
          if (fs.productId) {
            elements = elements.filter(e => e.productId ? e.productId === fs.productId : (e.productIds || []).indexOf(fs.productId) > -1)
          }
          if (fs.programId) {
            elements = elements.filter(e => e.programId ? e.programId === fs.programId : (e.programIds || []).indexOf(fs.programId) > -1)
          }
          if (fs.locationId) {
            elements = elements.filter(e => e.locationId ? e.locationId === fs.locationId : (e.locationIds || []).indexOf(fs.locationId) > -1)
          }
          if (fs.actionPath) {
            elements = elements.filter(e => e.actionPath ? e.actionPath === fs.actionPath : (e.actionPaths || []).indexOf(fs.actionPath) > -1)
          }
          if (fs.userId) {
            elements = elements.filter(e => e.userId ? e.userId === fs.userId : (e.userIds || []).indexOf(fs.userId) > -1)
          }
          if (fs.containerId) {
            elements = elements.filter(e => e.containerId ? e.containerId === fs.containerId : (e.containerIds || []).indexOf(fs.containerId) > -1)
          }
          if (fs.stateId) {
            elements = elements.filter(e => e.stateId ? e.stateId === fs.stateId : (e.stateIds || []).indexOf(fs.stateId) > -1)
          }
          if (fs.experimentId) {
            elements = elements.filter(e => e.experimentId ? e.experimentId === fs.experimentId : (e.experimentIds || []).indexOf(fs.experimentId) > -1)
          }
          if (fs.labelId) {
            elements = elements.filter(e => e.labelId ? e.labelId === fs.labelId : (e.labelIds || []).indexOf(fs.labelId) > -1)
          }
          if (fs.labelBatchId) {
            elements = elements.filter(e => e.labelBatchId ? e.labelBatchId === fs.labelBatchId : (e.labelBatchIds || []).indexOf(fs.labelBatchId) > -1)
          }
          if (fs.labelBatchSerial) {
            elements = elements.filter(e => e.labelBatchSerial ? e.labelBatchSerial === fs.labelBatchSerial : (e.labelBatchSerials || []).indexOf(fs.labelBatchSerial) > -1)
          }
          if (fs.messageType) {
            elements = elements.filter(e => e.messageType ? e.messageType === fs.messageType : (e.messageTypes || []).indexOf(fs.messageType) > -1)
          }
          if (fs.isError) {
            elements = elements.filter(e => e.errors && e.errors >= 1)
          }
          if (fs.isWarning) {
            elements = elements.filter(e => e.warnings && e.warnings >= 1)
          }
          if (fs.day >= 0) {
            elements = elements.filter(e => new Date(e.registered).getDay() === fs.day)
          }
          if (fs.hour) {
            elements = elements.filter(e => new Date(e.registered).getHours() === fs.hour)
          }
          if (fs.date) {
            elements = elements.filter(e => new Date(e.registered).toDateString() === new Date(fs.date).toDateString())
          }

          return elements
        }, [elements, fs, fromArray])
        .then(resolve)
        .catch(err => console.error('filterElements', err)) // logs any possible error
      })
  },
  
}
