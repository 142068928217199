const routes = [
  {
    path: '/labeling/dashboard/:dashboard?/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/labeling/dashboard/Dashboards.vue')
  },
  {
    path: '/labeling/register/mask',
    props: true,
    component: () => import('@/views/labeling/batch/Mask.vue')
  },
  {
    path: '/labeling/batches/:page?',
    props: true,
    component: () => import('@/views/labeling/batch/Batches.vue')
  },
  {
    path: '/labeling/batch/:batchId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/batch/Batch.vue')
  },
  {
    path: '/labeling/product/:productId/batch/:batchId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/batch/Batch.vue')
  },
  {
    path: '/labeling/product/:productId/batching/:batchingId/batch/:batchId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/batch/Batch.vue')
  },
  {
    path: '/labeling/labels/:page?',
    props: true,
    component: () => import('@/views/labeling/label/Labels.vue')
  },
  {
    path: '/labeling/label/:labelId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/label/Label.vue')
  },

  {
    path: '/labeling/batchings/:page?',
    props: true,
    component: () => import('@/views/labeling/batching/Batchings.vue')
  },
  {
    path: '/labeling/batching/:batchingId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/batching/Batching.vue')
  },

  {
    path: '/labeling/templates/:page?',
    props: true,
    component: () => import('@/views/labeling/template/Templates.vue')
  },
  {
    path: '/labeling/template/:templateId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/template/Template.vue')
  },

  {
    path: '/labeling/alerts',
    props: true,
    component: () => import('@/views/labeling/alert/Alerts.vue')
  },
  {
    path: '/labeling/alert/:alertId/:nav?/:page?',
    props: true,
    component: () => import('@/views/labeling/alert/Alert.vue')
  },

  {
    path: '/labeling/qr/generate',
    props: true,
    component: () => import('@/views/labeling/qr/Generate.vue')
  },
]

export default routes
