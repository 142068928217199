const routes = [
  {
    path: '/crm/companies/:page?',
    props: true,
    component: () => import('@/views/crm/company/Companies.vue')
  },
  {
    path: '/crm/company/:companyId/:nav?/:page?',
    props: true,
    component: () => import('@/views/crm/company/Company.vue')
  },
  /*{
    path: '/crm/contacts/:page?',
    props: true,
    component: () => import('@/views/crm/contact/Contacts.vue')
  },
  {
    path: '/crm/contact/:contactId/:nav?/:page?',
    props: true,
    component: () => import('@/views/crm/contact/Contact.vue')
  },
  {
    path: '/crm/tickets/:page?',
    props: true,
    component: () => import('@/views/crm/ticket/Tickets.vue')
  },
  {
    path: '/crm/ticket/:ticketId/:nav?/:page?',
    props: true,
    component: () => import('@/views/crm/ticket/Ticket.vue')
  },*/
]

export default routes
