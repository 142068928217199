const routes = [
  {
    path: '/provider/equipments/:page?',
    props: true,
    component: () => import('@/views/provider/equipment/Equipments.vue')
  },
  {
    path: '/provider/equipment/:equipmentId/:nav?/:page?',
    props: true,
    component: () => import('@/views/provider/equipment/Equipment.vue')
  }
]

export default routes
