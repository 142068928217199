<template>

      <!-- Affiliate options -->
      <SidenavSection v-if="permissions && permissions.AFF"
        :name="$tc('m.Affiliate', 2)"
        icon="groups"
        :navs="[
          { name: $tc('m.Summary'), href: `/#/affiliate/dashboard`, active: '/affiliate/dashboard' },
          { name: $tc('m.Organization', 2), href: `/#/affiliate/organizations`, active: '/affiliate/organization' },
          { name: $tc('m.StorePurchase', 2), href: `/#/affiliate/purchases`, active: '/affiliate/purchase' },
          { name: $tc('m.License', 2), href: `/#/affiliate/licenses`, active: '/affiliate/license' },
          { name: $tc('m.Device', 2), href: `/#/affiliate/devices`, active: '/affiliate/devices' },
          { name: $tc('m.User', 2), href: `/#/affiliate/users`, active: '/affiliate/user' },
          { name: $tc('m.Report', 2), href: `/#/affiliate/reports`, active: '/affiliate/report' },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="affiliate"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
