<template>

        <!-- GENERIC SIDENAV MENU -->
        <b-nav v-if="navs" vertical align="left" class="mb-2 p-0 list-group">

            <!-- SHOW GROUP AS DROPRIGHT -->
            <b-list-group-item v-if="layout.sidenav == 1" :id="`sidenav-section-${visibleKey}-popover`" variant="link" class="border-0 bg-janby d-flex justify-content-left align-items-center">
              <i class="material-icons text-white">{{icon}}</i>
              
              <b-popover
                boundary="window"
                :target="`sidenav-section-${visibleKey}-popover`"
                triggers="hover"
                class="sidenav-popover"
                placement="auto">
                <div v-for="(nav, idx) in navs.filter(n => visibleNavFunction(n, permissions))" :key="idx">

                  <!-- IS A LINK -->
                  <b-nav-item v-if="nav.active" class="overflow-hidden d-block" :href="nav.href" :target="nav.target" :active="isActive(nav.active || [])">
                      <Marquee v-show="layout.sidenav > 1" class="ml-2 d-block text-dark text-capitalize">
                        {{nav.name}}
                        <small class="badge badge-dark icon-pill badge-pill icon-pill" v-show="nav.count">({{nav.count}})</small>
                      </Marquee>
                  </b-nav-item>

                  <!-- JUST TEXT -->
                  <div v-if="!nav.active" class="ml-4 py-2 overflow-hidden text-muted">{{nav.name}}</div>

                </div>
            </b-popover>
            </b-list-group-item>

            <!-- SHOW GROUP AS ITEM -->
            <b-list-group-item v-if="layout.sidenav > 1" class="text-secondary border-0 bg-janby d-flex justify-content-left align-items-center" role="button" hred="#" :class="visibles.indexOf(visibleKey) >= 0 ? 'visible not-collapsed' : 'collapsed'" @click="toggleVisible(visibleKey)">
                <i class="material-icons" :class="visibles.indexOf(visibleKey) >= 0 ? 'text-white' : ''">{{icon}}</i>
                <span>{{name}}</span>
                <i class="material-icons collapse-icon position-absolute" style="right:.75rem">{{ visibles.indexOf(visibleKey) < 0 ? 'more_vert' : 'remove' }}</i>
            </b-list-group-item>

            <!-- VIEW AS LIST FOR OPEN SIDENAV -->
            <b-collapse v-if="layout.sidenav > 1" :visible="visibles.indexOf(visibleKey) >= 0" class="w-100 bg-janby">
                <div v-for="(nav, idx) in navs.filter(n => visibleNavFunction(n, permissions))" :key="idx">

                    <!-- IS A LINK -->
                    <b-nav-item v-if="nav.active" class="overflow-hidden" :href="nav.href" :target="nav.target" :active="isActive(nav.active || [])">
                        
                        <Marquee class="w-100">

                          <span v-show="layout.sidenav > 1" class="ml-2 d-block" :title="nav.name">{{nav.name}}
                            <small class="badge badge-dark icon-pill badge-pill icon-pill" v-show="nav.count">({{nav.count}})</small>
                          </span>

                        </Marquee>

                    </b-nav-item>

                    <!-- JUST TEXT -->
                    <div v-if="!nav.active" class="ml-3 pt-3 pb-2 overflow-hidden text-muted">{{nav.name}}</div>

                </div>
            </b-collapse>

        </b-nav>

</template>

<script>

import UserController from '@/controllers/base/user.controller.js'

import Marquee from '@/views/components/_utils/Marquee.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    name: {
      type: String,
      default () { return 'Section' }
    },
    icon: {
      type: String,
      default () { return 'Section' }
    },
    count: {
      type: Number
    },
    navs: {
      type: Array,
      default () { return [] } // { name: '', count: '', href: '', active: [], minPermissions: [] }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibleKey: {
      type: String
    },
    visibles: {
      type: Array,
      default () { return [] }
    },
    visibleNavFunction: {
      type: Function,
      default (nav, permissions) {
        if (!nav) { return false }
        for (let i = 0; i < (nav.canView || []).length; i++) {
          if (!permissions[ UserController.getPermissionJwtKey(nav.canView[i]) ] || permissions[ UserController.getPermissionJwtKey(nav.canView[i]) ] <= 0) {
            return false
          }
        }
        for (let i = 0; i < (nav.canEdit || []).length; i++) {
          if (!permissions[ UserController.getPermissionJwtKey(nav.canEdit[i]) ] || permissions[ UserController.getPermissionJwtKey(nav.canEdit[i]) ] <= 1) {
            return false
          }
        }
        for (let i = 0; i < (nav.canCreate || []).length; i++) {
          if (!permissions[ UserController.getPermissionJwtKey(nav.canCreate[i]) ] || permissions[ UserController.getPermissionJwtKey(nav.canCreate[i]) ] <= 2) {
            return false
          }
        }
        for (let key in (nav.minPermissions || {})) {
          const minValue = nav.minPermissions[key] || 0
          if (!permissions || (permissions[key] || 0) < minValue) {
            return false
          }
        }
        return true
      }
    }
  },
  components: {
    Marquee,
  }
}
</script>
