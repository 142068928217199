<template>
    <div class="page-content-wrapper" v-bind:style="{ 'width' : contentWidth , 'top' : contentTop , padding : contentPadding }">
        <slot></slot>
    </div>
</template>

<script>
export default {
  props: {
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    }
  },
  computed: {
    contentPadding () {
      switch (this.layout.navbar) {
        // case 0:
          // return '0'
        default:
          return this.layout.mobile ? '0.2rem 1rem' : '1rem 1rem'
      }
    },
    contentTop () {
      switch (this.layout.navbar) {
        case 1:
          return '54px'
        case 0:
        default:
          return '0px'
      }
    },
    contentWidth () {
      if (this.layout.mobile) {
        return '100%'
      }

      switch (this.layout.sidenav) {
        case 1:
          return 'calc(100% - 70px)'
        case 2:
          return 'calc(100% - 300px)'
        case 0:
        default:
          return '100%'
      }
    }
  },
  methods: {
    onUpdate (type, data) {
      this.$emit('update', type, data)
    }
  }
}
</script>

<style scoped>
    .page-content-wrapper {
        position: absolute;
        right: 0;
        top: 56px;
        min-height: calc(100% - 56px);
        transition: width .3s ease, left .2s ease, top .3s ease, padding .3s ease;
    }
</style>
