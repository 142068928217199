const routes = [
    {
      path: '/provider/softwares/:page?',
      props: true,
      component: () => import('@/views/provider/software/Softwares.vue')
    },
    {
      path: '/provider/software/:softwareId/:nav?/:page?',
      props: true,
      component: () => import('@/views/provider/software/Software.vue')
    },
    {
      path: '/provider/find/softwares',
      props: true,
      component: () => import('@/views/provider/software/TestFind.vue')
    }
  ]
  
  export default routes
  