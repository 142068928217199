const routes = [
  {
    path: '/qr/bytecode/:bytecode/:nav?/:tab?/:page?',
    props: true,
    component: () => import('@/views/qr/QrBytecode.vue')
  },
  {
    path: '/qr/product/bytecode/:productBytecode/:nav?/:tab?/:page?',
    props: true,
    component: () => import('@/views/qr/QrProductBytecode.vue')
  },
  {
    path: '/qr/product/id/:productId/:nav?/:tab?/:page?',
    props: true,
    component: () => import('@/views/qr/QrProductId.vue')
  },
  {
    path: '/qr/location/id/:locationId/:nav?/:tab?/:page?',
    props: true,
    component: () => import('@/views/qr/QrLocationId.vue')
  },
  {
    path: '/qr/organization/id/:organizationId/:nav?/:tab?/:page?',
    props: true,
    component: () => import('@/views/qr/QrOrganizationId.vue')
  },
  {
    path: '/qr/equipment/id/:equipmentId/:nav?/:tab?/:page?',
    props: true,
    component: () => import('@/views/qr/QrBase.vue')
  },
  {
    path: '/qr/404',
    props: true,
    component: () => import('@/views/qr/NotFound.vue')
  }
]

export default routes
