<template>

    <component ref="marquee" class="marquee overflow-auto position-relative pr-2" :is="componentClass" @mouseover="startScroll">
        <slot>{{text}}</slot>
        <slot name="After"/>
    </component>

</template>

<script>

export default {
  props: {
    componentClass: {
      type: String,
      default: 'div'
    },
    trigger: {
      type: String,
      default: ''
    },
    text: {
      type: String
    },
    startOnMount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      moving: false,
      timeout: null
    }
  },
  mounted () {
    // this.$refs.marquee.addEventListener("wheel", this.scroll)
    if (!this.startOnMount) { return }
    this.startScroll()
  },
  beforeUnmount () {
    if (this.timeout) { clearTimeout(this.timeout) }
    // this.$refs.marquee.removeEventListener("wheel", this.scroll)
  },
  methods: {
    startScroll () {
      if (this.moving) { return }
      this.moving = true
      if (this.timeout) { clearTimeout(this.timeout) }
      if (!this.$refs.marquee) { return }
      this.$refs.marquee.scrollTo({ left: 0, behavior: 'smooth'})
      this.timeout = setTimeout(() => this.scrollIncr(2), 100)
    },
    scroll (evt) {
      evt.preventDefault()
      this.$refs.marquee.scrollLeft += evt.deltaY
    },
    scrollIncr (incr) {
      if (this.timeout) { clearTimeout(this.timeout) }
      if (!this.$refs.marquee) { return }
      // if (this.$refs.marquee.scrollLeft ){}
      let lastScroll = this.$refs.marquee.scrollLeft
      this.$refs.marquee.scrollLeft += incr
      if (this.$refs.marquee.scrollLeft === lastScroll) { // Reached max scroll
        // console.log('reached max scroll')
        setTimeout(() => {
          if (this.$refs.marquee) {
            this.$refs.marquee.scrollTo({ left: 0, behavior: 'smooth' })
            this.moving = false
          } }, 2000)
        return // this.timeout = setTimeout(() => { this.scrollIncr(-1*incr) }, 50)
      }
      if (this.$refs.marquee.scrollLeft <= 0){ // Reached min scroll
        // console.log('reached min scroll')
        this.moving = false
        return // this.timeout = setTimeout(() => { this.scrollIncr(-1*incr) }, 50)
      }
      this.timeout = setTimeout(() => { this.scrollIncr(incr) }, 50)
    },
    scrollMargin (direction) {
      if (this.timeout) { clearTimeout(this.timeout) }
      if (!this.$refs.marquee) { return }
      this.$refs.marquee.scrollTo({ left: direction > 0 ? this.$refs.marquee.clientWidth : 0, behavior: 'smooth'})
      this.timeout = setTimeout(() => { this.scrollMargin(direction*-1) }, 5000)
    }
  },
  watch: {
    trigger () {
      if (this.timeout) { clearTimeout(this.timeout) }
      if (!this.$refs.marquee) { return }
      this.$refs.marquee.scrollTo({ left: 0, behavior: 'smooth'})
      this.timeout = setTimeout(() => this.scrollIncr(2), 1000)
    }
  }
}

</script>

<style scoped>

.marquee {
    white-space: nowrap;
    /* text-overflow: ellipsis; */
}

.marquee::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.marquee {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>