<template>

      <!-- Containers and Positions -->
      <SidenavSection v-if="permissions && permissions.C"
        :name="$tc('m.Container', 2)"
        icon="pin_drop"
        :navs="[
          { name: $tc('m.Container', 2), href: `/#/container/containers`, active: ['/container/containers', '/container/container'] },
          { name: $tc('m.ContainerShape', 2), href: `/#/container/shapes`, active: '/container/shape' },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="container"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
