<template>

    <AbstractAlert
    :show="show"
    :busy="busy"
    :icon="icon"
    :text="text"
    variant="secondary"
    textClass="text-dark"
    borderClass="border-dark"
    :dismissible="dismissible"
    @dismissed="$emit('dismissed')">
        <template v-slot:Content><slot name="Content"/></template>
        <template v-slot:Text><slot name="Text"/></template>
        <template v-slot:Append><slot name="Append"/></template>
        <template v-slot:Extra><slot name="Extra"/></template>
    </AbstractAlert>

</template>

<script>

import AbstractAlert from '@/views/components/_alert/AbstractAlert.vue'

export default {
  props: {
    show: {
      type: Boolean
    },
    busy: {
      type: Number
    },
    icon: {
      type: String
    },
    text: {
      type: String
    },
    dismissible: {
      type: Boolean
    }
  },
  components: {
    AbstractAlert
  }
}

</script>
