import { createI18n } from 'vue-i18n'
import Formatter from './formatter'

import enUS from './en_US'
import esES from './es_ES'

const params = new URLSearchParams(window.location.search)
const browserLang = navigator.language || navigator.userLanguage
let locale = params.get('locale') || localStorage.getItem('locale') || browserLang || 'en-US' // default locale

// Not available locale
if (['en', 'en-US', 'es', 'es-ES'].indexOf(locale) < 0) { locale = 'en' }

const formatter = new Formatter({ locale })

export default createI18n({
  locale,
  formatter,
  messages: {
    'en': enUS,
    'en-US': enUS,
    'es': esES,
    'es-ES': esES
  }
})
