let API_ENDPOINT = location.hostname; let credentials = { credentials: 'include' } // PRODUCTION
// API_ENDPOINT = 'api.janby.kitchen'; credentials = {} // DEVELOPMENT WITH PROD API
if (location.hostname === 'localhost') { API_ENDPOINT = 'localhost:8081'; credentials = {} } // DEVELOPMENT WITH LOCAL API

import { isProxy , toRaw } from "vue"

export default {
  getApiEndpoint () { return API_ENDPOINT },

  getSessionJWT () { try { return JSON.parse(localStorage.getItem('jwt-session') || 'null') } catch (err) { return null } },

  buildUrl(parts, sep = '/'){
    var separator = sep || '/'
    var replace   = new RegExp(separator + '{1,}', 'g')
    if (!Array.isArray(parts)) { parts = [parts] }
    return `${location.protocol}//` + (parts.join(separator).replace(replace, separator))
  },

  async fetchWithoutJwt (url, options = {}) {
    let r = null
    let status = null
    let ok = null

    // eslint-disable-next-line no-useless-catch
    try {
      r = await fetch( this.buildUrl([ API_ENDPOINT , url ]), { ...options, ...credentials })
      status = r.status
      ok = r.ok

      r = await r.json()
    } catch (err) { throw err }

    // Error, throw the json
    if (!ok) {
      if (r.status) { r.status = status }
      if (r.ok) { r.ok = ok }
      throw r
    }

    return r
  },

  async fetchWithJwt (url, options = {}) {
    const jwt = (options ? options.jwt : null) || this.getSessionJWT()

    if (!jwt) {
      localStorage.removeItem('jwt-session')
      // return location.reload()
    }

    let r = null
    let status = null
    let ok = null

    options.headers = Object.assign(options.headers || {}, { Authorization: jwt })

    // eslint-disable-next-line no-useless-catch
    try {
      r = await fetch( this.buildUrl([ API_ENDPOINT , url ]), options)
      status = r.status
      ok = r.ok
      r = await r.json()
    } catch (err) { throw err }

    // Error, throw the json
    if (!ok) {
      // 400 code means that we have an invalid/expired jwt

      if (status === 400 && r.code === 'INVALID_TOKEN') {
        // Remove jwt
        localStorage.removeItem('jwt-session')
        if (options.uiredirect !== 0 && options.uiredirect !== '0') { location = '/#/login' }
        return null
      }

      if (r.status) { r.status = status }
      if (r.ok) { r.ok = ok }
      throw r
    }

    return r
  },

  openPOSTWindow (url, data, jwt = null, closeTimeout = 0, windowOptions = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1') {
    url = this.buildUrl([ this.getApiEndpoint() , url ])
    const windowName = 'w_' + new Date().getTime();
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', url)
    // form.setAttribute("target", windowName)

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', key)
        hiddenField.setAttribute('value', data[key])
        form.appendChild(hiddenField)
      }
    }

    if (jwt) {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', 'jwt')
      hiddenField.setAttribute('value', jwt)
      form.appendChild(hiddenField)
    }
                 
    const n = window.open('', windowName, windowOptions)
    n.document.body.appendChild(form)
    form.target = windowName

    const l = document.createElement('label')
    l.style = 'width:100%'
    l.for = 'progress-bar'
    l.innerHTML = 'Please wait while we process your request...'
    n.document.body.appendChild(l)

    const p = document.createElement('progress')
    p.id = 'progress-bar'
    p.style = 'width:100%; height:64px; color: #343a40'
    n.document.body.appendChild(p)
    
    form.submit()
    n.document.body.removeChild(form)
    if (closeTimeout) { setTimeout(function() { n.close() }, closeTimeout) }
  },

  stringify (obj, stack = []) {
    return JSON.stringify(this.decycle(obj, stack))
  },
  decycle(obj, stack = []) {
    if (!obj) { return obj }
    if (isProxy(obj)) { obj = toRaw(obj) }
    if (typeof obj !== 'object') {
      return obj
    }
    
    if (stack.includes(obj)){
        return null
    }

    let s = stack.concat([obj])

    return Array.isArray(obj)
        ? obj.map(x => this.decycle(x, s))
        : Object.fromEntries(
            Object.entries(obj)
                .map(([k, v]) => [k, this.decycle(v, s)]))
  },
}
