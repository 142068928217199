// This works on all devices/browsers, and uses IndexedDBShim as a final fallback
Object.defineProperty(window, 'indexedDB', {
    value: window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB
  })
if (!window.indexedDB) {
    window.alert("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.")
}

const lifecycle = 60 * 1000 // 1 Minute

let idatabase = null
let upgradePending = false
let retries = 0
let timeout = null

const printMessage = (msg) => {
    try {
        if (process != null && process.env && process.env.NODE_ENV !== 'production') { console.log(msg) }
        // console.log(msg, idatabase, upgradePending, retries)
    } catch (err) { }
}

const deleteIDB = async (restartMs = null) => {
    printMessage("Trying to delete database")

    try {
        await new Promise((resolve, reject) => {
            const deleteReq = window.indexedDB.deleteDatabase("JanbyCloudDB")
            deleteReq.onsuccess = () => { printMessage("Success deleting database") ; resolve(true) }
            deleteReq.onerror = () => { reject("Could not delete database") }
            deleteReq.onblocked = () => { reject("Could not delete database due to the operation being blocked") }
        })
    } catch (err) {
        printMessage("Error trying to delete database")
        console.log(err)
        return
    }
    printMessage("Success deleting")

    if (restartMs) {
        printMessage("After deleting, tryng to restart")
        if (timeout) { clearTimeout(timeout) }
        timeout = setTimeout(startIDB, restartMs)
    }
}

const createIDB = async (dbConnection) => {
    printMessage("Trying to create database")
    if (!dbConnection) { return }

    await Promise.all([

        // Organizations
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('organizations')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('organizations', { keyPath: 'id' })
            store.createIndex("smallId", "smallId", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Organizations store created') ; resolve(true) }
        }),

        // Locations
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('locations')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('locations', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Locations store created') ; resolve(true) }
        }),

        // Products
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('products')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('products', { keyPath: 'id' })
            store.createIndex("productGtin", "productGtin", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Products store created') ; resolve(true) }
        }),

        // Product batchings
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('batchings')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('batchings', { keyPath: 'id' })
            store.createIndex("productGtin", "productGtin", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Bacthings store created') ; resolve(true) }
        }),

        // Label templates
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('labeltemplates')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('labeltemplates', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Label templates store created') ; resolve(true) }
        }),

        // Devices
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('devices')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('devices', { keyPath: 'id' })
            store.createIndex("serial", "serial", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Devices store created') ; resolve(true) }
        }),

        // Actions
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('actions')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('actions', { keyPath: 'id' })
            store.createIndex("path", "path", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Actions store created') ; resolve(true) }
        }),

        // Equipments
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('equipments')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('equipments', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Equipments store created') ; resolve(true) }
        }),

        // Programs
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('programs')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('programs', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Programs store created') ; resolve(true) }
        }),

        // Users
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('users')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('users', { keyPath: 'id' })
            store.createIndex("email", "email", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Users store created') ; resolve(true) }
        }),

        // Containers
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('containers')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('containers', { keyPath: 'id' })
            store.createIndex("containerSscc", "containerSscc", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Containers store created') ; resolve(true) }
        }),

        // Container shapes
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('containershapes')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('containershapes', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Container Shapes store created') ; resolve(true) }
        }),

        // Tasks
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('tasks')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('tasks', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Tasks store created') ; resolve(true) }
        }),

        // Sensors
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('sensors')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('sensors', { keyPath: 'id' })
            store.createIndex("path", "path", { unique: false })
            store.transaction.oncomplete = () => { printMessage('Sensors store created') ; resolve(true) }
        }),

        // Elaborations
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('elaborations')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('elaborations', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Elaborations store created') ; resolve(true) }
        }),

        // States
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('states')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('states', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('States store created') ; resolve(true) }
        }),

        // Experiments
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('experiments')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('experiments', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Experiments store created') ; resolve(true) }
        }),

        // Quality parameters
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('qualityparameters')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('qualityparameters', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Quality parameters store created') ; resolve(true) }
        }),


        // Work contexts
        new Promise((resolve) => {
            if (dbConnection.objectStoreNames.contains('contexts')) {
                return resolve(true)
            }
            let store = dbConnection.createObjectStore('contexts', { keyPath: 'id' })
            store.transaction.oncomplete = () => { printMessage('Contexts store created') ; resolve(true) }
        }),

    ])

    upgradePending = false
}

const storeInIDB = async (storeName, data, secondaryIndexName = null, idbExpiration = new Date(new Date().getTime() + lifecycle) ) => {
    if (!idatabase || upgradePending) { return null }

    if (!storeName || !data){ return null }

    if (isProxy(data)) { data = toRaw(data) }

    // Make array
    await Promise.all(( Array.isArray(data) ? data : [data] ).map(async d => {

        // Store idbDate
        d.idbExpiration = idbExpiration ? idbExpiration.toISOString() : idbExpiration

        // Get existing
        try {
            const e = await getFromIDB(storeName, d[secondaryIndexName || 'id'], secondaryIndexName)
            if (e){ d = FormatController.mergeObjects(e, d) }
        } catch (err) {
            printMessage(err)
            console.log(storeName, d)
            deleteIDB((++retries) * 5000)
            return
        }

        // Store
        let objStore = null
        try {
            let transaction     = idatabase.transaction([storeName], 'readwrite')
            objStore            = transaction.objectStore(storeName)
        } catch (err) {
            printMessage(err)
            console.error(storeName, d)
            return
        }

        let objReq = objStore.put(d) // Overwrite if exists

        objReq.onerror = (evt) => { console.error('Error storing ' , storeName, evt) }

        objReq.onsuccess = () => {}
    }))

    return true
}

const getFromIDB = (storeName, id, secondaryIndexName = null) => {
    return new Promise((resolve, reject) => {
        if (!idatabase || upgradePending) { return resolve(null) }

        if (!storeName || !id){ return resolve(null) }

        let objReq = null
        try {
            let transaction     = idatabase.transaction([storeName], 'readonly')
            let objStore        = transaction.objectStore(storeName)
            if (secondaryIndexName) { objStore = objStore.index(secondaryIndexName) }
            objReq              = objStore.get(id)
        } catch (err) {
            console.log(err)
            if (timeout) { clearTimeout(timeout) }
            timeout = setTimeout(deleteIDB, 5000)
            return resolve(null)
        }

        objReq.onerror = () => {
            printMessage(`Error retrieving ${storeName} - ${id}`)
            resolve(null)
        }

        objReq.onsuccess = (evt) => {
            if (evt.target.result) {
                const data = evt.target.result

                // Test idbExpiration
                /* if (data.idbExpiration && new Date(data.idbExpiration).getTime() < expireThreshold.getTime()) {
                    deleteFromIDB(storeName, id)
                    return resolve(null)
                }*/

                // console.log(`Fetched data`, data)
                resolve(data)
            } else {
                resolve(null)
            }
        }
    })
}

const deleteFromIDB = (storeName, id) => {
    return new Promise((resolve, reject) => {
        if (!idatabase || upgradePending) { return resolve(null) }

        if (!storeName || !id){ return resolve(null) }

        let transaction   = idatabase.transaction([storeName], 'readwrite')
        let objStore   = transaction.objectStore(storeName)
        let objReq = objStore.delete(id)

        objReq.onerror = () => {
            reject(Error('Error text'))
        }

        objReq.onsuccess = () => {
            resolve(objReq.result)
        }
    })
}

const startIDB = () => {
    if (!window.indexedDB) { return }
    printMessage('Starting IDB Database')
    upgradePending = false

    // Open (or create) the database
    const openRequest = window.indexedDB.open("JanbyCloudDB", 22)

    openRequest.onerror = async () => {
        printMessage(`IDB Database error ${openRequest.error}`)
        try {
            await deleteIDB()
        } catch (err) {
            printMessage('startIDB.onerror: Could not delete database')
            console.log(err)
        }
    }
    
    openRequest.onsuccess = () => {
        idatabase = openRequest.result
        printMessage('Success starting IDB Database')
        retries = 0
        if (upgradePending) {
            printMessage('Database needs upgrading, closing it')
            idatabase.close()
        }
    }

    // Upsert the schema
    openRequest.onupgradeneeded = async (evt) => {
        upgradePending = true
        const dbConnection = evt.target.result
        if (!dbConnection) { return }
        printMessage('IDB Database upgrade is needed')

        // Check if DB has data
        if (dbConnection.objectStoreNames.length) {
            printMessage('IDB Database has data, deleting it')
            await deleteIDB((++retries) * 5000)
        } else {
            printMessage('IDB Database is empty, creating schema')
            await createIDB(dbConnection)
            if (timeout) { clearTimeout(timeout) }
            timeout = setTimeout(startIDB, 5000)
        }
    }
}


import { isProxy , toRaw } from "vue"
import FormatController from "./format.controller"

export default {
    startIDB,
    storeInIDB,
    getFromIDB,
    deleteFromIDB,
    mergeObjects: FormatController.mergeObjects,

}