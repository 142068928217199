<template>

<div class="position-relative">
    <div class="page-background position-fixed w-100 h-100" :style="`background-image: url(${image})`"/>
</div>

</template>

<script>

export default {
  props: {
    image: {
      type: String,
      default () { return 'https://janby.blob.core.windows.net/equipments/equipments.jpg' }
    },
    loaded: {
      type: Boolean,
      default () { return false }
    },
    me: {
      type: Object
    },
    location: {
      type: Object
    }
  }
}

</script>

<style scoped>

.page-background {
    right: 0;
    bottom: 0;
    opacity: 0.03;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    filter: grayscale(100%);
}

</style>
